﻿import { Component, Input } from "@angular/core";

@Component({
    selector: 'tfolio-page-title',
    template: `
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12"><h1>{{content}}</h1></div>
    </div>
</div>
`,
    styles: ['']
})
export class TFolioPageTitleComponent {
    @Input() content: string;
}