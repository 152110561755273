﻿import { Component } from "@angular/core";

@Component({
    selector: 'landing-page-footer',
    template: `
    <div class="row">
        <div class="col-md-12">
            <img src="/img/ccby.png" alt="CC BY License" height="33" style="float:left;margin-top:2px;margin-bottom:10px;margin-right:5px;">
            <div style="font-size:small;">
                Except where otherwise noted, content on this site, created by <a href="http://www.seattleu.edu/ccts" target="_blank">CCTS at Seattle University</a>
                with funding from <a href="https://www.dshs.wa.gov/strategic-planning/division-vocational-rehabilitation" target="_blank">WA DSHS/DVR</a>,
                is licensed under a <a href="https://creativecommons.org/licenses/by/4.0" target="_blank">CC BY 4.0 license</a>.
                All logos and trademarks are property of their respective owners.
            </div>
        </div>
    </div>
`,
    styles: [``]
})
export class LandingPageFooterComponent {
}