import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "independent-living-assessment",
    templateUrl: "./independent-living-assessment.component.html",
    styleUrls: ["./independent-living-assessment.component.css"]
})
export class UnitFiveLessonThreeIndependentLivingAssessmentComponent {

    title: string = "Independent Living Assessment";
    pageTitle: string = this.title + " | Unit 5, Lesson 3 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    data: any = {
        dailyLiving: {
            routine: { always: 0, sometimes: 0, never: 0 },
            food: { always: 0, sometimes: 0, never: 0 },
            chores: { always: 0, sometimes: 0, never: 0 },
            whereToLive: { always: 0, sometimes: 0, never: 0 },
            preferredSchedule: { always: 0, sometimes: 0, never: 0 }
        },
        moneyManagement: {
            maintainAccounts: { always: 0, sometimes: 0, never: 0 },
            knowCostsOfServices: { always: 0, sometimes: 0, never: 0 },
            useCreditCards: { always: 0, sometimes: 0, never: 0 },
            useOnlineBanking: { always: 0, sometimes: 0, never: 0 },
            understandCreditScore: { always: 0, sometimes: 0, never: 0 }
        },
        relationships: {
            maintainRelationships: { always: 0, sometimes: 0, never: 0 },
            establishNewFriendships: { always: 0, sometimes: 0, never: 0 },
            understandSexuality: { always: 0, sometimes: 0, never: 0 },
            understandOnline: { always: 0, sometimes: 0, never: 0 },
            makeInformedChoicesSexuality: { always: 0, sometimes: 0, never: 0 }
        },
        communityAccess: {
            knowLeisureActivities: { always: 0, sometimes: 0, never: 0 },
            utilizeLeisureActivities: { always: 0, sometimes: 0, never: 0 },
            useCommunityServices: { always: 0, sometimes: 0, never: 0 },
            canFollowSafetyRules: { always: 0, sometimes: 0, never: 0 },
            knowHowToParticipate: { always: 0, sometimes: 0, never: 0 }
        },
        selfCare: {
            performFirstAid: { always: 0, sometimes: 0, never: 0 },
            completeMedicalPaperwork: { always: 0, sometimes: 0, never: 0 },
            keepMedicalAppointments: { always: 0, sometimes: 0, never: 0 },
            knowCPR: { always: 0, sometimes: 0, never: 0 },
            call911: { always: 0, sometimes: 0, never: 0 }
        },
        transportation: {
            selectAppropriate: { always: 0, sometimes: 0, never: 0 },
            keepTrackOfDocumentation: { always: 0, sometimes: 0, never: 0 },
            navigateCommunity: { always: 0, sometimes: 0, never: 0 },
            maintainVehicle: { always: 0, sometimes: 0, never: 0 },
            knowAlternate: { always: 0, sometimes: 0, never: 0 }
        },
        selfAdvocacy: {
            expressNeeds: { always: 0, sometimes: 0, never: 0 },
            askForAccommodations: { always: 0, sometimes: 0, never: 0 },
            makeDecisionsAboutFuture: { always: 0, sometimes: 0, never: 0 },
            makeDecisionsForFamily: { always: 0, sometimes: 0, never: 0 },
            knowRights: { always: 0, sometimes: 0, never: 0 }
        }
    }

    public get dailyLivingAlways(): number {
        return this.data.dailyLiving.routine.always +
            this.data.dailyLiving.food.always +
            this.data.dailyLiving.chores.always +
            this.data.dailyLiving.whereToLive.always +
            this.data.dailyLiving.preferredSchedule.always;
    }
    public get dailyLivingSometimes(): number {
        return this.data.dailyLiving.routine.sometimes +
            this.data.dailyLiving.food.sometimes +
            this.data.dailyLiving.chores.sometimes +
            this.data.dailyLiving.whereToLive.sometimes +
            this.data.dailyLiving.preferredSchedule.sometimes;
    }
    public get dailyLivingNever(): number {
        return this.data.dailyLiving.routine.never +
            this.data.dailyLiving.food.never +
            this.data.dailyLiving.chores.never +
            this.data.dailyLiving.whereToLive.never +
            this.data.dailyLiving.preferredSchedule.never;
    }

    public get moneyManagementAlways(): number {
        return this.data.moneyManagement.maintainAccounts.always +
            this.data.moneyManagement.knowCostsOfServices.always +
            this.data.moneyManagement.useCreditCards.always +
            this.data.moneyManagement.useOnlineBanking.always +
            this.data.moneyManagement.understandCreditScore.always;
    }
    public get moneyManagementSometimes(): number {
        return this.data.moneyManagement.maintainAccounts.sometimes +
            this.data.moneyManagement.knowCostsOfServices.sometimes +
            this.data.moneyManagement.useCreditCards.sometimes +
            this.data.moneyManagement.useOnlineBanking.sometimes +
            this.data.moneyManagement.understandCreditScore.sometimes;
    }
    public get moneyManagementNever(): number {
        return this.data.moneyManagement.maintainAccounts.never +
            this.data.moneyManagement.knowCostsOfServices.never +
            this.data.moneyManagement.useCreditCards.never +
            this.data.moneyManagement.useOnlineBanking.never +
            this.data.moneyManagement.understandCreditScore.never;
    }

    public get relationshipsAlways(): number {
        return this.data.relationships.maintainRelationships.always +
            this.data.relationships.establishNewFriendships.always +
            this.data.relationships.understandSexuality.always +
            this.data.relationships.understandOnline.always +
            this.data.relationships.makeInformedChoicesSexuality.always;
    }
    public get relationshipsSometimes(): number {
        return this.data.relationships.maintainRelationships.sometimes +
            this.data.relationships.establishNewFriendships.sometimes +
            this.data.relationships.understandSexuality.sometimes +
            this.data.relationships.understandOnline.sometimes +
            this.data.relationships.makeInformedChoicesSexuality.sometimes;
    }
    public get relationshipsNever(): number {
        return this.data.relationships.maintainRelationships.never +
            this.data.relationships.establishNewFriendships.never +
            this.data.relationships.understandSexuality.never +
            this.data.relationships.understandOnline.never +
            this.data.relationships.makeInformedChoicesSexuality.never;
    }

    public get communityAccessAlways(): number {
        return this.data.communityAccess.knowLeisureActivities.always +
            this.data.communityAccess.utilizeLeisureActivities.always +
            this.data.communityAccess.useCommunityServices.always +
            this.data.communityAccess.canFollowSafetyRules.always +
            this.data.communityAccess.knowHowToParticipate.always;
    }
    public get communityAccessSometimes(): number {
        return this.data.communityAccess.knowLeisureActivities.sometimes +
            this.data.communityAccess.utilizeLeisureActivities.sometimes +
            this.data.communityAccess.useCommunityServices.sometimes +
            this.data.communityAccess.canFollowSafetyRules.sometimes +
            this.data.communityAccess.knowHowToParticipate.sometimes;
    }
    public get communityAccessNever(): number {
        return this.data.communityAccess.knowLeisureActivities.never +
            this.data.communityAccess.utilizeLeisureActivities.never +
            this.data.communityAccess.useCommunityServices.never +
            this.data.communityAccess.canFollowSafetyRules.never +
            this.data.communityAccess.knowHowToParticipate.never;
    }

    public get selfCareAlways(): number {
        return this.data.selfCare.performFirstAid.always +
            this.data.selfCare.completeMedicalPaperwork.always +
            this.data.selfCare.keepMedicalAppointments.always +
            this.data.selfCare.knowCPR.always +
            this.data.selfCare.call911.always;
    }
    public get selfCareSometimes(): number {
        return this.data.selfCare.performFirstAid.sometimes +
            this.data.selfCare.completeMedicalPaperwork.sometimes +
            this.data.selfCare.keepMedicalAppointments.sometimes +
            this.data.selfCare.knowCPR.sometimes +
            this.data.selfCare.call911.sometimes;
    }
    public get selfCareNever(): number {
        return this.data.selfCare.performFirstAid.never +
            this.data.selfCare.completeMedicalPaperwork.never +
            this.data.selfCare.keepMedicalAppointments.never +
            this.data.selfCare.knowCPR.never +
            this.data.selfCare.call911.never;
    }

    public get transportationAlways(): number {
        return this.data.transportation.selectAppropriate.always +
            this.data.transportation.keepTrackOfDocumentation.always +
            this.data.transportation.navigateCommunity.always +
            this.data.transportation.maintainVehicle.always +
            this.data.transportation.knowAlternate.always;
    }
    public get transportationSometimes(): number {
        return this.data.transportation.selectAppropriate.sometimes +
            this.data.transportation.keepTrackOfDocumentation.sometimes +
            this.data.transportation.navigateCommunity.sometimes +
            this.data.transportation.maintainVehicle.sometimes +
            this.data.transportation.knowAlternate.sometimes;
    }
    public get transportationNever(): number {
        return this.data.transportation.selectAppropriate.never +
            this.data.transportation.keepTrackOfDocumentation.never +
            this.data.transportation.navigateCommunity.never +
            this.data.transportation.maintainVehicle.never +
            this.data.transportation.knowAlternate.never;
    }

    public get selfAdvocacyAlways(): number {
        return this.data.selfAdvocacy.expressNeeds.always +
            this.data.selfAdvocacy.askForAccommodations.always +
            this.data.selfAdvocacy.makeDecisionsAboutFuture.always +
            this.data.selfAdvocacy.makeDecisionsForFamily.always +
            this.data.selfAdvocacy.knowRights.always;
    }
    public get selfAdvocacySometimes(): number {
        return this.data.selfAdvocacy.expressNeeds.sometimes +
            this.data.selfAdvocacy.askForAccommodations.sometimes +
            this.data.selfAdvocacy.makeDecisionsAboutFuture.sometimes +
            this.data.selfAdvocacy.makeDecisionsForFamily.sometimes +
            this.data.selfAdvocacy.knowRights.sometimes;
    }
    public get selfAdvocacyNever(): number {
        return this.data.selfAdvocacy.expressNeeds.never +
            this.data.selfAdvocacy.askForAccommodations.never +
            this.data.selfAdvocacy.makeDecisionsAboutFuture.never +
            this.data.selfAdvocacy.makeDecisionsForFamily.never +
            this.data.selfAdvocacy.knowRights.never;
    }

    public get totalAlways(): number {
        return this.dailyLivingAlways +
            this.moneyManagementAlways +
            this.relationshipsAlways +
            this.communityAccessAlways +
            this.selfCareAlways +
            this.transportationAlways +
            this.selfAdvocacyAlways;
    }
    public get totalSometimes(): number {
        return this.dailyLivingSometimes +
            this.moneyManagementSometimes +
            this.relationshipsSometimes +
            this.communityAccessSometimes +
            this.selfCareSometimes +
            this.transportationSometimes +
            this.selfAdvocacySometimes;
    }
    public get totalNever(): number {
        return this.dailyLivingNever +
            this.moneyManagementNever +
            this.relationshipsNever +
            this.communityAccessNever +
            this.selfCareNever +
            this.transportationNever +
            this.selfAdvocacyNever;
    }
}
