﻿import { Component } from "@angular/core";

@Component({
    selector: 'tfolio-heading',
    template: `
<div class="container-fluid">
    <div class="row">
        <div class="col-md-1"><label for="tfolio-name-textbox"><b>Name:</b></label></div>
        <div class="col-md-7"><input type="text" id="tfolio-name-textbox" name="tfolio-name-textbox"/></div>
        <div class="col-md-1"><label for="tfolio-date-textbox"><b>Date:</b></label></div>
        <div class="col-md-3"><input type="text" id="tfolio-date-textbox" name="tfolio-date-textbox"/></div>
    </div>
</div>
`,
    styles: ['']
})
export class TFolioHeadingComponent {
    doPrint(): void {
        window.print();
    }
}