import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-three-lesson-one-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitThreeLessonTwoLandingComponent {

    bannerHeader = "Work-Based Learning Experiences: Lesson 2";
    title: string = "Lesson 2: SCANS Workplace Skills and Competencies";
    pageTitle: string = "Scans Workplace Skills and Competencies | Unit 3, Lesson 2 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    doPrint(): void {
        window.print();
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 2",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-3",
                    linktext: "Unit 3"
                }
            ]
        },
        summary: "In this lesson, youth learn about the SCANS Report (1991), which provides a list of skills and competencies necessary for success in the workplace. Youth will use the SCANS Skills Assessment to discover their own skills.",
        objectives: [
            "Review the foundation skills and competencies outlined in the SCANS report.",
            "Take the SCANS Skills Assessment.",
            "Discuss what skills and competencies they can work on in school and the workplace."
        ],
        buttons: [
            {
                route: "/unit-3/lesson-2/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-3/lesson-2/scans-skills-assessment",
                linktext: "SCANS Skills Assessment",
                color: "btn-primary",
                window: false,
            },
            {
                route: '/img/unit-3/lesson-2/T-Folio_Unit-3_Lesson-2_SCANS-Workplace-Skills-And-Competencies.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-3/lesson-2/LESSON 2_jobs_cashier.jpg",
        imgalt: "Two people exchanging a bag of macarons and money are in the left corner with a display of colorful macarons and a flavor board to the right of them. The flavors listed are passion fruit, black sesame, key lime, chocolate, strawberry marshmallow, and vanilla bean.",
        caption: "by Alicia Zinn on ",
        photoLink: "https://www.pexels.com/photo/assorted-business-desserts-display-159991/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",
    }
}
