import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-four-lesson-three-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFourLessonThreeLandingComponent {

    bannerHeader = "Workplace Readiness Training: Lesson 3";
    title: string = "Lesson 3: Writing and Presenting an Elevator Speech";
    pageTitle: string = "Writing and Presenting an Elevator Speech | Unit 4, Lesson 3 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 3",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-4",
                    linktext: "Unit 4"
                }
            ]
        },
        summary: "In this lesson, students will learn about the various components of an elevator speech. Then they will write their own elevator speech and practice delivering their pitch to their peers.  ",
        objectives: [
            "Recognize the components of an elevator speech.",
            "Understand the importance of presenting themselves in a positive, professional manner.",
            "Develop an elevator speech to share with potential employers.",
        ],
        buttons: [
            {
                route: "/unit-4/lesson-3/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-4/lesson-3/elevator-speech-worksheet",
                linktext: "Elevator Speech Worksheet",
                color: "btn-primary",
                window: false,
            },
            {
                route: "/unit-4/lesson-3/elevator-speech-feedback",
                linktext: "Elevator Speech: Peer Reflection Feedback",
                color: "btn-primary",
                window: false,
            },
            {
                route: '/img/unit-4/lesson-3/T-Folio_Unit-4_Lesson-3_Writing-And-Presenting-An-Elevator-Speech.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-4/lesson-3/L3_k-120-name-1117-id-62272-jpeg.jpg",
        imgalt: "A person wearing glasses and a blazer is speaking to a coworker.",
        caption: "on",
        photoLink: "https://www.rawpixel.com/image/62272/agreement-business-business-people-businessman-businesswoman-colleague-communication-community",
        source: "Rawpixel",
        sourceLink: "https://www.rawpixel.com/",
        licence: "Free license",
        licenceLink: "https://www.rawpixel.com/services/image-licenses/free-license",
    }


}
