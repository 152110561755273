import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-four-lesson-seven-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFourLessonSevenLandingComponent {

    bannerHeader = "Workplace Readiness Training: Lesson 7";
    title: string = "Lesson 7: Action Plan for Workplace Readiness";
    pageTitle: string = "Action Plan for Workplace Readiness | Unit 4, Lesson 7 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 7",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-4",
                    linktext: "Unit 4"
                }
            ]
        },
        summary: "In Lesson 7, youth will develop an action plan to follow up with the activities conducted throughout Unit 4. This will allow them to put the preparatory activities they have completed in Lessons 1-6 of the unit into a plan of action.",
        objectives: [
            "Review the components of an action plan.",
            "Create an action plan for one or more of the areas covered in this unit.",
            "Identify supports needed for each portion of the action plan.",
        ],
        buttons: [
            {
                route: "/unit-4/lesson-7/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },

            {
                route: "/unit-4/lesson-7/activity-action-plan-template",
                linktext: "Action Plan",
                color: "btn-primary",
                window: false,
            },
            {
                route: "/img/unit-4/lesson-7/T-Folio_Unit-4_Lesson-7_Action-Plan-Sample.pdf",
                linktext: "Action Plan - Sample (PDF)",
                color: "btn-primary",
                window: true,
            },
            {
                route: "/img/unit-4/lesson-7/T-Folio_Unit-4_Lesson-7_Action-Plan-For-Employment.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
                
            }
        ],
        image: "/img/unit-4/lesson-7/L7_K-63C-Ae-0005424901-BW-1-id-1211-jpeg.jpg",
        imgalt: "This black and white photo shows a team of eight young people putting their hands together over a table with work supplies.",
        caption: "on",
        photoLink: "https://www.rawpixel.com/image/1211/collaboration-flatlay-top-view-people-together-teamwork-hands-aerial-view-diversity-support-joining",
        source: "Rawpixel",
        sourceLink: "https://www.rawpixel.com/",
        licence: "Free license",
        licenceLink: "https://www.rawpixel.com/services/image-licenses/free-license",
    }


}
