import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-two-landing-page',
    templateUrl: '../_shared/landing-pages/unit-landing-page.component.html',
    styleUrls: ['../_shared/landing-pages/unit-landing-page.component.css']
})
export class UnitTwoLandingComponent {

    title: string = "Unit 2: Postsecondary Employment and Education Options";
    pageTitle: string = "Postsecondary Employment and Education Options | Unit 2 | T-Folio";

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    UnitLandingPageData = {
        breadcrumbs: {
            label: "Unit 2",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                }
            ]
        },
        portfolioitems: [
            {
                text: "SMART Goals Worksheet",
            },
            {
                text: "Postsecondary Goals Worksheet",
            },
            {
                text: "Career Exploration Worksheet",
            },
            {
                text: "College Navigator School Listings",
            },
            {
                text: "Course of Study Worksheet",
            },
            {
                text: "Agency Connections Worksheet",
            },
            {
                text: "Action Plan for Employment and Education",
            },
        ],
        summary: "Unit 2 will guide youth in creating goals and exploring options for postsecondary education and employment based on the strengths, preferences, interests, and needs they have identified in Unit 1. They will set goals, explore options, design a course of study, and write an action plan.",
        buttons: [
            {
                route: "/unit-2/lesson-1",
                linktext: "Lesson 1: How to Set Goals",
                color: "btn-success",
                image: "/img/unit-2/lesson-1/LESSON 1_How-to-Set-Goals_pexels-photo-382167_ps.jpg",
                alttext: "A medium-sized sailboat is heading out to sea on a partly cloudy day with two passengers on the back deck.",
            },
            {
                route: "/unit-2/lesson-2",
                linktext: "Lesson 2: Employment and Education Goals",
                color: "btn-success",
                image: "/img/unit-2/lesson-2/LESSON 2_Employment-and-Education-Goals_jungwoo-hong-100345.jpg",
                alttext: "Four white arrows are nailed to a wooden fence and pointing upward. Three more arrows are partially cropped from the frame below.",
            },
            {
                route: "/unit-2/lesson-3",
                linktext: "Lesson 3: Exploring Employment Options",
                color: "btn-success",
                image: "/img/unit-2/lesson-3/LESSON 3_Exploring-Employment-Options_AMERICANED_MC2_021-2.jpg",
                alttext: "An older person wearing a blue patterned sweater and smiling is showing a paper to a younger person who is wearing a gray long sleeve shirt and looking thoughtful.",
            },
            {
                route: "/unit-2/lesson-4",
                linktext: "Lesson 4: Exploring Postsecondary Education Options",
                color: "btn-success",
                image: "/img/unit-2/lesson-4/LESSON 4_Exploring-Postsecondary-Education-Options_backtoschool.jpg",
                alttext: "Scrabble tiles spell out “Back to School” on top of a yellow notepad next to two yellow #2 pencils.",
            },
            {
                route: "/unit-2/lesson-5",
                linktext: "Lesson 5: Course of Study",
                color: "btn-success",
                image: "/img/unit-2/lesson-5/LESSON 5_Course-of-Study_kimberly-farmer-287677.jpg",
                alttext: "The edges of a colorful stack of hardback textbooks appear on the left side of the image with the background blurred.",
            },
            {
                route: "/unit-2/lesson-6",
                linktext: "Lesson 6: Exploring Agency Options",
                color: "btn-success",
                image: "/img/unit-2/lesson-6/LESSON 6_Exploring-Agency-Connections_william-bout-264826.jpg",
                alttext: "The image shows a network of cables that connect to form geometrical patterns.",
            },
            {
                route: "/unit-2/lesson-7",
                linktext: "Lesson 7: Action Plan for Employment and Education",
                color: "btn-success",
                image: "/img/unit-2/lesson-7/LESSON 7_Action-Plan_tamarcus-brown-131428.jpg",
                alttext: "Two hands work in a spiral sketchbook with the left one positioning a ruler and the right one holding a pen.",
            },
        ],
        image: "/img/unit-2/_landing/UNIT_2_LANDING_PAGE_school4.jpg",
        alttext: "A black and white analog clock is positioned on a green chalkboard and is surrounded by a yellow chalk spiral with around 30 rings. The bottom half of the spiral and the rest of the chalkboard are covered in colorful chalked words, drawings, and smudges.",
        caption: "by Aleks on", 
        photoText: "Photo",
        photoLink: "https://www.pexels.com/photo/black-round-analog-wall-clock-121734/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "/ CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",
    }
}
