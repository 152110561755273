﻿import { Component, Input, Output, EventEmitter} from "@angular/core";

@Component({
    selector: 'self-determination-scale-radio-button-group',
    templateUrl: './radio-button-group.component.html',
    styleUrls: ['./radio-button-group.component.css']
})
export class SelfDeterminationScaleRadioButtonGroupComponent {

    @Input('label-number') labelNumber: string;
    @Input() statement: string;
    @Input() identifier: string;
    @Input() val: number;

    @Output() valChange: EventEmitter<number>;

    constructor() {
        this.valChange = new EventEmitter<number>();
    }

    getVal(event:any) {
        this.valChange.emit(event.target.value);
    }
}