import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-one-landingpage',
    templateUrl: '../_shared/landing-pages/unit-landing-page.component.html',
    styleUrls: ['../_shared/landing-pages/unit-landing-page.component.css']
})
export class UnitOneLandingpage {

    title: string = "Unit 1: Job Exploration Groundwork";
    pageTitle: string = "Job Exploration Groundwork | Unit 1 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }


    doPrint(): void {
        window.print();
    }

    UnitLandingPageData = {
        breadcrumbs: {
            label: "Unit 1",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                }
            ]
        },
        portfolioitems: [
            {
                text: "Strengths Assessment",
            },
            {
                text: "Preferences Assessment",
            },
            {
                text: "Career Clusters Interest Survey",
            },
            {
                text: "Challenges in the Classroom and Beyond ",
            },
            {
                text: "Needs Assessment",
            },
            {
                text: "AIR Self - Determination Scale",
            },
            {
                text: "Skills Assessment",
            },
            {
                text: "Synthesizing Assessment Results: Bringing It All Together",
            },
            {
                text: "Career Research Worksheet",
            }
        ],
        summary: "Unit 1 guides youth through a series of assessments that can be added to their transition portfolio. The final two lessons ask students to synthesize and reflect on the information gathered and to use it in their exploration of potential jobs and careers.",
        buttons: [
            {
                route: "/unit-1/lesson-1",
                linktext: "Lesson 1: Strengths",
                color: "btn-success",
                image: "/img/unit-1/LESSON 1_teen4.jpg",
                alttext: "A young person with orange braids looks over their right shoulder with eyes closed and a smile.",
            },
            {
                route: "/unit-1/lesson-2",
                linktext: "Lesson 2: Preferences",
                color: "btn-success",
                image: "/img/unit-1/LESSON 2_Checklist_yes_no.jpg",
                alttext: "A hand holding a sharpie pen writes a checkmark on a small notebook page with the word “Checklist” at the top and the words “yes” and “no” underneath. The yes has a check to the left of it and the no has an x to the left of it.",
            },
            {
                route: "/unit-1/lesson-3",
                linktext: "Lesson 3: Interests",
                color: "btn-success",
                image: "/img/unit-1/LESSON 3_jobs_photography.jpg",
                alttext: "A person holds a printed photograph of a lake and mountains in front of their computer screen.",
            },
            {
                route: "/unit-1/lesson-4",
                linktext: "Lesson 4: Needs",
                color: "btn-success",
                image: "/img/unit-1/LESSON 4_lightbulb_thoughtbubbles.jpg",
                alttext: "A chalkboard drawing of a light bulb in the center and six circles drawn out from the lightbulb, like thought bubbles.",
            },
            {
                route: "/unit-1/lesson-5",
                linktext: "Lesson 5: Self-Determination",
                color: "btn-success",
                image: "/img/unit-1/LESSON 5_zachary-nelson-192289.jpg",
                alttext: "Three young people are jumping in the air with big smiles on their faces. They are on a sidewalk that has mounds of snow on either side, next to a three way intersection.",
            },
            {
                route: "/unit-1/lesson-6",
                linktext: "Lesson 6: Work Maturity Skills",
                color: "btn-success",
                image: "img/unit-1/LESSON 6_climate-kic-350836.jpg",
                alttext: "Five college-aged students are gathered around a small, wooden table working on a design project with Legos, play dough, and other creative materials, as well as three computer laptops.",
            },
            {
                route: "/unit-1/lesson-7",
                linktext: "Lesson 7: Synthesize and Reflect",
                color: "btn-success",
                image: "/img/unit-1/LESSON 7_nature-laptop-outside-macbook.jpg",
                alttext: " A person holds two tablets, one vertical in front of their face and one horizontal to the left of their face. The person is standing in front of a panoramic blue sky with fluffy clouds, and the screens of the tablets match the sky. There is also a laptop in the right hand corner whose screen also matches the blue sky.",
            },
            {
                route: "/unit-1/lesson-8",
                linktext: "Lesson 8: Researching Occupations",
                color: "btn-success",
                image: "/img/unit-1/LESSON 8_jobs_happy.jpg",
                alttext: "A person sitting in a computer lab stretches their arms upwards while smiling at their computer screen.",
            },
        ],
        image: "/img/unit-1/UNIT_1_LANDING_PAGE_pexels-photo-296230.jpg",
        alttext: "A shovel is shown in extreme close up digging into the earth. Small green leaves are sprouting next to it and in the background.",
        caption: "by Lukas on",
        photoText: "Photo",
        photoLink: "https://www.pexels.com/photo/agriculture-backyard-blur-close-up-296230/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "/ CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",
    }
}
