﻿import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: 'tfolio-textarea',
    template: `
<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div contenteditable="true" 
                [id]="identifier"
                [textContent]="currentText"
                (keypress)="keyed($event)"
                (paste)="pasted($event);"
                (input)="currentText = $event.target.textContent"
                class="tfolio-noscroll-textarea"
                [ngStyle]="textareaWidthHeightAttrVal"
                ngDefaultControl></div>
            <div class="tfolio-noscroll-textarea-counts hidden-print" 
                [ngStyle]="countMaxWidthAttrVal">{{maxLength - currentText.length}} / {{maxLength}}</div>
        </div>
    </div>
</div>
`,
    styles: [`
    div.tfolio-noscroll-textarea {
        font-family:Consolas;
        font-size:20px;
        margin:0 auto;
        padding:0;
        border:1px solid rgb(169,169,169);
        word-break:break-all;
    }
    div.tfolio-noscroll-textarea-counts {
        text-align:right;
        font-size:small;
        margin:0; 
        padding:0;
    }

@media print {
    div.tfolio-noscroll-textarea {
        font-family:Consolas;
        font-size:20px;
        margin:0 auto;
        padding:0;
        border:1px solid transparent;
        word-break:break-all;
    }
    div.tfolio-noscroll-textarea-counts {
        text-align:right;
        font-size:small;
        margin:0;
        padding:0;
    }
}
`]
})
export class TFolioTextareaComponent implements OnInit {
    @Input() rows: number;
    @Input() cols: number;
    @Input() identifier: string;

    currentText: string = "";
    maxLength: number;

    textareaWidthHeightAttrVal: any;
    countMaxWidthAttrVal: any;

    ngOnInit(): void {
        this.maxLength = this.rows * this.cols;

        this.textareaWidthHeightAttrVal = {
            'max-width': ((this.cols * 11) + 5) + 'px',
            'min-height': (this.rows * (23+6)) + 'px'
        };
        this.countMaxWidthAttrVal = {
            'max-width': ((this.cols * 11) + 5) + 'px',
        };
    }

    keyed(event: KeyboardEvent): void {
        if (this.currentText.length >= this.maxLength) {
            event.returnValue = false;
        }
        if (event.keyCode == 13) {
            event.returnValue = false;
        }
        
    }

    pasted(event: ClipboardEvent): void {

        var clip = event.clipboardData.getData('text');
        while (clip.match('\t'))
            clip = clip.replace('\t', ' ');
        while (clip.match('\r\n'))
            clip = clip.replace('\r\n', ' ');

        this.doPaste(event.srcElement, clip);

        event.returnValue = false;
    }

    doPaste(div, text) {

        var sel = window.getSelection();

        var front = div.innerText.substring(0, sel);
        var back = div.innerText.substring(sel, div.innerText.length);

        if (front.length + text.length + back.length > this.maxLength)
            return false;

        this.currentText = front + text + back;
    }
}