import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-two-lesson-two-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitTwoLessonTwoLandingComponent {
    title = "Lesson 2: Employment and Education Goals";
    pageTitle: string = "Employment and Education Goals | Unit 2, Lesson 2 | T-Folio";
    bannerHeader = "Postsecondary Employment and Education Options: Lesson 2";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 2",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-2",
                    linktext: "Unit 2"
                }
            ]
        },
        summary: "In Lesson 2, youth will identify goals for postsecondary education and employment by utilizing the work from Unit 1 and synthesizing the information into their postsecondary goals in employment and education.",
        objectives: [
            "Identify a postsecondary goal in employment.",
            "Identify a postsecondary goal in education.",
        ],
        buttons: [
            {
                route: "/unit-2/lesson-2/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-2/lesson-2/postsecondary-goals-worksheet",
                linktext: "Postsecondary Goals Worksheet",
                color: "btn-primary",
                window: false,
            },
            {
                route: '/img/unit-2/lesson-2/T-Folio_Unit-2_Lesson-2_Employment-And-Education-Goals.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-2/lesson-2/LESSON 2_Employment-and-Education-Goals_jungwoo-hong-100345.jpg",
        imgalt: "Four white arrows are nailed to a wooden fence and pointing upward. Three more arrows are partially cropped from the frame below.",
        caption: "by Jungwoo Hong on ",
        source: "Unsplash",
        photoLink: "https://unsplash.com/photos/cYUMaCqMYvI",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",

    }
}
