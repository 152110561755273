import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "a-to-z",
    templateUrl: "./a-to-z.component.html",
    styleUrls: ["./a-to-z.component.css"]
})
export class UnitFiveLessonThreeAToZComponent {

    title: string = "A-Z for Independent Living";
    pageTitle: string = this.title + " | Unit 5, Lesson 3 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
