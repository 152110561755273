import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-one-lesson-one-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitOneLessonOneLandingComponent {
    title = "Lesson 1: Strengths";
    pageTitle: string = "Strengths | Unit 1, Lesson 1 | T-Folio";
    bannerHeader = "Job Exploration Groundwork: Lesson 1";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 1",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-1",
                    linktext: "Unit 1"
                }
            ]
        },
        summary: "In Lesson 1, students will identify their own strengths in various categories (personal, work-related, and specialty). They will complete a Strengths Assessment that can be added to the student's portfolio. The lesson facilitates self-reflection, assessment, and self-advocacy.",
        objectives: [
            "Identify strengths as it relates to job exploration.",
            "Develop and practice self-determination skills by sharing identified personal strengths and talents.",
            "Apply readiness methods and supports when taking strengths assessment.",
            "Complete a strength assessment.",
        ],
        buttons: [
            {
                route: "/unit-1/lesson-1/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            },
            {
                route: "/unit-1/lesson-1/strengths-assessment",
                linktext: "Strengths Assessment",
                color: "btn-primary",
            },
            {
                route: "/img/unit-1/lesson-1/T-Folio_Unit-1_Lesson-1_Identifying-Your-Strengths.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-1/LESSON 1_teen4.jpg",
        imgalt: "",
        caption: " on",
        photoLink: "https://www.pexels.com/photo/adolescent-adult-attractive-beautiful-235485/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",


    }
}
