import { Component, HostListener } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'self-reflection',
    templateUrl: './_self-reflection.component.html',
    styleUrls: ['./_self-reflection.component.css']
})
export class IntroductionLessonTwoSelfIntroductionComponent {

    title: string = "Self-Reflection: Taking Career Assessments";
    pageTitle: string = this.title + " | Introduction, Lesson 2 | T-Folio";
    column1: string = "Assessment Readiness";
    column2: string = "Strategies and/or Supports to Perform My Best"
    rowNum: number = 8; 
    content = [
        { id: '01', question: 'What I know: ', example1: '(example: I know I’m most successful in the front row.)', example2: '(example: I need noise reducing earphones to fully focus on the assessment.)'},
        { id: '02', question: 'What I think I know: ', example1: '(example: I think I can navigate online OK but it might make me anxious.)', example2: '(example: Frequent breaks help me stay focused but I’m not clear what the rules are.)' },
        { id: '03', question: 'What I still want to know or need: ', example1: '(example: I need some ideas on how to keep my test-taking anxiety in check.)', example2: '(example: Will I get support in reading some of the text?)' },

    ]; 

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    doPrint(): void {
        window.print();
    }
}
