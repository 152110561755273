import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "job-shadow-workbook-observation",
    templateUrl: "./observation.component.html",
    styleUrls: ['./job-shadow-workbook.component.css']
})
export class UnitThreeLessonFourWorkbookObservationComponent {

    title: string = "Job Shadow Observation";
    pageTitle: string = this.title + " | Job Shadow Workbook | Unit 3, Lesson 4 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }

}
