import { Component, Input, OnInit } from "@angular/core";

declare let $;

@Component({
    selector: 'unit-one-lesson-seven-textarea-block',
    templateUrl: './_textarea-block.component.html',
    styleUrls: ['./_textarea-block.component.css']
})
export class UnitOneLessonSevenTextareaBlockComponent implements OnInit {

    @Input() name: string;
    @Input() traitCategory: string;
    @Input() CommonTraitsOne :string; 
    @Input() CommonTraitsTwo: string;
    @Input() CommonTraitsThree: string;
    @Input() CommonTraitsFour: string;
    @Input() CommonTraitsFive: string;
    @Input() combinedTrait: string;
    @Input() reflectMe: string;
    @Input() headerHeight: string; 

    tname: string = "TEST!";

    ngOnInit() {
        //var na = document.getElementById("synthesizing-textarea-05");
        //var af = document.createAttribute("autofocus");
        //af.value = "autofocus";
        //na.attributes.setNamedItem(af);
    }

    onChange($event) {

        var textareaObject = $('#'+$event.target.id);

        textareaObject.val('');
        textareaObject.removeClass('not-print-visible');
        textareaObject.addClass('print-visible');
        

        /*
        var na = document.getElementById("synthesizing-textarea-01");
        na.innerHTML = '';
        */


    }

    doPrint(): void {
        window.print();
    }
}
