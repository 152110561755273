import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-one-lesson-seven-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitOneLessonSevenLandingComponent {
    title = "Lesson 7: Synthesize and Reflect";
    pageTitle = "Synthesize and Reflect | Unit 1, Lesson 7 | T-Folio";
    bannerHeader = "Job Exploration Groundwork: Lesson 7";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 7",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-1",
                    linktext: "Unit 1"
                }
            ]
        },
        summary: "Lesson 7 asks students to bring together their assessment results from the first six lessons of Unit 1, in order to create a more complete and integrated picture of their strengths, preferences, interests, and needs.",
        objectives: [
            "Analyze and combine connected traits relating to strengths, needs, interests, and preferences.",
            "Describe and illustrate how identified traits represent self.",
            "Complete Synthesizing Assessment Results: Bringing it all Together worksheet.",
        ],
        buttons: [
            {
                route: "/unit-1/lesson-7/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            },
            {
                route: "/unit-1/lesson-7/synthesize-reflect",
                linktext: "Synthesizing Assessment Results: Bringing It All Together",
                color: "btn-primary",
            },
            {
                route: "/img/unit-1/lesson-7/T-Folio_Unit-1_Lesson-7_Synthesize-And-Reflect.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-1/LESSON 7_nature-laptop-outside-macbook.jpg",
        imgalt: "A person holds two tablets, one vertical in front of their face and one horizontal to the left of their face. The person is standing in front of a panoramic blue sky with fluffy clouds, and the screens of the tablets match the sky. There is also a laptop in the right hand corner whose screen also matches the blue sky.",
        caption: "by Ed Gregory on",
        photoLink: "https://www.pexels.com/photo/nature-laptop-outside-macbook-6508/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",
    }
}
