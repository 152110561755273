﻿import { Component } from "@angular/core";


@Component({
    selector: 'tfolio-scroll',
    template: ` <a (click)="doScroll();" class="hidden-print" style="cursor:pointer">Back to Top</a> `,
    styles: ['']
})


export class TFolioScroll {
    doScroll() {
        window.scrollTo(0, 0);
    }
}