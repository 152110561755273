import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: "about",
    templateUrl: './about.component.html',
    styleUrls: ['./_shared/landing-pages/landing-page.component.css'],
})

export class AboutComponent {

    title: string = "About T-Folio";
    pageTitle: string = this.title + " | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }


    AboutLandingPageData = {
        breadcrumbs: {
            label: "About",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                }
            ]
        },
    }
}
