import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home.component";
import { PageNotFoundComponent } from "./page-not-found.component";

import { StudentCenteredIntroductionlandingpage } from "./introduction/_landing.component";
import { IntroductionLessonOneLandingComponent } from "./introduction/lesson-1/_landing.component";
import { IntroductionLessonOneGuideComponent } from "./introduction/lesson-1/_guide.component";
import { IntroductionWhatsImportantToMeComponent } from "./introduction/lesson-1/whats-important-to-me.component";
import { IntroductionWhatsImportantToMeFacilitatorComponent } from "./introduction/lesson-1/whats-important-to-me-facilitator.component";
import { IntroductionLessonTwoLandingComponent } from "./introduction/lesson-2/_landing.component";
import { IntroductionLessonTwoGuideComponent } from "./introduction/lesson-2/_guide.component";
import { IntroductionLessonTwoSelfIntroductionComponent } from "./introduction/lesson-2/_self-reflection.component";

import { UnitOneLandingpage } from "./unit-1/_landing.component";
import { UnitOneLessonOneLandingComponent } from "./unit-1/lesson-1/_landing.component";
import { UnitOneLessonOneGuideComponent } from "./unit-1/lesson-1/_guide.component";
import { UnitOneLessonOneStrengthsAssessmentComponent } from "./unit-1/lesson-1/strengths-assessment.component";
import { UnitOneLessonTwoLandingComponent } from "./unit-1/lesson-2/_landing.component";
import { UnitOneLessonTwoGuideComponent } from "./unit-1/lesson-2/_guide.component";
import { UnitOneLessonTwoPreferencesAssessmentComponent } from "./unit-1/lesson-2/preferences-assessment.component";
import { UnitOneLessonThreeLandingComponent } from "./unit-1/lesson-3/_landing.component";
import { UnitOneLessonThreeGuideComponent } from "./unit-1/lesson-3/_guide.component";
import { UnitOneLessonThreeInterestsSurveyComponent } from "./unit-1/lesson-3/interests-survey.component";
import { UnitOneLessonThreeEncuestaDeInteresComponent } from "./unit-1/lesson-3/encuesta-de-interes.component";
import { UnitOneLessonFourLandingComponent } from "./unit-1/lesson-4/_landing.component";
import { UnitOneLessonFourGuideComponent } from "./unit-1/lesson-4/_guide.component";
import { UnitOneLessonFourNeedsAssessmentComponent } from "./unit-1/lesson-4/needs-assessment.component";
import { UnitOneLessonFourChallengesInTheClassroomComponent } from "./unit-1/lesson-4/classroom-challenges.component";
import { UnitOneLessonFiveLandingComponent } from "./unit-1/lesson-5/_landing.component";
import { UnitOneLessonFiveGuideComponent } from "./unit-1/lesson-5/_guide.component";
import { UnitOneLessonFiveSelfDeterminationScaleComponent } from "./unit-1/lesson-5/self-determination-scale.component";
import { UnitOneLessonSixLandingComponent } from "./unit-1/lesson-6/_landing.component";
import { UnitOneLessonSixGuideComponent } from "./unit-1/lesson-6/_guide.component";
import { UnitOneLessonSixWorkMaturitySkillsComponent } from "./unit-1/lesson-6/work-maturity-skills.component";
import { UnitOneLessonSevenLandingComponent } from "./unit-1/lesson-7/_landing.component";
import { UnitOneLessonSevenGuideComponent } from "./unit-1/lesson-7/_guide.component";
import { UnitOneLessonSevenSynthesizeReflectComponent } from "./unit-1/lesson-7/synthesize-reflect.component";
import { UnitOneLessonEightLandingComponent } from "./unit-1/lesson-8/_landing.component";
import { UnitOneLessonEightGuideComponent } from "./unit-1/lesson-8/_guide.component";
import { UnitOneLessonEightResearchingOccupationComponent } from "./unit-1/lesson-8/researching-occupations.component"; 

import { UnitTwoLandingComponent } from "./unit-2/_landing.component";
import { UnitTwoLessonOneLandingComponent } from "./unit-2/lesson-1/_landing.component";
import { UnitTwoLessonOneGuideComponent } from "./unit-2/lesson-1/_guide.component";
import { UnitTwoLessonOneSmartGoalsWorksheetComponent } from "./unit-2/lesson-1/smart-goals-worksheet.component";
import { UnitTwoLessonTwoLandingComponent } from "./unit-2/lesson-2/_landing.component";
import { UnitTwoLessonTwoGuideComponent } from "./unit-2/lesson-2/_guide.component";
import { UnitTwoLessonTwoPostsecondaryGoalsWorksheetComponent } from "./unit-2/lesson-2/postsecondary-goals-worksheet.component";
import { UnitTwoLessonThreeLandingComponent } from "./unit-2/lesson-3/_landing.component";
import { UnitTwoLessonThreeGuideComponent } from "./unit-2/lesson-3/_guide.component";
import { UnitTwoLessonThreeCareerExplorationWorksheetComponent } from "./unit-2/lesson-3/career-exploration-worksheet.component";
import { UnitTwoLessonFourLandingComponent } from "./unit-2/lesson-4/_landing.component";
import { UnitTwoLessonFourGuideComponent } from "./unit-2/lesson-4/_guide.component";
import { UnitTwoLessonFiveLandingComponent } from "./unit-2/lesson-5/_landing.component";
import { UnitTwoLessonFiveGuideComponent } from "./unit-2/lesson-5/_guide.component";
import { UnitTwoLessonFiveCourseOfStudyWorksheetComponent } from "./unit-2/lesson-5/course-of-study-worksheet.component";
import { UnitTwoLessonSixLandingComponent } from "./unit-2/lesson-6/_landing.component";
import { UnitTwoLessonSixGuideComponent } from "./unit-2/lesson-6/_guide.component";
import { UnitTwoLessonSixAgencyConnectionsWorksheetComponent } from "./unit-2/lesson-6/agency-connections-worksheet.component";
import { UnitTwoLessonSevenLandingComponent } from "./unit-2/lesson-7/_landing.component";
import { UnitTwoLessonSevenGuideComponent } from "./unit-2/lesson-7/_guide.component";
import { UnitTwoLessonSevenActionPlanComponent } from "./unit-2/lesson-7/action-plan.component";

import { UnitThreeLandingComponent } from "./unit-3/_landing.component";
import { UnitThreeLessonOneLandingComponent } from "./unit-3/lesson-1/_landing.component";
import { UnitThreeLessonOneGuideComponent } from "./unit-3/lesson-1/_guide.component";
import { UnitThreeLessonTwoLandingComponent } from "./unit-3/lesson-2/_landing.component";
import { UnitThreeLessonTwoGuideComponent } from "./unit-3/lesson-2/_guide.component";
import { UnitThreeLessonTwoScansSkillsAssessmentComponent } from "./unit-3/lesson-2/scans-skills-assessment.component";
import { UnitThreeLessonThreeLandingComponent } from "./unit-3/lesson-3/_landing.component";
import { UnitThreeLessonThreeGuideComponent } from "./unit-3/lesson-3/_guide.component";
import { UnitThreeLessonThreeInformationalInterviewWorksheetComponent } from "./unit-3/lesson-3/informational-interview-worksheet.component";
import { UnitThreeLessonFourLandingComponent } from "./unit-3/lesson-4/_landing.component";
import { UnitThreeLessonFourGuideComponent } from "./unit-3/lesson-4/_guide.component";
import { UnitThreeLessonFourWorkbookLandingComponent } from "./unit-3/lesson-4/workbook-landing.component";
import { UnitThreeLessonFourWorkbookCoverLetterComponent } from "./unit-3/lesson-4/workbook/cover-letter.component";
import { UnitThreeLessonFourWorkbookChecklistComponent } from "./unit-3/lesson-4/workbook/job-shadow-checklist.component";
import { UnitThreeLessonFourWorkbookPhoneScriptComponent } from "./unit-3/lesson-4/workbook/phone-script.component";
import { UnitThreeLessonFourWorkbookEmailScriptComponent } from "./unit-3/lesson-4/workbook/email-script.component";
import { UnitThreeLessonFourWorkbookStudentQuestionsComponent } from "./unit-3/lesson-4/workbook/student-questions.component";
import { UnitThreeLessonFourWorkbookDayOfComponent } from "./unit-3/lesson-4/workbook/day-of.component";
import { UnitThreeLessonFourWorkbookHostFeedbackComponent } from "./unit-3/lesson-4/workbook/host-feedback.component";
import { UnitThreeLessonFourWorkbookObservationComponent } from "./unit-3/lesson-4/workbook/observation.component";
import { UnitThreeLessonFourWorkbookReflectionComponent } from "./unit-3/lesson-4/workbook/reflection.component";
import { UnitThreeLessonFourWorkbookEvaluationComponent } from "./unit-3/lesson-4/workbook/evaluation.component";
import { UnitThreeLessonFourWorkbookThankYouComponent } from "./unit-3/lesson-4/workbook/thank-you.component";
import { UnitThreeLessonFiveLandingComponent } from "./unit-3/lesson-5/_landing.component";
import { UnitThreeLessonFiveGuideComponent } from "./unit-3/lesson-5/_guide.component";
import { UnitThreeLessonFivePerformanceReviewComponent } from "./unit-3/lesson-5/performance-review.component";
import { UnitThreeLessonSixLandingComponent } from "./unit-3/lesson-6/_landing.component";
import { UnitThreeLessonSixGuideComponent } from "./unit-3/lesson-6/_guide.component";

import { UnitFourLandingComponent } from "./unit-4/_landing.component";
import { UnitFourLessonOneLandingComponent } from "./unit-4/lesson-1/_landing.component";
import { UnitFourLessonOneGuideComponent } from "./unit-4/lesson-1/_guide.component";
import { UnitFourLessonOneWorkMaturitySkillsListComponent } from "./unit-4/lesson-1/work-maturity-skills-list.component";
import { UnitFourLessonTwoLandingComponent } from "./unit-4/lesson-2/_landing.component";
import { UnitFourLessonTwoGuideComponent } from "./unit-4/lesson-2/_guide.component";
import { UnitFourLessonTwoResumeKeywordsWorksheetComponent } from "./unit-4/lesson-2/resume-keywords-worksheet.component";
import { UnitFourLessonTwoResumeTemplateComponent } from "./unit-4/lesson-2/resume-template.component";
import { UnitFourLessonThreeLandingComponent } from "./unit-4/lesson-3/_landing.component";
import { UnitFourLessonThreeGuideComponent } from "./unit-4/lesson-3/_guide.component";
import { UnitFourLessonThreeElevatorSpeechWorksheetComponent } from "./unit-4/lesson-3/elevator-speech-worksheet.component";
import { UnitFourLessonThreeElevatorSpeechFeedbackComponent } from "./unit-4/lesson-3/elevator-speech-feedback.component";
import { UnitFourLessonFourLandingComponent } from "./unit-4/lesson-4/_landing.component";
import { UnitFourLessonFourGuideComponent } from "./unit-4/lesson-4/_guide.component";
import { UnitFourLessonFourPracticeJobApplicationComponent } from "./unit-4/lesson-4/practice-job-application.component";
import { UnitFourLessonFourJobApplicationsTrackerComponent } from "./unit-4/lesson-4/job-applications-tracker.component";
import { UnitFourLessonFiveLandingComponent } from "./unit-4/lesson-5/_landing.component";
import { UnitFourLessonFiveGuideComponent } from "./unit-4/lesson-5/_guide.component";

import { UnitFourLessonFiveInterviewEvaluationComponent } from "./unit-4/lesson-5/job-interview-evaluation.component";

import { UnitFourLessonFiveJobInterviewQuestionsComponent } from "./unit-4/lesson-5/job-interview-questions.component";
import { UnitFourLessonFiveJobInterviewFeedbackComponent } from "./unit-4/lesson-5/job-interview-feedback.component";
import { UnitFourLessonSixLandingComponent } from "./unit-4/lesson-6/_landing.component";
import { UnitFourLessonSixGuideComponent } from "./unit-4/lesson-6/_guide.component";
import { UnitFourLessonSixThankYouNoteTemplateComponent } from "./unit-4/lesson-6/thank-you-note-template.component"
import { UnitFourLessonSevenLandingComponent } from "./unit-4/lesson-7/_landing.component";
import { UnitFourLessonSevenGuideComponent } from "./unit-4/lesson-7/_guide.component";
import { UnitFourLessonSevenActivityActionPlanTemplateComponent } from "./unit-4/lesson-7/activity-action-plan-template.component";

import { UnitFiveLandingComponent } from "./unit-5/_landing.component";

import { UnitFiveLessonOneLandingComponent } from "./unit-5/lesson-1/_landing.component";
import { UnitFiveLessonOneGuideComponent } from "./unit-5/lesson-1/_guide.component";

import { UnitFiveLessonTwoLandingComponent } from "./unit-5/lesson-2/_landing.component";
import { UnitFiveLessonTwoGuideComponent } from "./unit-5/lesson-2/_guide.component";

import { UnitFiveLessonThreeLandingComponent } from "./unit-5/lesson-3/_landing.component";
import { UnitFiveLessonThreeGuideComponent } from "./unit-5/lesson-3/_guide.component";

import { UnitFiveLessonFourLandingComponent } from "./unit-5/lesson-4/_landing.component";
import { UnitFiveLessonFourGuideComponent } from "./unit-5/lesson-4/_guide.component";

import { UnitFiveLessonFiveLandingComponent } from "./unit-5/lesson-5/_landing.component";
import { UnitFiveLessonFiveGuideComponent } from "./unit-5/lesson-5/_guide.component";

import { UnitFiveLessonSixLandingComponent } from "./unit-5/lesson-6/_landing.component";
import { UnitFiveLessonSixGuideComponent } from "./unit-5/lesson-6/_guide.component";
 

import { UnitFiveLessonTwoSelfAdvocacyGoalsWorksheetComponent } from "./unit-5/lesson-2/self-advocacy-goals-worksheet.component";
import { UnitFiveLessonThreeAToZComponent } from "./unit-5/lesson-3/a-to-z.component";
import { UnitFiveLessonThreeIndependentLivingAssessmentComponent } from "./unit-5/lesson-3/independent-living-assessment.component";
import { UnitFiveLessonThreeIndependentLivingGoalsWorksheetComponent } from "./unit-5/lesson-3/independent-living-goals-worksheet.component";
import { UnitFiveLessonFiveActionPlanComponent } from "./unit-5/lesson-5/action-plan.component";
import { UnitFiveLessonSixFinalReflectionWorksheetComponent } from "./unit-5/lesson-6/final-reflection-worksheet.component";

import { FeedbackComponent } from "./feedback.component";
import { HelpComponent } from "./help.component";
import { CreateTicketComponent } from "./create-ticket.component";
import { AboutComponent } from "./about.component";
import { ConfirmRouteGuard } from './_guards/confirm-route-guard.component';

const appRoutes: Routes = [
    {
        path: "",
        component: HomeComponent
    },
    {
        path: "introduction",
        component: StudentCenteredIntroductionlandingpage
    },
    {
        path: "introduction/lesson-1",
        component: IntroductionLessonOneLandingComponent
    },
    {
        path: "introduction/lesson-1/guide",
        component: IntroductionLessonOneGuideComponent
    },
    {
        path: "introduction/lesson-1/whats-important-to-me",
        component: IntroductionWhatsImportantToMeComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "introduction/lesson-1/whats-important-to-me-facilitator",
        component: IntroductionWhatsImportantToMeFacilitatorComponent
    },
    {
        path: "introduction/lesson-2",
        component: IntroductionLessonTwoLandingComponent
    },
    {
        path: "introduction/lesson-2/guide",
        component: IntroductionLessonTwoGuideComponent
    },
    {
        path: "introduction/lesson-2/self-reflection",
        component: IntroductionLessonTwoSelfIntroductionComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-1",
        component: UnitOneLandingpage
    },
    {
        path: "unit-1/lesson-1",
        component: UnitOneLessonOneLandingComponent
    },
    {
        path: "unit-1/lesson-1/guide",
        component: UnitOneLessonOneGuideComponent
    },
    {
        path: "unit-1/lesson-1/strengths-assessment",
        component: UnitOneLessonOneStrengthsAssessmentComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-1/lesson-2",
        component: UnitOneLessonTwoLandingComponent
    },
    {
        path: "unit-1/lesson-2/guide",
        component: UnitOneLessonTwoGuideComponent
    },
    {
        path: "unit-1/lesson-2/preferences-assessment",
        component: UnitOneLessonTwoPreferencesAssessmentComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-1/lesson-3",
        component: UnitOneLessonThreeLandingComponent
    },
    {
        path: "unit-1/lesson-3/guide",
        component: UnitOneLessonThreeGuideComponent
    },
    {
        path: "unit-1/lesson-3/interests-survey",
        component: UnitOneLessonThreeInterestsSurveyComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-1/lesson-3/encuesta-de-interes",
        component: UnitOneLessonThreeEncuestaDeInteresComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-1/lesson-4",
        component: UnitOneLessonFourLandingComponent
    },
    {
        path: "unit-1/lesson-4/guide",
        component: UnitOneLessonFourGuideComponent
    },
    {
        path: "unit-1/lesson-4/needs-assessment",
        component: UnitOneLessonFourNeedsAssessmentComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-1/lesson-4/classroom-challenges",
        component: UnitOneLessonFourChallengesInTheClassroomComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-1/lesson-5",
        component: UnitOneLessonFiveLandingComponent
    },
    {
        path: "unit-1/lesson-5/guide",
        component: UnitOneLessonFiveGuideComponent
    },
    {
        path: "unit-1/lesson-5/self-determination-scale",
        component: UnitOneLessonFiveSelfDeterminationScaleComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-1/lesson-6",
        component: UnitOneLessonSixLandingComponent
    },
    {
        path: "unit-1/lesson-6/guide",
        component: UnitOneLessonSixGuideComponent
    },
    {
        path: "unit-1/lesson-6/work-maturity-skills",
        component: UnitOneLessonSixWorkMaturitySkillsComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-1/lesson-7",
        component: UnitOneLessonSevenLandingComponent
    },
    {
        path: "unit-1/lesson-7/guide",
        component: UnitOneLessonSevenGuideComponent
    },
    {
        path: "unit-1/lesson-7/synthesize-reflect",
        component: UnitOneLessonSevenSynthesizeReflectComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-1/lesson-8",
        component: UnitOneLessonEightLandingComponent
    },
    {
        path: "unit-1/lesson-8/guide",
        component: UnitOneLessonEightGuideComponent
    },
    {
        path: "unit-1/lesson-8/researching-occupation",
        component: UnitOneLessonEightResearchingOccupationComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-2",
        component: UnitTwoLandingComponent
    },
    {
        path: "unit-2/lesson-1",
        component: UnitTwoLessonOneLandingComponent
    },
    {
        path: "unit-2/lesson-1/guide",
        component: UnitTwoLessonOneGuideComponent
    },
    {
        path: "unit-2/lesson-1/smart-goals-worksheet",
        component: UnitTwoLessonOneSmartGoalsWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    }, 
    {
        path: "unit-2/lesson-2",
        component: UnitTwoLessonTwoLandingComponent
    },
    {
        path: "unit-2/lesson-2/guide",
        component: UnitTwoLessonTwoGuideComponent
    },
    {
        path: "unit-2/lesson-2/postsecondary-goals-worksheet",
        component: UnitTwoLessonTwoPostsecondaryGoalsWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
,    },
    {
        path: "unit-2/lesson-3",
        component: UnitTwoLessonThreeLandingComponent
    },
    {
        path: "unit-2/lesson-3/guide",
        component: UnitTwoLessonThreeGuideComponent
    },
    {
        path: "unit-2/lesson-3/career-exploration-worksheet",
        component: UnitTwoLessonThreeCareerExplorationWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-2/lesson-4",
        component: UnitTwoLessonFourLandingComponent
    },
    {
        path: "unit-2/lesson-4/guide",
        component: UnitTwoLessonFourGuideComponent
    },
    {
        path: "unit-2/lesson-5",
        component: UnitTwoLessonFiveLandingComponent
    },
    {
        path: "unit-2/lesson-5/guide",
        component: UnitTwoLessonFiveGuideComponent
    },
    {
        path: "unit-2/lesson-5/course-of-study-worksheet",
        component: UnitTwoLessonFiveCourseOfStudyWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-2/lesson-6",
        component: UnitTwoLessonSixLandingComponent
    },
    {
        path: "unit-2/lesson-6/guide",
        component: UnitTwoLessonSixGuideComponent
    },
    {
        path: "unit-2/lesson-6/agency-connections-worksheet",
        component: UnitTwoLessonSixAgencyConnectionsWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-2/lesson-7",
        component: UnitTwoLessonSevenLandingComponent
    },
    {
        path: "unit-2/lesson-7/guide",
        component: UnitTwoLessonSevenGuideComponent
    },
    {
        path: "unit-2/lesson-7/action-plan",
        component: UnitTwoLessonSevenActionPlanComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3",
        component: UnitThreeLandingComponent
    },
    {
        path: "unit-3/lesson-1",
        component: UnitThreeLessonOneLandingComponent
    },
    {
        path: "unit-3/lesson-1/guide",
        component: UnitThreeLessonOneGuideComponent
    },
    {
        path: "unit-3/lesson-2",
        component: UnitThreeLessonTwoLandingComponent
    },
    {
        path: "unit-3/lesson-2/guide",
        component: UnitThreeLessonTwoGuideComponent
    },
    {
        path: "unit-3/lesson-2/scans-skills-assessment",
        component: UnitThreeLessonTwoScansSkillsAssessmentComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-3",
        component: UnitThreeLessonThreeLandingComponent
    },
    {
        path: "unit-3/lesson-3/guide",
        component: UnitThreeLessonThreeGuideComponent
    },
    {
        path: "unit-3/lesson-3/informational-interview-worksheet",
        component: UnitThreeLessonThreeInformationalInterviewWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4",
        component: UnitThreeLessonFourLandingComponent
    },
    {
        path: "unit-3/lesson-4/guide",
        component: UnitThreeLessonFourGuideComponent
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook",
        component: UnitThreeLessonFourWorkbookLandingComponent
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/cover-letter",
        component: UnitThreeLessonFourWorkbookCoverLetterComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/checklist",
        component: UnitThreeLessonFourWorkbookChecklistComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/phone-script",
        component: UnitThreeLessonFourWorkbookPhoneScriptComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/email-script",
        component: UnitThreeLessonFourWorkbookEmailScriptComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/student-questions",
        component: UnitThreeLessonFourWorkbookStudentQuestionsComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/day-of",
        component: UnitThreeLessonFourWorkbookDayOfComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/host-feedback",
        component: UnitThreeLessonFourWorkbookHostFeedbackComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/observation",
        component: UnitThreeLessonFourWorkbookObservationComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/reflection",
        component: UnitThreeLessonFourWorkbookReflectionComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/evaluation",
        component: UnitThreeLessonFourWorkbookEvaluationComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-4/job-shadow-workbook/thank-you",
        component: UnitThreeLessonFourWorkbookThankYouComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-3/lesson-5",
        component: UnitThreeLessonFiveLandingComponent
    },
    {
        path: "unit-3/lesson-5/guide",
        component: UnitThreeLessonFiveGuideComponent
    },
    {
        path: "unit-3/lesson-5/performance-review",
        component: UnitThreeLessonFivePerformanceReviewComponent,
        canDeactivate: [ConfirmRouteGuard]
    },

    {
        path: "unit-3/lesson-6",
        component: UnitThreeLessonSixLandingComponent
    },
    {
        path: "unit-3/lesson-6/guide",
        component: UnitThreeLessonSixGuideComponent
    },
    {
        path: "unit-4",
        component: UnitFourLandingComponent
    },
    {
        path: "unit-4/lesson-1",
        component: UnitFourLessonOneLandingComponent
    },
    {
        path: "unit-4/lesson-1/guide",
        component: UnitFourLessonOneGuideComponent
    },
    {
        path: "unit-4/lesson-1/work-maturity-skills-list",
        component: UnitFourLessonOneWorkMaturitySkillsListComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-2",
        component: UnitFourLessonTwoLandingComponent
    },
    {
        path: "unit-4/lesson-2/guide",
        component: UnitFourLessonTwoGuideComponent
    },
    {
        path: "unit-4/lesson-2/resume-keywords-worksheet",
        component: UnitFourLessonTwoResumeKeywordsWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-2/resume-template",
        component: UnitFourLessonTwoResumeTemplateComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-3",
        component: UnitFourLessonThreeLandingComponent
    },
    {
        path: "unit-4/lesson-3/elevator-speech-worksheet",
        component: UnitFourLessonThreeElevatorSpeechWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-3/elevator-speech-feedback",
        component: UnitFourLessonThreeElevatorSpeechFeedbackComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-3/guide",
        component: UnitFourLessonThreeGuideComponent
    },
    {
        path: "unit-4/lesson-4",
        component: UnitFourLessonFourLandingComponent
    },
    {
        path: "unit-4/lesson-4/guide",
        component: UnitFourLessonFourGuideComponent
    },
    {
        path: "unit-4/lesson-4/practice-job-application",
        component: UnitFourLessonFourPracticeJobApplicationComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-4/job-applications-tracker",
        component: UnitFourLessonFourJobApplicationsTrackerComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-5",
        component: UnitFourLessonFiveLandingComponent
    },
    {
        path: "unit-4/lesson-5/guide",
        component: UnitFourLessonFiveGuideComponent
    },
    {
        path: "unit-4/lesson-5/job-interview-questions",
        component: UnitFourLessonFiveJobInterviewQuestionsComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-5/job-interview-feedback",
        component: UnitFourLessonFiveJobInterviewFeedbackComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-5/job-interview-evaluation",
        component: UnitFourLessonFiveInterviewEvaluationComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-6",
        component: UnitFourLessonSixLandingComponent
    },
    {
        path: "unit-4/lesson-6/guide",
        component: UnitFourLessonSixGuideComponent
    },
    {
        path: "unit-4/lesson-6/thank-you-note-template",
        component: UnitFourLessonSixThankYouNoteTemplateComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-4/lesson-7",
        component: UnitFourLessonSevenLandingComponent
    },
    {
        path: "unit-4/lesson-7/guide",
        component: UnitFourLessonSevenGuideComponent
    },
    {
        path: "unit-4/lesson-7/activity-action-plan-template",
        component: UnitFourLessonSevenActivityActionPlanTemplateComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-5",
        component: UnitFiveLandingComponent
    },
    {
        path: "unit-5/lesson-1",
        component: UnitFiveLessonOneLandingComponent
    },
    {
        path: "unit-5/lesson-1/guide",
        component: UnitFiveLessonOneGuideComponent
    },
    {
        path: "unit-5/lesson-1/work-maturity-skills-list",
        component: UnitFourLessonOneWorkMaturitySkillsListComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-5/lesson-2",
        component: UnitFiveLessonTwoLandingComponent
    },
    {
        path: "unit-5/lesson-2/guide",
        component: UnitFiveLessonTwoGuideComponent
    },
    {
        path: "unit-5/lesson-2/resume-keywords-worksheet",
        component: UnitFourLessonTwoResumeKeywordsWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-5/lesson-2/self-advocacy-goals-worksheet",
        component: UnitFiveLessonTwoSelfAdvocacyGoalsWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-5/lesson-3",
        component: UnitFiveLessonThreeLandingComponent,

    },
    {
        path: "unit-5/lesson-3/guide",
        component: UnitFiveLessonThreeGuideComponent,

    },
    {
        path: "unit-5/lesson-3/a-to-z",
        component: UnitFiveLessonThreeAToZComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-5/lesson-3/independent-living-assessment",
        component: UnitFiveLessonThreeIndependentLivingAssessmentComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-5/lesson-3/independent-living-goals-worksheet",
        component: UnitFiveLessonThreeIndependentLivingGoalsWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-5/lesson-4",
        component: UnitFiveLessonFourLandingComponent,
    },
    {
        path: "unit-5/lesson-4/guide",
        component: UnitFiveLessonFourGuideComponent,
    },
    {
        path: "unit-5/lesson-5",
        component: UnitFiveLessonFiveLandingComponent,
    },
    {
        path: "unit-5/lesson-5/guide",
        component: UnitFiveLessonFiveGuideComponent,
    },
    {
        path: "unit-5/lesson-5/action-plan",
        component: UnitFiveLessonFiveActionPlanComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "unit-5/lesson-6",
        component: UnitFiveLessonSixLandingComponent,

    },
    {
        path: "unit-5/lesson-6/guide",
        component: UnitFiveLessonSixGuideComponent,
    },
    {
        path: "unit-5/lesson-6/final-reflection-worksheet",
        component: UnitFiveLessonSixFinalReflectionWorksheetComponent,
        canDeactivate: [ConfirmRouteGuard]
    },
    {
        path: "create-ticket",
        component: FeedbackComponent
    },
    {
        path: "about",
        component: AboutComponent
    },
    {
        path: "help",
        component: HelpComponent
    },
    {
        path: "feedback",
        component: FeedbackComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

export const AppRoutingProviders: any[] = [];
export const AppRouting: ModuleWithProviders = RouterModule.forRoot(appRoutes, { useHash: true });
