import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'self-determination-scale',
    templateUrl: './self-determination-scale.component.html',
    styleUrls: ['./self-determination-scale.component.css']
})
export class UnitOneLessonFiveSelfDeterminationScaleComponent {

    title: string = "AIR Self-Determination Scale";
    pageTitle:string = this.title + " | Unit 1, Lesson 5 | T-Folio";

    thingsIDo01: number = 0;
    thingsIDo02: number = 0;
    thingsIDo03: number = 0;
    thingsIDo04: number = 0;
    thingsIDo05: number = 0;
    thingsIDo06: number = 0;

    howIFeel01: number = 0;
    howIFeel02: number = 0;
    howIFeel03: number = 0;
    howIFeel04: number = 0;
    howIFeel05: number = 0;
    howIFeel06: number = 0;

    whatHappensAtSchool01: number = 0;
    whatHappensAtSchool02: number = 0;
    whatHappensAtSchool03: number = 0;
    whatHappensAtSchool04: number = 0;
    whatHappensAtSchool05: number = 0;
    whatHappensAtSchool06: number = 0;

    whatHappensAtHome01: number = 0;
    whatHappensAtHome02: number = 0;
    whatHappensAtHome03: number = 0;
    whatHappensAtHome04: number = 0;
    whatHappensAtHome05: number = 0;
    whatHappensAtHome06: number = 0;

    maxDeterminationScore: number = 120;

    constructor(private titleService: Title) {

        this.titleService.setTitle(this.pageTitle);

    } 

    public get thingsIDoOneAndTwoTotal() : number {
        return (this.thingsIDo01-0) + (this.thingsIDo02-0);
    }

    public get thingsIDoThreeAndFourTotal() : number {
        return (this.thingsIDo03-0) + (this.thingsIDo04-0);
    }

    public get thingsIDoFiveAndSixTotal() : number {
        return (this.thingsIDo05-0) + (this.thingsIDo06-0);
    }

    public get thingsIDoTotal(): number {
        return this.thingsIDoOneAndTwoTotal + this.thingsIDoThreeAndFourTotal + this.thingsIDoFiveAndSixTotal;
    }

    public get howIFeelOneAndTwoTotal(): number {
        return (this.howIFeel01 - 0) + (this.howIFeel02 - 0);
    }

    public get howIFeelThreeAndFourTotal(): number {
        return (this.howIFeel03 - 0) + (this.howIFeel04 - 0);
    }

    public get howIFeelFiveAndSixTotal(): number {
        return (this.howIFeel05 - 0) + (this.howIFeel06 - 0);
    }

    public get howIFeelTotal(): number {
        return this.howIFeelOneAndTwoTotal + this.howIFeelThreeAndFourTotal + this.howIFeelFiveAndSixTotal;
    }

    public get whatHappensAtHomeOneAndTwoTotal(): number {
        return (this.whatHappensAtHome01 - 0) + (this.whatHappensAtHome02 - 0);
    }

    public get whatHappensAtHomeThreeAndFourTotal(): number {
        return (this.whatHappensAtHome03 - 0) + (this.whatHappensAtHome04 - 0);
    }

    public get whatHappensAtHomeFiveAndSixTotal(): number {
        return (this.whatHappensAtHome05 - 0) + (this.whatHappensAtHome06 - 0);
    }

    public get whatHappensAtHomeTotal(): number {
        return this.whatHappensAtHomeOneAndTwoTotal + this.whatHappensAtHomeThreeAndFourTotal + this.whatHappensAtHomeFiveAndSixTotal;
    }

    public get whatHappensAtSchoolOneAndTwoTotal(): number {
        return (this.whatHappensAtSchool01 - 0) + (this.whatHappensAtSchool02 - 0);
    }

    public get whatHappensAtSchoolThreeAndFourTotal(): number {
        return (this.whatHappensAtSchool03 - 0) + (this.whatHappensAtSchool04 - 0);
    }

    public get whatHappensAtSchoolFiveAndSixTotal(): number {
        return (this.whatHappensAtSchool05 - 0) + (this.whatHappensAtSchool06 - 0);
    }

    public get whatHappensAtSchoolTotal(): number {
        return this.whatHappensAtSchoolOneAndTwoTotal + this.whatHappensAtSchoolThreeAndFourTotal + this.whatHappensAtSchoolFiveAndSixTotal;
    }

    public get capacityScore(): number {
        return this.thingsIDoTotal + this.howIFeelTotal;
    }

    public get opportunityScore(): number {
        return this.whatHappensAtSchoolTotal + this.whatHappensAtHomeTotal;
    }

    public get selfDeterminationScore(): number {
        return this.capacityScore + this.opportunityScore;
    }

    public get thermHeightPercentage(): string {

        // height of thermometer within entire containing image
        var thermHeight = 360; 
        var imageHeight = 390;
        var thermPercentHeightOfImage = (thermHeight / imageHeight) * 100;

        return ((this.selfDeterminationScore / this.maxDeterminationScore) * thermPercentHeightOfImage)  + '%';
    }

    doPrint(): void {
        window.print();
    }
}
