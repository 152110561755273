import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'introduction-lesson-one-guide',
    templateUrl: './_guide.component.html',
    styleUrls: ['./_guide.component.css']
})
export class IntroductionLessonTwoGuideComponent {

    pageTitle: string = "Lesson Guide | Introduction, Lesson 2 | T-Folio";
    title: string = "Lesson Guide";
    subtitle: string = "Lesson 2: Taking Assessments";

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
