
import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'introduction-lesson-two-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class IntroductionLessonTwoLandingComponent {
    title = "Lesson 2: Taking Assessments";
    pageTitle: string = "Taking Assessments | Introduction, Lesson 2 | T-Folio";
    bannerHeader = "Student-Centered Introduction: Lesson 2";

    doPrint(): void {
        window.print();
    }

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 2",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/introduction",
                    linktext: "Introduction"
                }
            ]
        },
        summary: "In this lesson, students will reflect on their past experiences taking assessments and will explore the idea of assessments as tools for self-discovery and growth. A self-reflection activity will guide students to express their concerns and expectations regarding taking assessments.",
        objectives: [
            "Understand the purpose for taking assessments.",
            "Become familiar with readiness factors in preparation for taking assessments.",
            "Identify and voice personal readiness methods for taking assessments.",
            "Identify and voice personal supports needed when taking assessments.",
            "Complete Self-Reflection: Taking Assessments worksheet.",
        ],
        buttons: [
            {
                route: "/introduction/lesson-2/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            },
            {
                route: "/introduction/lesson-2/self-reflection",
                linktext: "Self Reflection: Taking Career Assessments",
                color: "btn-primary",
            },
            {
                route: "/img/introduction/lesson-2/T-Folio_Introduction_Lesson-2_Taking-Assessments.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/introduction/lesson-2/pencil-918449_960_720.jpg",
        imgalt: "A pencil with bite marks and a pencil sharpener rest on a notebook that is opened to a blank lined page. There are pencil shavings on the page.",
        caption: "on",
        photoLink: "https://pixabay.com/en/pencil-sharpener-notebook-paper-918449/",
        source: "Pixabay",
        sourceLink: "https://pixabay.com/en/",
        licence: "CC0 license",
        licenceLink: "https://creativecommons.org/publicdomain/zero/1.0/",

    }
}
