import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'final-reflection-worksheet',
    templateUrl: './final-reflection-worksheet.component.html',
    styleUrls: ['./final-reflection-worksheet.component.css']
})
export class UnitFiveLessonSixFinalReflectionWorksheetComponent {

    title: string = "Final Reflection Worksheet";
    pageTitle: string = this.title + " | Unit 5, Lesson 6 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

}
