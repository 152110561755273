import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-three-landing-page',
    templateUrl: '../_shared/landing-pages/unit-landing-page.component.html',
    styleUrls: ['../_shared/landing-pages/unit-landing-page.component.css']
})
export class UnitThreeLandingComponent {

    title: string = "Unit 3: Work-Based Learning Experiences";
    pageTitle: string = "Work-Based Learning Experiences | Unit 3 | T-Folio";
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    UnitLandingPageData = {
        breadcrumbs: {
            label: "Unit 3",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                }
            ]
        },
        portfolioitems: [
            {
                text: "SCANS Skills Assessment",
            },
            {
                text: "Informational Interview Worksheet",
            },
            {
                text: "Job Shadow Workbook",
            },
            {
                text: "High School and Beyond Plan",
            }
        ],
        summary: "Unit 3 helps youth gauge their interest in a particular career through experiential learning opportunities, such as informational interviews and job shadows. They will learn what employers look for when hiring and supervising employees. This unit helps students become more competitive for future employment. It ends with a lesson on aligning T-Folio activities with the High School and Beyond Plan.",
        buttons: [
            {
                route: "/unit-3/lesson-1",
                linktext: "Lesson 1: Introduction to Work-Based Learning",
                color: "btn-success",
                image: "/img/unit-3/lesson-1/LESSON 1_industrial-1636397_1920.jpg",
                alttext: "A worker wearing a red jacket, orange gloves, and clear work glasses stands in a warehouse smiling.",
            },
            {
                route: "/unit-3/lesson-2",
                linktext: "Lesson 2: SCANS Workplace Skills and Competencies",
                color: "btn-success",
                image: "/img/unit-3/lesson-2/LESSON 2_jobs_cashier.jpg",
                alttext: "Two people exchanging a bag of macarons and money are in the left corner with a display of colorful macarons and a flavor board to the right of them. The flavors listed are passion fruit, black sesame, key lime, chocolate, strawberry marshmallow, and vanilla bean.",
            },
            {
                route: "/unit-3/lesson-3",
                linktext: "Lesson 3: Informational Interviews",
                color: "btn-success",
                image: "/img/unit-3/lesson-3/LESSON 3_writing-notes-idea-conference.jpg",
                alttext: "A person’s hands are in focus writing with a yellow pen on a folded white piece of paper on black table.",
            },
            {
                route: "/unit-3/lesson-4",
                linktext: "Lesson 4: Job Shadowing",
                color: "btn-success",
                image: "/img/unit-3/lesson-4/LESSON 4_shop-2649775_1920.jpg",
                alttext: "An older person wearing works goggles and an apron stands to the left of a younger person wearing work goggles, red safety earmuffs, and an apron as they both look at work equipment in a mechanic shop.",
            },
            {
                route: "/unit-3/lesson-5",
                linktext: "Lesson 5: Evaluation and Letters of Recommendation",
                color: "btn-success",
                image: "/img/unit-3/lesson-5/LESSON 5_feedback-1825515_1920.jpg",
                alttext: "The word “feedback” is written and underlined in white chalk on a black chalkboard. ",
            },
            {
                route: "/unit-3/lesson-6",
                linktext: "Lesson 6: High School and Beyond Plan",
                color: "btn-success",
                image: "/img/unit-3/lesson-6/LESSON 6_carl-heyerdahl-181868.jpg",
                alttext: "This image shows an organized desk space with a large computer monitor that says “DO MORE.” in white letters on a black screen.",
            }
        ],
        image: "/img/unit-3/_landing/UNIT 3_landing page_jobs_construction.jpg",
        alttext: "A worker wearing dark jeans and a grey pullover is kneeling on a large piece of plywood and drawing a line with a white pencil on a small beam of wood.",
        caption: "by Fancycrave on ",
        photoText: "Photo",
        photoLink: "https://www.pexels.com/photo/adult-blur-carpenter-carpentry-345135/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "/ CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",
    }

    doPrint(): void {
        window.print();
    }
}
