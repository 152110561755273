import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'unit-one-lesson-eight-researching-occupation',
    templateUrl: './researching-occupations.component.html',
    styleUrls: ['./researching-occupations.component.css']
})
export class UnitOneLessonEightResearchingOccupationComponent {

    title: string = "Career Research Worksheet";
    pageTitle: string = this.title + " | Unit 1, Lesson 8 | T-Folio";


    textQuestions = [
        { id: '01', question: '1.	What do people do on the job in this career?' },
        { id: '02', question: '2.	What personality traits fit well with this career?' },
        { id: '03', question: '3.	What knowledge areas are important for this career and what one(s) are you not familiar with (that you should learn more about)?' },
        { id: '04', question: '4.	Describe the necessary skill sets and how they might be essential for this career.' },
        { id: '05', question: '5.	Describe the desired technology skills.' },
        { id: '06', question: '6.	What are the desired abilities and how might they be essential for this career?  ' },
        { id: '07', question: '7.	Describe the education needed for performing this career.' },
        { id: '08', question: '8.	List two facts regarding the job outlook.' },
    ]

    reflectionQuestions = [

        { id: '01', question: '1.	Do you think this career is a good fit for your interests, skills, and abilities? Why or why not?' },
        { id: '02', question: '2.	What characteristics do you have that match requirements for this career?   Be specific! ' },
        { id: '03', question: '3.	What areas or skills might you identify to work on if you still have an interest in this career?' },
        { id: '04', question: '4.	What classes should you consider taking while in high school that could support your future in this career?' },
        { id: '05', question: '5.	What surprised you the most about this career? Why?' },

    ]

    constructor(private titleService: Title) {

        this.titleService.setTitle(this.pageTitle);

    }

    doPrint(): void {
        window.print();
    }
}
