import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-three-lesson-five-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitThreeLessonFiveLandingComponent {

    bannerHeader = "Work-Based Learning Experiences: Lesson 5";
    title: string = "Lesson 5: Evaluations and Letters of Recommendation";
    pageTitle: string = "Evaluations and Letters of Recommendation | Unit 3, Lesson 5 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    doPrint(): void {
        window.print();
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 5",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-3",
                    linktext: "Unit 3"
                }
            ]
        },
        summary: "Youth will learn about performance evaluations and letters of recommendation, which are important for getting and keeping a job.",
        objectives: [
            "Be introduced to employer evaluations and their importance.", 
            "Review a good evaluation and a poor evaluation.", 
            "Conduct a self-evaluation activity.", 
            "Be introduced to letters of recommendation and how to ask for one.", 
            "Review examples of letters of recommendation.", 

        ],
        buttons: [
            {
                route: "/unit-3/lesson-5/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: '/unit-3/lesson-5/performance-review',
                linktext: 'Employee Performance Review',
                color: "btn-primary",
                window: false
            },
            {
                route: '/img/unit-3/lesson-5/T-Folio_Unit-3_Lesson-5_Employee-Performance-Review-Sample-Michelle.pdf',
                linktext: 'Employee Performance Review Sample - Michelle (PDF)',
                color: "btn-primary",
                window: true
            },
            {
                route: '/img/unit-3/lesson-5/T-Folio_Unit-3_Lesson-5_Employee-Performance-Review-Sample-Sally.pdf',
                linktext: 'Employee Performance Review Sample - Sally (PDF)',
                color: "btn-primary",
                window: true
            },
            {
                route: '/img/unit-3/lesson-5/T-Folio_Unit-3_Lesson-5_Evaluations-And-Letters-Of-Recommendation.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-3/lesson-5/LESSON 5_feedback-1825515_1920.jpg",
        imgalt: 'The word "feedback" is written and underlined in white chalk on a black chalkboard.',
        caption: "on",
        photoLink: "https://pixabay.com/en/feedback-confirming-board-1825515/",
        source: "Pixabay",
        sourceLink: "https://pixabay.com/en/",
        licence: "CC0 license",
        licenceLink: "https://creativecommons.org/publicdomain/zero/1.0/",
    }
}
