import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "job-shadow-workbook-day-of",
    templateUrl: "./day-of.component.html",
    styleUrls: ['./job-shadow-workbook.component.css']
})
export class UnitThreeLessonFourWorkbookDayOfComponent {

    title: string = "Day of Job Shadow";
    pageTitle: string = this.title + " | Job Shadow Workbook | Unit 3, Lesson 4 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }

}
