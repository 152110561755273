import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "elevator-speech-worksheet",
    templateUrl: "./elevator-speech-worksheet.component.html",
    styleUrls: ["./elevator-speech-worksheet.component.css"]
})
export class UnitFourLessonThreeElevatorSpeechWorksheetComponent {

    title: string = "Elevator Speech Worksheet";
    pageTitle: string = this.title + " | Unit 4, Lesson 3 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    userName: string;
    schoolName: string;
    strengths_1: string;
    strengths_2: string;
    interests: string;
    fitbecause: string;

    copyToClipboard(event: any) {

        var copyText = document.getElementById("note").innerText;

        var textArea = document.createElement("textarea");

        textArea.style.position = 'fixed';
        textArea.style.top = '0px';
        textArea.style.left = '0px';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0px';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = copyText;
        document.body.appendChild(textArea);

        textArea.select();
        document.execCommand('copy');

        document.body.removeChild(textArea);

    }

    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }
}
