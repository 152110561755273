﻿import { Component, Input, Output, EventEmitter} from "@angular/core";

@Component({
    selector: 'three-radio-buttons',
    templateUrl: './three-radio-buttons.component.html',
    styleUrls: ['./three-radio-buttons.component.css']
})
export class IndependentLivingAssessmentThreeRadioButtonsComponent {

    @Input() statement: string;
    @Input() identifier: string;

    @Input() always: number;
    @Input() sometimes: number;
    @Input() never: number;

    @Output() alwaysChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() sometimesChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() neverChange: EventEmitter<number> = new EventEmitter<number>();

    setAlways($event) {
        this.alwaysChange.emit(1);
        this.sometimesChange.emit(0);
        this.neverChange.emit(0);
    }

    setSometimes($event) {
        this.alwaysChange.emit(0);
        this.sometimesChange.emit(1);
        this.neverChange.emit(0);
    }

    setNever($event) {
        this.alwaysChange.emit(0);
        this.sometimesChange.emit(0);
        this.neverChange.emit(1);
    }

}