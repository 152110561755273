import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "self-advocacy-goals-worksheet",
    templateUrl: "./self-advocacy-goals-worksheet.component.html",
    styleUrls: ["./self-advocacy-goals-worksheet.component.css"]
})
export class UnitFiveLessonTwoSelfAdvocacyGoalsWorksheetComponent {

    title: string = "Self-Advocacy Goals Worksheet";
    pageTitle: string = this.title + " | Unit 5, Lesson 2 | T-Folio";
    dateString: any = new Date();

    //dateFormatted: string = this.dateString.getMonth() + 1 + "/" + this.dateString.getDate() +
     //   "/" + this.dateString.getFullYear();
    dateFormatted: string = "";
    

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    onChange(value: string) {
        if (value.length == 0) {
            this.dateFormatted = "";
        } else {

            this.dateFormatted = value.substring(5, 7) + "/" + value.substring(8, 10) + "/" + value.substring(0, 4);
        }
    }
}
