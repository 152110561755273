import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "practice-job-application",
    templateUrl: "./practice-job-application.component.html",
    styleUrls: ["./practice-job-application.component.css"]
})
export class UnitFourLessonFourPracticeJobApplicationComponent {

    title: string = "Practice Job Application";
    pageTitle: string = this.title + " | Unit 4, Lesson 4 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }
}
