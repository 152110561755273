import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-two-lesson-one-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitTwoLessonOneLandingComponent {
    title = "Lesson 1: How to Set Goals";
    pageTitle: string = "How to Set Goals | Unit 2, Lesson 1 | T-Folio";
    bannerHeader = "Postsecondary Employment and Education Options: Lesson 1";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 1",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-2",
                    linktext: "Unit 2"
                }
            ]
        },
        summary: "In Lesson 1, youth will learn how to set goals that will help them to reach their dreams. They will learn to write SMART goals for themselves and will complete the SMART Goals Worksheet.",
        objectives: [
            "Define a goal.",
            "Outline the steps needed to achieve the goal.",
            "Consider possible blocks and a plan to deal with them.",
            "Set deadlines.",
        ],
        buttons: [
            {
                route: "/unit-2/lesson-1/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-2/lesson-1/smart-goals-worksheet",
                linktext: "SMART Goals Worksheet",
                color: "btn-primary",
                window: false

            },
            {
                route: '/img/unit-2/lesson-1/T-Folio_Unit-2_Lesson-1_How-To-Set-Goals.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-2/lesson-1/LESSON 1_How-to-Set-Goals_pexels-photo-382167_ps.jpg",
        imgalt: "A medium-sized sailboat is heading out to sea on a partly cloudy day with two passengers on the back deck.",
        caption: "by Jahoo Clouseau on ",
        photoLink: "https://www.pexels.com/photo/adventure-beach-boat-clouds-382167/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",



    }
}
