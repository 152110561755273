import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "home",
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [Title]
})

export class HomeComponent {
    title = "Welcome to T-Folio!";
    header = "What is T-Folio?";

    public constructor(private titleService: Title) {
        this.titleService.setTitle("Home | T-Folio");
    }

    HomePageData = {
        summary: "In Lesson 1, youth will learn how to set goals that will help them to reach their dreams. They will learn to write SMART goals for themselves and will complete the S.M.A.R.T.Goals Worksheet.",
        objectives: [
            "Define a goal.",
            "Outline the steps needed to achieve the goal.",
            "Consider possible blocks and a plan to deal with them.",
            "Set deadlines.",
        ],
        tiles: [
            {
                shortTitle: "Introduction",
                tileText: "Student-Centered Introduction",
                route: "/introduction",
                getTileStyles() {
                    let tileStyles = {
                        'background-size': 'cover',
                        'background-image': 'url(/img/introduction/UNIT_LANDING_PAGE_INTRODUCTION_whats_your_story.jpeg)'
                    };
                    return tileStyles;
                }  
            },
            {
                shortTitle: "Unit 1",
                tileText: "Unit 1: Job Exploration Groundwork",
                route: "/unit-1",
                getTileStyles() {
                    let tileStyles = {
                        'background-size': 'cover',
                        'background-image': 'url(/img/unit-1/UNIT_1_LANDING_PAGE_pexels-photo-296230.jpeg)'
                    };
                    return tileStyles;
                }  
            },
            {
                shortTitle: "Unit 2",
                tileText: "Unit 2: Postsecondary Employment and Education Options",
                route: "/unit-2",
                getTileStyles() {
                    let tileStyles = {
                        'background-size': 'cover',
                        'background-image': 'url(/img/unit-2/_landing/UNIT_2_LANDING_PAGE_school4.jpeg)'
                    };
                    return tileStyles;
                }  
            },
            {
                shortTitle: "Unit 3",
                tileText: "Unit 3: Work-Based Learning Experiences",
                route: "/unit-3",
                getTileStyles() {
                    let tileStyles = {
                        'background-size': 'cover',
                        'background-image': 'url(/img/unit-3/_landing/UNIT 3_landing page_jobs_construction.jpeg)'
                    };
                    return tileStyles;
                }  
            },
            {
                shortTitle: "Unit 4",
                tileText: "Unit 4: Workplace Readiness Training",
                route: "/unit-4",
                getTileStyles() {
                    let tileStyles = {
                        'background-size': 'cover',
                        'background-image': ''
                    };
                    return tileStyles;
                }
            }

        ],
        

    }
}
