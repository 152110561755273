import { Component, ViewChild, ElementRef } from "@angular/core";
declare let $;
// jsPDF for pdf 
//import * as jsPDF from 'jsPDF';


@Component({
    selector: 'tfolio-print-button',
    template: '<button class="hidden-print" (click)="doPrint();">Print</button>',
    styles: ['']
})


export class TFolioPrintButtonComponent {
    doPrint(): void {
        var original = document.title;
        var temp = "";
        //$(document).ready(function () {
        //    $("h1").text("test");
        //});
        var breadcrumb = $("ol.breadcrumb.hidden-print li");
        var size = breadcrumb.length;
        //5 being job shadow workbook length
        if (size == 5) {
            breadcrumb.each(function (i) {
                switch (i) {
                    case 0:
                        var tfolio = $(this).text() + "_";
                        temp += tfolio
                        break;
                    case 1:
                        var unit = $(this).text() + "_";
                        unit = unit.replace(" ", "-");
                        temp += unit;
                        break;
                    case 2:
                        var lesson = $(this).text() + "_";
                        lesson = lesson.replace(" ", "-");
                        temp += lesson;
                        break;
                    case 3:
                        var name = $(this).text();
                        name = name.replace(/ /g, "-");
                        temp += name;
                        break;
                    case 4:
                        var shadow = "_" + $(this).text();
                        shadow = shadow.replace(/ /g, "-");
                        temp += shadow;
                        break;
                    default:
                        break;
                }

            }
            );
        } else {
            breadcrumb.each(function (i) {
                switch (i) {
                    case 0:
                        var tfolio = $(this).text() + "_";
                        temp += tfolio
                        break;
                    case 1:
                        var unit = $(this).text() + "_";
                        unit = unit.replace(" ", "-");
                        temp += unit;
                        break;
                    case 2:
                        var lesson = $(this).text() + "_";
                        lesson = lesson.replace(" ", "-");
                        temp += lesson;
                        break;
                    case 3:
                        var name = $(this).text();
                        name = name.replace(/ /g, "-");
                        temp += name;
                        break;
                    default:
                        break;
                }

            }
            );
        }
        document.title = temp;
        //var reg = RegExp("(? <word1>folio) \s ? (? <word2>unit) \s(?<unit>\d) \s(?<lesson>Lesson) \s(?<num>\d)");
        window.print();
        document.title = original;
    }


    ////////////////////////////////////


    //// get reference to page content for PDF
    //@ViewChild('content') content: ElementRef;


    //// download to PDF
    //public DownLoadToPDF() {

    //    // new jsPDF object
    //    let PDFDoc = new jsPDF();

    //    let SpecialElementHandlers = {

    //        '#editor': function (element, renderer) {
    //            return true;
    //        }
    //    };


    //    // get native element
    //    let currcontent = this.content.nativeElement;


    //    // ADD COMMENTS
    //    PDFDoc.fromHTML(currcontent.innerHTML, 15, 15,
    //    {
    //        'width': 190,
    //        'elementHandlers': SpecialElementHandlers
    //     });

    //    // save pdf
    //    PDFDoc.save('TFolioPage.pdf');
    //}

}
