import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-three-lesson-one-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitThreeLessonThreeLandingComponent {

    bannerHeader = "Work-Based Learning Experiences: Lesson 3";
    title: string = "Lesson 3: Informational Interviews";
    pageTitle: string = "Informational Interviews | Unit 3, Lesson 3 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    doPrint(): void {
        window.print();
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 3",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-3",
                    linktext: "Unit 3"
                }
            ]
        },
        summary: "In this lesson, youth will learn about informational interviews to expand their understanding of a career or company in which they may be interested.",
        objectives: [
            "Review the components of an informational interview.",
            "Create a set of questions to ask.",
            "Identify a person or company to interview in an area of their choice.",
            "Prepare for and conduct an informational interview.",
            "Share with class/facilitator what was learned."
        ],
        buttons: [
            {
                route: "/unit-3/lesson-3/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-3/lesson-3/informational-interview-worksheet",
                linktext: "Informational Interview Worksheet",
                color: "btn-primary",
                window: false,
            },
            {
                route: '/img/unit-3/lesson-3/T-Folio_Unit-3_Lesson-3_Informational-Interviews.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-3/lesson-3/LESSON 3_writing-notes-idea-conference.jpg",
        imgalt: "A person’s hands are in focus writing with a yellow pen on a folded white piece of paper on black table.",
        caption: "by Startup Stock Photos on",
        photoLink: "https://www.pexels.com/photo/writing-notes-idea-class-7103/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",
    }
}
