import { Component, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

@Injectable()
export class ConfirmRouteGuard implements CanDeactivate<Component> {

    confirmMessage: string = '';


    /*

        Caution: T-Folio will not store your work. Please use the print button to save all work as a PDF:
        Click the "Print" button.
        From the print screen, choose "Save as PDF" by changing the printer destination.
        Save to a designated folder for your portfolio.
        If you navigate away from the page before saving or printing, all entries will be lost.

        Click OK to leave this page, or Cancel to return and save your work.
        u2022
    */
    public constructor() {
        this.confirmMessage += 'Caution: T-Folio will not store your work. Please use the print button to save all work as a PDF:\n';
        this.confirmMessage += '\u2006  \u2022 Click the "Print" button.\n';
        this.confirmMessage += '\u2006  \u2022 From the print screen, choose "Save as PDF" by changing the\n  \u2005  printer destination.\n';
        this.confirmMessage += '\u2006  \u2022 Save to a designated folder for your portfolio.\n';
        this.confirmMessage += 'If you navigate away from the page before saving or printing, all entries will be lost.\n';
        this.confirmMessage += 'Click OK to leave this page, or Cancel to return.';
    }

    canDeactivate(component: Component) {
        return confirm(this.confirmMessage);
    }
}
