import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'unit-four-lesson-seven-activity-action-plan-template',
    templateUrl: './activity-action-plan-template.component.html',
    styleUrls: [`./activity-action-plan-template.component.css`]
})
export class UnitFourLessonSevenActivityActionPlanTemplateComponent {
    title: string = "Action Plan";
    pageTitle: string = this.title + " | Unit 4, Lesson 7 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

}
