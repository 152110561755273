import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "job-shadow-workbook-landing",
    templateUrl: "./workbook-landing.component.html",
    styleUrls: ["./workbook-landing.component.css"]
})
export class UnitThreeLessonFourWorkbookLandingComponent {

    title: string = "Job Shadow Workbook";
    pageTitle: string = "Job Shadow Workbook | Unit 3, Lesson 4 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }

}
