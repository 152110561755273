import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-four-lesson-two-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFourLessonTwoLandingComponent {

    bannerHeader = "Workplace Readiness Training: Lesson 2";
    title: string = "Lesson 2: Writing a R\xE9sum\xE9";
    pageTitle: string = "Writing a R\xE9sum\xE9 | Unit 4, Lesson 2 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 2",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-4",
                    linktext: "Unit 4"
                }
            ]
        },
        summary: "In Lesson 2, students will identify the parts of a r\xE9sum\xE9 and create their own, including the key words needed to help their r\xE9sum\xE9 stand out to potential employers.",
        objectives: [
            "Identify the parts of a r\xE9sum\xE9.",
            "Create a r\xE9sum\xE9 that reflects their employment history, educational experiences, volunteer experiences, activities, and awards/honors.",
            "Identify key words from a job posting to include in r\xE9sum\xE9.",
        ],
        buttons: [
            {
                route: "/unit-4/lesson-2/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },

            {
                route: "/unit-4/lesson-2/resume-keywords-worksheet",
                linktext: "R\xE9sum\xE9 Keywords Worksheet",
                color: "btn-primary",
                window: false,
            },

            {
                route: "/unit-4/lesson-2/resume-template",
                linktext: "R\xE9sum\xE9 Template",
                color: "btn-primary",
                window: false,
            },

            {
                route: "/img/unit-4/lesson-2/T-Folio_Unit-4_Lesson-2_Sample-Resume.pdf",
                linktext: "Sample R\xE9sum\xE9 (PDF)",
                color: "btn-primary",
                window: true,
            },

            {
                route: '/img/unit-4/lesson-2/T-Folio_Unit-4_Lesson-2_Writing-Resume.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-4/lesson-2/L2_resume.jpg",
        imgalt: "A r\xE9sum\xE9 and a data report rest on a wooden desk next to a laptop.",
        caption: "on",
        photoLink: "https://cdn.pixabay.com/photo/2017/09/25/17/09/chart-2785863__340.jpg",
        source: "Pixabay",
        sourceLink: "https://pixabay.com/en/",
        licence: "CC0 license",
        licenceLink: "https://creativecommons.org/publicdomain/zero/1.0/",
    }


}
