import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-five-lesson-four-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFiveLessonFourLandingComponent {

    bannerHeader = "Self-Advocacy: Lesson 4";
    title: string = "Lesson 4 :Student-Led IEPs";
    pageTitle: string = "Student-Led IEPs | Unit 5, Lesson 4 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 4",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-5",
                    linktext: "Unit 5"
                }
            ]
        },
        summary: "In this lesson, students will be introduced to the student-led Individualized Education Program (IEP). They will determine their current level of participation on a continuum and decide on next steps to improve their ability to meaningfully participate in their IEP process. ",
        objectives: [
            "Understand the purpose of a student-led Individualized Education Program (IEP).",
            "Acknowledge their current level of involvement in the planning and meeting process. ",
            "Determine next steps to be a more active participant in the IEP. ",
        ],
        buttons: [
            {
                route: "/unit-5/lesson-4/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },

            {
                route: '/img/unit-5/lesson-4/T-Folio_Unit-5_Lesson-4_Student-Led-IEPs.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-5/lesson-4/4_nathan-anderson-157614.jpg",
        imgalt: "A young person hugs their mom with their dad looking on and all three smiling.",
        caption: "by Nathan Anderson on",
        photoLink: "https://unsplash.com/photos/GM5Yn5XRVqA",
        source: "Unsplash",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",

    }


}
