﻿import { Component } from "@angular/core";

@Component({
    selector: 'unit-one-lesson-seven-list-names',
    templateUrl: './_list-names.component.html',
    styleUrls: ['./_list-names.component.css']
})
export class UnitOneLessonSevenListNamesComponent {

   

    doPrint(): void {
        window.print();
    }
}