import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-three-lesson-one-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitThreeLessonOneLandingComponent {

    bannerHeader = "Work-Based Learning Experiences: Lesson 1";
    title: string = "Lesson 1: Introduction to Work-Based Learning";
    pageTitle: string = "Introduction to Work-Based Learning | Unit 3, Lesson 1 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    doPrint(): void {
        window.print();
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 1",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-3",
                    linktext: "Unit 3"
                }
            ]
        },
        summary: "In this lesson, youth will be introduced to the different activities that constitute Work-Based Learning (WBL).",
        objectives: [
            "Learn about different kinds of work-based learning opportunities.",
            "Talk about the importance of work-based experiences and how they impact a student's future."
        ],
        buttons: [
            {
                route: "/unit-3/lesson-1/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: '/img/unit-3/lesson-1/T-Folio_Unit-3_Lesson-1_Work-Based-Learning.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-3/lesson-1/LESSON 1_industrial-1636397_1920.jpg",
        imgalt: "A worker wearing a red jacket, orange gloves, and clear work glasses stands in a warehouse smiling.",
        caption: "on",
        photoLink: "https://pixabay.com/en/industrial-security-logistic-1636397/",
        source: "Pixabay",
        sourceLink: "https://pixabay.com/en/",
        licence: "CC0 license",
        licenceLink: "https://creativecommons.org/publicdomain/zero/1.0/",
    }


}
