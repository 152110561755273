import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TicketingService } from "./_services/ticketing.service";

@Component({
    selector: "create-ticket",
    templateUrl: './create-ticket.component.html',
    styleUrls: ['./_shared/landing-pages/landing-page.component.css'],
    providers: [TicketingService]
})

export class CreateTicketComponent {

    title: string = "Feedback";

    public userName: string ="";
    public phone: string = "";
    public email: string = "";
    public description: string = "";
    public location: string = "";
    public browser: string = "";
    public device: string = "";

    public devices: Device[] = [
        { "name": "Laptop — PC", "value": "Laptop-PC" },
        { "name": "Laptop — Apple", "value": "Laptop-Apple" },
        { "name": "Desktop — PC", "value": "Desktop-PC" },
        { "name": "Desktop — Apple", "value": "Desktop-Apple" },
        { "name": "iPad", "value": "iPad" },
        { "name": "Chromebook", "value": "Chromebook" },
        { "name": "Other", "value": "Other" },
    ];
    public browsers: Browser[] = [
        { "name": "Chrome", "value": "Chrome" },
        { "name": "Safari", "value": "Safari" },
        { "name": "Edge", "value": "Edge" },
        { "name": "Firefox", "value": "Firefox" },
        { "name": "Other", "value": "Other" },
    ];

    constructor(private ticketingService: TicketingService, private router: Router) { }

    createTicket() {

        var meta: string = "";
        meta += "Name: " + this.userName + "<br/>";
        meta += "Phone: " + this.phone + "<br/>";
        meta += "Email: " + this.email + "<br/>";
        meta += "Location: " + this.location + "<br/>";
        meta += "Device: " + this.device + "<br/>";
        meta += "Browser: " + this.browser + "<br/>";
        meta += "<br/>";

        var data = {
            description: meta + this.description,
            subject: "T-Folio",
            email: "albertsj@seattleu.edu",
            priority: 1,
            status: 2
        }
        this.ticketingService.createTicket(data);
        window.alert("Your ticket has been submitted. Thank you for your feedback!");
        //window.history.back();
        window.location.reload(true);

    }

    cancel() {
      //window.history.back();
      window.location.reload(true);
    }
}

class Device {
    name: string;
    value: string;
}

class Browser {
    name: string;
    value: string;
}
