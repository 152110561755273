﻿import { Component, Input } from "@angular/core";
declare let kendo;
declare let $;


@Component({
    selector: 'tfolio-whats-important-to-me-pdf-button',
    template: '',
    //template: '&nbsp;&nbsp;<button class="hidden-print" (click)="ExportToPdf(fileName);">PDF</button>',
    styles: ['']
})


// https://www.w3schools.com/jquery/tryit.asp?filename=tryjquery_traversing_find


export class TFolioWhatsImportantToMePDFComponent {

    // name of file to be downloaded to pdf
    @Input() fileName: string;


    ExportToPdf(fileName): void {

        /////////////////////////////////////////////////////////////////////
        //// make page style adjustments for pdf formatting /////////////////
        /////////////////////////////////////////////////////////////////////


        // page level
        $('#pdfcontent').css('font-size', '17pt');

        // circle text
        $('.arrows-container').find('.circle_text').css('font-size', '17pt');


        /////////////////////////////////////////////////////////////////////


        // northeast_quest 
        $('.arrows-container').find('.northeast_quest').css('font-size', '17pt');

        // northeast_entry 
        $('.arrows-container').find('.northeast_entry').css({
            'height': '97px',
            'width': '220px',
            'position':'absolute',
            'left':'637px',
            'top': '338px',
            'font-size': '16pt'
        })


        /////////////////////////////////////////////////////////////////////


        // east_quest
        $('.arrows-container').find('.east_quest').css('font-size', '17pt');


        // east_entry
        $('.arrows-container').find('.east_entry').css({
            'height': '97px',
            'width': '220px',
            'position': 'absolute',
            'left': '750px',
            'top': '470px',
            'font-size': '16pt'
        })


        /////////////////////////////////////////////////////////////////////


        // southeast_quest
        $('.arrows-container').find('.southeast_quest').css('font-size', '17pt');


        // southeast_entry
        $('.arrows-container').find('.southeast_entry').css({
            'height': '95px',
            'width': '220px',
            'position': 'absolute',
            'left': '700px',
            'top': '624px',
            'font-size': '16pt'
        })


        /////////////////////////////////////////////////////////////////////


        // south_quest
        $('.arrows-container').find('.south_quest').css('font-size', '17pt');

        // south_entry
        $('.arrows-container').find('.south_entry').css({
            'height': '230px',
            'width': '90px',
            'position': 'absolute',
            'left': '472px',
            'top': '720px',
            'font-size': '16pt'
        })


        /////////////////////////////////////////////////////////////////////

     
        // southwest_quest
        $('.arrows-container').find('.southwest_quest').css({
            'font-size': '17pt',
            'width': '250px'
        });


        // southwest_entry
        $('.arrows-container').find('.southwest_entry').css({
            'height': '90px',
            'width': '230px',
            'position': 'absolute',
            'left': '160px',
            'top': '805px',
            'font-size': '16pt'
        })


        /////////////////////////////////////////////////////////////////////


        // west_quest
        $('.arrows-container').find('.west_quest').css('font-size', '17pt');

        // west_entry
        $('.arrows-container').find('.west_entry').css({
            'height': '90px',
            'width': '230px',
            'position': 'absolute',
            'left': '75px',
            'top': '477px',
            'font-size': '16pt'
        })


        /////////////////////////////////////////////////////////////////////


        // northwest_quest
        $('.arrows-container').find('.northwest_quest').css('font-size', '17pt');

        // northwest_entry
        $('.arrows-container').find('.northwest_entry').css({
            'height': '90px',
            'width': '230px',
            'position': 'absolute',
            'left': '238px',
            'top': '180px',
            'font-size': '16pt'
        })


        /////////////////////////////////////////////////////////////////////


        // north_quest
        $('.arrows-container').find('.north_quest').css('font-size', '17pt');

        // north_entry
        $('.arrows-container').find('.north_entry').css({
            'height': '230px',
            'width': '90px',
            'position': 'absolute',
            'left': '470px',
            'top': '96px',
            'font-size': '16pt'
        })


        /////////////////////////////////////////////////////////////////////


        // telerik Kendo functionality (see library references in index.html)
        kendo.drawing.drawDOM("#pdfcontent",
            {
                // paper size, margin, and scale
                paperSize: "letter",
                margin: { top: "1cm", bottom: "1cm" },            
                scale: 0.50,
            })
            .then(function (group) {
                kendo.drawing.pdf.saveAs(group, "'" + fileName + "'");


                /////////////////////////////////////////////////////////////////////
                //// restore default styles after pdf loads /////////////////////////
                /////////////////////////////////////////////////////////////////////


                // page level
                $('#pdfcontent').css('font-size', '15px'); 

                // circle text
                $('.arrows-container').find('.circle_text').css('font-size', '20px');


                /////////////////////////////////////////////////////////////////////


                // northeast_quest 
                $('.arrows-container').find('.northeast_quest').css('font-size', '20px');

                // northeast_entry 
                $('.arrows-container').find('.northeast_entry').css({
                    'height': '90px',
                    'width': '305px',
                    'position': 'absolute',
                    'left': '615px',
                    'top': '361px',
                    'font-size': '15px'
                })

               
                /////////////////////////////////////////////////////////////////////


                // east_quest
                $('.arrows-container').find('.east_quest').css('font-size', '20px');

                // east_entry
                $('.arrows-container').find('.east_entry').css({
                    'height': '90px',
                    'width': '305px',
                    'position': 'absolute',
                    'left': '716px',
                    'top': '472px',
                    'font-size': '14px'
                })


                /////////////////////////////////////////////////////////////////////


                // southeast_quest
                $('.arrows-container').find('.southeast_quest').css('font-size', '20px');

                // southeast_entry
                $('.arrows-container').find('.southeast_entry').css({
                    'height': '90px',
                    'width': '305px',
                    'position': 'absolute',
                    'left': '678px',
                    'top': '603px',
                    'font-size':'15px'
                })


                /////////////////////////////////////////////////////////////////////
                

                // south_quest
                $('.arrows-container').find('.south_quest').css('font-size', '20px');

                // south_entry
                $('.arrows-container').find('.south_entry').css({
                    'height': '305px',
                    'width': '90px',
                    'position': 'absolute',
                    'left': '472px',
                    'top': '690px',
                    'font-size': '11.5px'
                })


                /////////////////////////////////////////////////////////////////////


                // southwest_quest
                $('.arrows-container').find('.southwest_quest').css({
                    'font-size': '20px',
                    'width': '200px'
                });


                // southwest_entry
                $('.arrows-container').find('.southwest_entry').css({
                    'height': '90px',
                    'width': '305px',
                    'position': 'absolute',
                    'left': '125px',
                    'top': '840px',
                    'font-size': '15px'
                })


                /////////////////////////////////////////////////////////////////////


                // west
                $('.arrows-container').find('.west_quest').css('font-size', '20px');

                // west_entry
                $('.arrows-container').find('.west_entry').css({
                    'height': '90px',
                    'width': '305px',
                    'position': 'absolute',
                    'left': '27px',
                    'top': '477px',
                    'font-size': '15px'
                })


                /////////////////////////////////////////////////////////////////////


                // northwest_quest
                $('.arrows-container').find('.northwest_quest').css('font-size', '20px');

                // northwest_entry
                $('.arrows-container').find('.northwest_entry').css({
                    'height': '90px',
                    'width': '305px',
                    'position': 'absolute',
                    'left': '202px',
                    'top': '148px',
                    'font-size': '15px'
                })


                /////////////////////////////////////////////////////////////////////

           
                // north
                $('.arrows-container').find('.north_quest').css('font-size', '20px');

                // north_entry
                $('.arrows-container').find('.north_entry').css({
                    'height': '305px',
                    'width': '90px',
                    'position': 'absolute',
                    'left': '466px',
                    'top': '48px',
                    'font-size': '15px'
                })
            });
    }
}