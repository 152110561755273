import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'classroom-challenges',
    templateUrl: './classroom-challenges.component.html',
    styleUrls: ['./classroom-challenges.component.css']
})
export class UnitOneLessonFourChallengesInTheClassroomComponent {

    title: string = "Challenges in the Classroom and Beyond";
    pageTitle: string = this.title + " | Unit 1, Lesson 4 | T-Folio";

    constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
