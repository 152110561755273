import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'student-centered-introduction',
    templateUrl: '../_shared/landing-pages/unit-landing-page.component.html',
    styleUrls: ['../_shared/landing-pages/unit-landing-page.component.css']
})

export class StudentCenteredIntroductionlandingpage {
    title = "Student-Centered Introduction";
    pageTitle = this.title + " | Introduction | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    UnitLandingPageData = {
        breadcrumbs: {
            label: "Introduction",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                }
            ]
        },
        portfolioitems: [
            {
                text: "What’s Important to Me",
            },
            {
                text: "T-Folio Tracker",
            }

        ],
        summary: "This introductory unit is meant to give both students and facilitators a chance to warm up and prepare for the work of the T-Folio. It encourages students to use their own voice, promoting student-centered planning in the development of the transition portfolio. It provides facilitators the opportunity to practice their facilitation roles; to support and guide through questioning, dialog, and encouragement.",
        buttons: [
            {
                route: "/introduction/lesson-1",
                linktext: "Lesson 1: Spotlight on Student Warm- up",
                color: "btn-success",
                image: "/img/introduction/LESSON 1_dayne-topkin-76099.jpg",
                alttext: 'A young person pictured from the shoulders up holds a card in front of their right eye that reads "Dare to Dream."',
            },
            {
                route: "/introduction/lesson-2",
                linktext: "Lesson 2: Taking Assessments",
                color: "btn-success",
                image: "/img/introduction/lesson-2/pencil-918449_960_720.jpg",
                alttext: "A pencil with bite marks and a pencil sharpener rest on a notebook that is opened to a blank lined page. There are pencil shavings on the page.",
            }
        ],
        image: "/img/introduction/UNIT_LANDING_PAGE_INTRODUCTION_whats_your_story.jpg",
        alttext: "The words 'What's Your Story' are written on a chalkboard.",

        photoText: "Photo",
        photoLink: "https://pixabay.com/en/chalkboard-story-blogging-believe-620316/",
        caption: "on",
        source: "Pixabay ",
        sourceLink: "https://pixabay.com/en/",
        licence: "/ CC0 license",
        licenceLink: "https://creativecommons.org/publicdomain/zero/1.0/",
    
    }
}
