import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-two-lesson-four-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitTwoLessonFourLandingComponent {
    title = "Lesson 4: Exploring Postsecondary Education Options";
    pageTitle = "Exploring Postsecondary Education Options | Unit 2, Lesson 4 | T-Folio";
    bannerHeader = "Postsecondary Employment and Education Options: Lesson 4";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 4",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-2",
                    linktext: "Unit 2"
                }
            ]
        },
        summary: "In Lesson 4, youth will research postsecondary institutions (2-year or 4-year) that provide educational pathways to their career goal(s).",
        objectives: [
            "Learn to access and use the College Navigator.",
            "Explore colleges in a location of their choice.",
            "Create a list of possible colleges to attend.",
            "Consider and compare price, location, program offerings, financial aid options, and other areas of interest.",
            "Begin a list of next steps.",
        ],
        buttons: [
            {
                route: "/unit-2/lesson-4/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: '/img/unit-2/lesson-4/T-Folio_Unit-2_Lesson-4_Exploring-Postsecondary-Education-Options.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-2/lesson-4/LESSON 4_Exploring-Postsecondary-Education-Options_backtoschool.jpg",
        imgalt: "Scrabble tiles spell out “Back to School” on top of a yellow notepad next to two yellow #2 pencils.",
        caption: "by Wokandapix on",
        photoLink: "https://pixabay.com/en/back-to-school-school-education-1622789/",
        source: "Pixabay",
        sourceLink: "https://pixabay.com/en/",
        licence: "CC0 license",
        licenceLink: "https://creativecommons.org/publicdomain/zero/1.0/",
    }
}
