import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-five-lesson-one-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFiveLessonOneLandingComponent {

    bannerHeader = "Self-Advocacy: Lesson 1";
    title: string = "Lesson 1 : Self-Determination Reassessment";
    pageTitle: string = "Self-Determination Reassessment | Unit 5, Lesson 1 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 1",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-5",
                    linktext: "Unit 5"
                }
            ]
        },
        summary: "In this lesson, students will complete the AIR Self-Determination Scale for the second time. The data provided will then be compared to the student's results from their first assessment. Students will be guided to analyze their individual growth in the areas of self-advocacy and self-determination.",
        objectives: [
            "Complete the AIR Self-Determination assessment.",
            "Analyze their results compared to the first assessment.",
            "Identify areas of improvement this year.",
        ],
        buttons: [
            {
                route: "/unit-5/lesson-1/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },

            {
                route: '/img/unit-5/lesson-1/T-Folio_Unit-5_Lesson-1_Self-Determination-Reassessment.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }


        ],
        image: "/img/unit-5/lesson-1/1_achievement-adult-agreement-1153213.jpg",
        imgalt: "A group of young people at a meeting table join hands in a high five. ",
        caption: "by rawpixel.com on",
        photoLink: "https://www.pexels.com/photo/achievement-adult-agreement-arms-1153213",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",

    }


}
