import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'unit-one-lesson-six-work-maturity-skills',
    templateUrl: './work-maturity-skills.component.html',
    styleUrls: ['./work-maturity-skills.component.css']
})
export class UnitOneLessonSixWorkMaturitySkillsComponent {

    title: string = "Skills Assessment Worksheet";
    pageTitle: string = this.title + " | Unit 1, Lesson 6 | T-Folio";

    choiceOne = "1. Feel Competent";
    choiceTwo = "2. Enjoy/ Favorites";
    choiceThree = "3. Would Like to Develop";

    headerOne = "Communication Skills";
    headerTwo = "Technical Skills";
    headerThree = "Management and Self-Management Skills";
    headerFour = "Number Skills";
    headerFive = "Creative/Artistic Skills";
    headerSix = "People and Social Skills";
    headerSeven = "Critical Thinking and Investigative Skills";
    headerEight = "Business Skills";    

    questions1 = [
        { id: '01', question: 'Write, edit, translate, interpret or critique words' },
        { id: '02', question: 'Speak in public, debate, advocate, present or demonstrate an idea' },
        { id: '03', question: 'Facilitate a meeting' },
        { id: '04', question: 'Reading and following directions' },
        { id: '05', question: 'Comparing or cross-checking two lists' },
        { id: '06', question: 'Filling out forms' },
        { id: '07', question: 'Writing reports, letters and memos correctly' },
        { id: '08', question: 'Reading and understanding policies and memos' },
        { id: '09', question: 'Comfortably speaking to others you do not know' },
        { id: '10', question: 'Taking notes while someone speaks' },
        { id: '11', question: 'Finding information' },
        { id: '12', question: 'Using a map' },
        { id: '13', question: 'Explaining things to other people' },
        { id: '14', question: 'Know when to ask for help or more explanation' },
        { id: '15', question: 'Counsel or advise others' },
        { id: '16', question: 'Listening to others' },
    ]; 

    questions2 = [
        { id: '01', question: 'Be athletic or use physical coordination' },
        { id: '02', question: 'Build or construct things or structures' },
        { id: '03', question: 'Do skilled crafts or use hand coordination with tools' },
        { id: '04', question: 'Operate vehicles, machines or electronic equipment' },
        { id: '05', question: 'Repair or set up machines or equipment ' },
        { id: '06', question: 'Installing things' },
        { id: '07', question: 'Work with earth and nature' },
        { id: '08', question: 'Gardening, landscaping and farming' },

    ]; 

    questions3 = [
        { id: '01', question: 'Administer, set goals and priorities, plan or make decisions' },
        { id: '02', question: 'Initiate, assess needs, anticipate or create change' },
        { id: '03', question: 'Manage people, delegate tasks, direct, oversee or motivate' },
        { id: '04', question: 'Sell, negotiate, convince, promote or persuade ' },
        { id: '05', question: 'Being patient with others' },
        { id: '06', question: 'Keeping a cheerful attitude' },
        { id: '07', question: 'Getting interested/excited about the task at hand' },
        { id: '08', question: 'Offering to help when it’s needed' },
        { id: '09', question: 'Knowing how to take directions' },
        { id: '10', question: 'Motivating myself to do what needs to get done' },
        { id: '11', question: 'Helping motivate others to get the job done' },
        { id: '12', question: 'Using a map' },
        { id: '13', question: 'Prioritizing tasks so that the larger goal is met on time' },
        { id: '14', question: 'Following the rules' },
        { id: '15', question: 'Presenting a neat and professional image' },
        { id: '16', question: 'Checking your own work' },
        { id: '17', question: 'Using courtesy when dealing with others' },
        { id: '18', question: 'Seeking help when needed' },
        { id: '19', question: 'Being eager to learn' },
        { id: '20', question: 'Speaking up for yourself' },
        { id: '21', question: 'Solving problems in a cooperative way' },

    ]; 

    questions4 = [
        { id: '01', question: 'Compute, calculate, compare or record numbers' },
        { id: '02', question: 'Forecast, appraise or estimate numerical information' },
        { id: '03', question: 'Doing arithmetic correctly' },
        { id: '04', question: 'Using percentages and decimals' },
        { id: '05', question: 'Estimating costs and/or time needed to complete a job' },
        { id: '06', question: 'Using a database program on a computer' },
        { id: '07', question: 'Using a spreadsheet on a computer' },
        { id: '08', question: 'Creating and managing a budget' },

    ]; 


     questions5 = [
        { id: '01', question: 'Perceive intuitively, sense, show insight or have foresight' },
        { id: '02', question: 'Use artistic ability, photograph, decorate, paint or sculpt' },
        { id: '03', question: 'Use creativity, visualize, imagine, brainstorm or design' },
        { id: '04', question: 'Use musical ability, sing, compose or play instruments' },
        { id: '05', question: 'Presenting artistic ideas' },
        { id: '06', question: 'Visualizing shapes' },
        { id: '07', question: 'Designing' },
        { id: '08', question: 'Drawing, illustrating, sketching' },

    ];

     questions6 = [
         { id: '01', question: 'Care, treat, heal, nurse or rehabilitate others ' },
         { id: '02', question: 'Counsel, empower, coach, guide or listen to individuals ' },
         { id: '03', question: 'Host, comfort, please, make welcome or serve customers ' },
         { id: '04', question: 'Plan social, recreational or other group events ' },
         { id: '05', question: 'Problem-solve, mediate or network with people ' },
         { id: '06', question: 'Teach, train, instruct, inform or explain to groups' },
         { id: '07', question: 'Caring for children responsibly' },
         { id: '08', question: 'Caring for the sick and elderly' },
         { id: '09', question: 'Calming people down' },
         { id: '10', question: 'Helping people complete a task' },
         { id: '11', question: 'Knowing how to get along with different people/personalities' },
         { id: '12', question: 'Leading groups or activities' },

     ];

     questions7 = [
         { id: '01', question: 'Analyze, use logic, problem solve, examine' },
         { id: '02', question: 'Conceptualize, adapt, develop, hypothesize or discover' },
         { id: '03', question: 'Evaluate, assess, test, appraise, diagnose' },
         { id: '04', question: 'Observe, reflect, study or notice' },
         { id: '05', question: 'Research, investigate, read or interview' },
         { id: '06', question: 'Synthesize, integrate, unify or conceptualize ideas' },

     ];

     questions8 = [
         { id: '01', question: 'Working with computers' },
         { id: '02', question: 'Using a business telephone' },
         { id: '03', question: 'Working with budgets' },
         { id: '04', question: 'Account, budget, program or systematize financial data' },
         { id: '05', question: 'Attend to detail, copy, inspect or transcribe' },
         { id: '06', question: 'Setting up and closing out a cash register' },
         { id: '07', question: 'Managing money and bills' },
         { id: '08', question: 'Organizing, filing, updating, categorizing or arranging information' },
         { id: '09', question: 'Writing business documents' },
         { id: '10', question: 'Coordinating events' },

     ];

    others = [
        { id: '01' },
        { id: '02' },
        { id: '03' },
    ];

    textQuestions = [
        { id: '01', question: '1. From the list above, referring to column 2, list your top five or ten favorite skills that you would most enjoy utilizing in your work (even if you are not proficient at them yet). Include the main skill category. '},
        { id: '02', question: '2. Which of the favorite skills listed above do you consider strengths or things that you are very good at (both column 1 and 2 would probably be marked)? Include the main skill category.' },
        { id: '03', question: '3. Which (top five) skills would you like to develop, improve and/or learn (refer to skills marked in column 3)? Include the main skill category.' },

    ]

    constructor(private titleService: Title) {

        this.titleService.setTitle(this.pageTitle);

    }
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint(): void {
        window.print();
    }
}
