import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-two-lesson-three-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitTwoLessonThreeLandingComponent {
    title = "Lesson 3: Exploring Employment Options";
    pageTitle: string = "Exploring Employment Options | Unit 2, Lesson 3 | T-Folio";
    bannerHeader = "Postsecondary Employment and Education Options: Lesson 3";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 3",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-2",
                    linktext: "Unit 2"
                }
            ]
        },
        summary: "In this lesson, youth will identify an area of interest for postsecondary employment based on their results from Unit 2: Lesson 2. They will then research the educational requirements, work duties, knowledge, and skills required for each career of interest. A worksheet is provided for the youth to record their research.",
        objectives: [
            "Choose an area of high interest for employment.",
            "Identify educational requirements for each area.",
            "Identify work duties of each area of employment.",
        ],
        buttons: [
            {
                route: "/unit-2/lesson-3/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-2/lesson-3/career-exploration-worksheet",
                linktext: "Career Exploration Worksheet",
                color: "btn-primary",
                window: false,
            },
            {
                route: '/img/unit-2/lesson-3/T-Folio_Unit-2_Lesson-3_Career-Exploration-Worksheet-Sample.pdf',
                linktext: "Career Exploration Worksheet - Sample (PDF)",
                color: "btn-primary",
                window: true,
            }
        ],
        image: "/img/unit-2/lesson-3/LESSON 3_Exploring-Employment-Options_AMERICANED_MC2_021-2.jpg",
        imgalt: "An older person wearing a blue patterned sweater and smiling is showing a paper to a younger person who is wearing a gray long sleeve shirt and looking thoughtful.",
        caption: " courtesy of Allison Shelley / The Verbatim Agency for American Education: Images of Teachers and Students in Action ",
        photoLink: "http://deeperlearningforall.org/deeperlearning/all-photos/7/?album_id=14788#%21enviragallery14319-14014",
        source: "",
        sourceLink: "",
        licence: "License: CC BY-NC 4.0 ",
        licenceLink: "https://creativecommons.org/licenses/by-nc/4.0/",
    }
}
