import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "scans-skills-assessment",
    templateUrl: "./scans-skills-assessment.component.html",
    styleUrls: ["./scans-skills-assessment.component.css"]
})
export class UnitThreeLessonTwoScansSkillsAssessmentComponent {

    title: string = "SCANS Skills Assessment";
    pageTitle: string = this.title + " | Unit 3, Lesson 2 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    data = [
        {
            name: "Basic Skills",
            blurb: "You can read, write, speak and listen well. You know your arithmetic.",
            skills: [
                {
                    skillName: "Speaking",
                    needsDevelopment: "Learning to speak clearly, audibly and courteously.",
                    competent: "Speak clearly and use language appropriate to the environment.",
                    proficient: "Express complex ideas in an organized and concise manner.",
                    advanced: "Present effectively to a group using well organized format, concise language and clear enunciation."
                },
                {
                    skillName: "Listening",
                    needsDevelopment: "Developing listening skills; working to make eye contact and confirm understanding.",
                    competent: "Listen attentively; make eye contact; repeat instruction to confirm understanding.",
                    proficient: "Listen attentively and demonstrate understanding through relevant responses and questions.",
                    advanced: "Retain complex information over time and apply it to later work."
                },
                {
                    skillName: "Reading",
                    needsDevelopment: "Read written directions and workplace documents with assistance.",
                    competent: "Read written directions and workplace documents independently.",
                    proficient: "Read and understand written materials, including technical documents, independently; ask questions where appropriate.",
                    advanced: "Read complex written materials and execute related tasks independently."
                },
                {
                    skillName: "Writing",
                    needsDevelopment: "Learning to write clearly with correct grammar.",
                    competent: "Write information in clear, logical, legible and grammatically correct manner.",
                    proficient: "Write clearly using work-related terminology.",
                    advanced: "Write and develop professional material such as newsletters and marketing brochures."
                },
                {
                    skillName: "Mathematics",
                    needsDevelopment: "Able to perform basic computation with supervision.",
                    competent: "Able to perform basic computation independently.",
                    proficient: "Able to interpret and apply basic computations and uses tables, graphs, diagrams and charts as needed.",
                    advanced: "Able to construct, apply and present logical applications for mathematics using tables, graphs, diagrams or charts."
                }
            ]
        },
        {
            name: "Thinking Skills",
            blurb: "You can think creatively. You can make decisions and solve problems. You know how to learn.",
            skills: [
                {
                    skillName: "Combining Ideas or Information in New Ways",
                    needsDevelopment: "Make connections with help from supervisor.",
                    competent: "Make connections with occasional help from supervisor.",
                    proficient: "Make connections independently.",
                    advanced: "Generate new ideas. Think abstractly."
                },
                {
                    skillName: "Making Decisions",
                    needsDevelopment: "Make decisions with help from supervisor.",
                    competent: "Make decisions with occasional help from supervisor.",
                    proficient: "Make decisions independently.",
                    advanced: "Make multiple decisions weighing risks and benefits to organization."
                },
                {
                    skillName: "Exercising Leadership to Identify and Solve Problems",
                    needsDevelopment: "Identify problems with help from supervisor.",
                    competent: "Identify and solve problems independently.",
                    proficient: "Explore cause of problems and options with team when solving problems.",
                    advanced: "Demonstrate leadership, develop creative solutions and systemic change, including preventive action."
                }
            ] 
        },
        {
            name: "Foundation Skill: Personal Qualities",
            blurb: "You can take personal responsibility. You think highly of yourself. You are also honest.",
            skills: [
                {
                    skillName: "Attendance and Appearance",
                    needsDevelopment: "Maintain consistent attendance, punctuality, and appropriate dress with supervision.",
                    competent: "Demonstrate understanding of appropriate workplace appearance, attendance and punctuality.",
                    proficient: "A model of excellent attendance and dress; attend events beyond those required.",
                    advanced: "Represent the organization at meetings and events."
                },
                {
                    skillName: "Self Management",
                    needsDevelopment: "Complete tasks and projects as assigned with supervision.",
                    competent: "Complete tasks and projects as assigned.",
                    proficient: "Initiate and complete projects independently.",
                    advanced: "Deliver high-quality results on schedule."
                },
                {
                    skillName: "Accepting Direction and Criticism",
                    needsDevelopment: "Learning to accept direction.",
                    competent: "Accept direction with positive attitude.",
                    proficient: "Accept constructive criticism with positive attitude.",
                    advanced: "Accept and apply constructive criticism to improve performance."
                },
                {
                    skillName: "Integrity, Honesty, and Confidentiality",
                    needsDevelopment: "Maintain appropriate confidentiality with supervision.",
                    competent: "Maintain appropriate confidentiality with occasional supervision.",
                    proficient: "Can be trusted. Demonstrate integrity and understand why certain information must remain confidential.",
                    advanced: "Model good discretion and honesty for others."
                }
            ]
        },
        {
            name: "Competency: Resource Management",
            blurb: "Time, money and materials are resources. You can manage them well.",
            skills: [
                {
                    skillName: "Manages Time",
                    needsDevelopment: "Meet assigned deadlines with supervision.",
                    competent: "Meet assigned deadlines independently.",
                    proficient: "Set priorities and deadlines independently.",
                    advanced: "Manage multiple tasks and projects effectively."
                },
                {
                    skillName: "Manages Money",
                    needsDevelopment: "Manage established program budget with supervision.",
                    competent: "Manage established program budget independently.",
                    proficient: "Help establish project budget and operates effectively within it.",
                    advanced: "Determine and manages budget efficiently."
                }
            ]
        },
        {
            name: "Competency: Interpersonal Skills",
            blurb: "You communicate and work well with other people.",
            skills: [
                {
                    skillName: "Interacting with Co- workers",
                    needsDevelopment: "Developing basic interaction skills. Respond when others initiate conversations.",
                    competent: "Interact appropriately in social settings.",
                    proficient: "Initiate positive interactions with co- workers and participate constructively as part of a team.",
                    advanced: "Lead teams of co-workers to complete projects in an effective and timely manner."
                },
                {
                    skillName: "Interacting with Customers",
                    needsDevelopment: "Developing skills necessary to deal with difficult customers/clients and situations.",
                    competent: "Appropriately request assistance when dealing with difficult customers/clients and situations.",
                    proficient: "Resolve customer/client problems independently where appropriate.",
                    advanced: "Proactively handle stress of difficult customers/clients and situations."
                },
                {
                    skillName: "Clients Respecting Diversity",
                    needsDevelopment: "Developing an understanding of diversity.",
                    competent: "Understand diversities and similarities.",
                    proficient: "Demonstrate ability to work with people different from myself.",
                    advanced: "Seek out opportunities to work with people different from myself."
                }
            ]
        },
        {
            name: "Competency: Information Management",
            blurb: "You can find, interpret and communicate information. You can organize and maintain files. You can also use a computer and process information.",
            skills: [
                {
                    skillName: "Collecting and Organizing Information",
                    needsDevelopment: "Developing ability to collect and organize information and materials needed for a task.",
                    competent: "Effectively compile information and resources in a clear, logical and legible manner.",
                    proficient: "Effectively organize and evaluate the relevance and accuracy of information.",
                    advanced: "Identify and obtain missing information based on mastery of subject."
                },
                {
                    skillName: "Interprets and Communicates Information",
                    needsDevelopment: "Able to select pertinent information with occasional assistance.",
                    competent: "Analyze information in an organized manner.",
                    proficient: "Effectively organize information and communicate results in a concise manner.",
                    advanced: "Present effectively to a group using a well-organized format, concise language and clear enunciation."
                }
            ]
        },
        {
            name: "Competency: Systems",
            blurb: "A system is the way things are done or organized. You understand social and business systems. You can check and correct your own business performance. You can make suggestions on how to improve the way things are done.",
            skills: [
                {
                    skillName: "Understanding the Structure and Dynamics of the Entire Organization",
                    needsDevelopment: "Am aware of my role within the department.",
                    competent: "Demonstrate working knowledge of the department’s role in the organization and how it relates to other departments.",
                    proficient: "Understand and negotiate the communication and workflow between departments.",
                    advanced: "Understand the role of the organization in the industry and the economy."
                },
                {
                    skillName: "Recognizing Health and Safety Issues",
                    needsDevelopment: "Practice appropriate health and safety protocol at the workplace with assistance.",
                    competent: "Practice appropriate health and safety protocol independently and recognize their importance. Report emergencies in an appropriate manner.",
                    proficient: "Understand the implication of health and safety principles and apply them to new situations.",
                    advanced: "Model good health and safety practices and help others to understand their importance."
                },
                {
                    skillName: "Understanding Personnel Policy and Relevant Labor Laws",
                    needsDevelopment: "Developing an understanding of personnel policy and, where appropriate, relevant labor laws.",
                    competent: "Understand personnel policy and, where appropriate, relevant labor laws.",
                    proficient: "Adhere to personnel policy and understands its impact on individuals.",
                    advanced: "Understand personnel policy and its impact on the organization; contribute to a positive work culture."
                }
            ]
        },
        {
            name: "Competency: Technology",
            blurb: "You can find and use the right tools for the job.",
            skills: [
                {
                    skillName: "Selecting Tools and Procedures",
                    needsDevelopment: "Able to use procedures, tools and machines with supervision.",
                    competent: "Able to use procedures, tools and machines with occasional supervision.",
                    proficient: "Able to use procedures, tools and machines without supervision.",
                    advanced: "Able to determine which procedures, tools and machines to use at appropriate times."
                },
                {
                    skillName: "Applying Technology to Task",
                    needsDevelopment: "Can identify problem as it relates to technology with supervision.",
                    competent: "Can identify a problem as it relates to technology without supervision.",
                    proficient: "Identify appropriate technology and use it to prevent problems.",
                    advanced: "Use technology appropriately to identify, prevent and solve problems."
                }
            ]
        },
    ];
}
