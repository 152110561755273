﻿import { Component, Input } from "@angular/core";

@Component({
    selector: 'self-determination-scale-radio-button-subtotal',
    templateUrl: './radio-button-subtotal.component.html',
    styleUrls: ['./radio-button-subtotal.component.css']
})
export class SelfDeterminationScaleRadioButtonSubtotalComponent {
    @Input() legend: string;
    @Input() subtotal: number;
    @Input() hasBottomBorder: boolean = false;
}