import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'job-interview-feedback',
    templateUrl: "./job-interview-feedback.component.html",
    styleUrls: ["./job-interview-feedback.component.css"]
})
export class UnitFourLessonFiveJobInterviewFeedbackComponent {

    title: string = "Job Interview: Peer Reflection Feedback";
    pageTitle: string = this.title + " | Unit 4, Lesson 5 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }
}
