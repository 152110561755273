import { Component, Input } from "@angular/core";
declare let kendo;
declare let html2pdf;
declare let $;


@Component({
    selector: 'tfolio-pdf-button',
    //template: '<button class="hidden-print" (click)="ExportToPdf(fileName);">PDF</button>',
    template: '',
    styles: ['']
})


export class TFolioPDFComponent  {

    @Input() fileName: string;
  
    ExportToPdf(fileName): void {

      var element = document.getElementById('pdfcontent');
      var opt = {
        pagebreak: { before: ['#personalStrengths'] },
        filename: fileName + '.pdf',
        jsPDF: { format: 'A4', orientation: 'portrait' },
        html2canvas: { width: 992, scale: 1 }
      };

      html2pdf().set(opt).from(element).save();


    }
}
