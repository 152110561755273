import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-four-lesson-four-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFourLessonFourLandingComponent {

    bannerHeader = "Workplace Readiness Training: Lesson 4";
    title: string = "Lesson 4: The Job Application Process";
    pageTitle: string = "The Job Application Process | Unit 4, Lesson 4 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 4",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-4",
                    linktext: "Unit 4"
                }
            ]
        },
        summary: "In Lesson 4, students will identify the various components of a job application. They will then complete a practice job application using their personal information. They will be able to utilize this application as a guide in the future when completing timely job applications. ",
        objectives: [
            "Learn about the various components of a job application.",
            "Complete a sample job application.",
            "Choose appropriate references. ",
        ],
        buttons: [
            {
                route: "/unit-4/lesson-4/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-4/lesson-4/practice-job-application",
                linktext: "Practice Job Application",
                color: "btn-primary",
                window: false,
            },
            {
                route: "/unit-4/lesson-4/job-applications-tracker",
                linktext: "Job Applications Tracker",
                color: "btn-primary",
                window: false,
            },
            {
                route: '/img/unit-4/lesson-4/T-Folio_Unit-4_Lesson-4_The-Job-Application-Process.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-4/lesson-4/L4_233-pom-4028-id-260562-jpeg.jpg",
        imgalt: "A student fills out a form at a desk.",
        caption: "on",
        photoLink: "https://www.rawpixel.com/image/260562/study-student-arab-arabian-dubai-dubai-qutar-indonesia-indonisian-islam-malasia-malasian-malay",
        source: "Rawpixel",
        sourceLink: "https://www.rawpixel.com/",
        licence: "Free license",
        licenceLink: "https://www.rawpixel.com/services/image-licenses/free-license",
    }


}
