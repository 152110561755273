import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-four-lesson-one-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFourLessonOneLandingComponent {

    bannerHeader = "Workplace Readiness Training: Lesson 1";
    title: string = "Lesson 1: Work Maturity Skills";
    pageTitle: string = "Work Maturity Skills | Unit 4, Lesson 1 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 1",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-4",
                    linktext: "Unit 4"
                }
            ]
        },
        summary: "In this lesson, students will learn about work maturity skills and be able to identify their personal areas of strength and challenges. Students will also develop the necessary skills to address various types of conflict within the workplace environment.",
        objectives: [
            "Be able to define and give examples of work maturity skills.",
            "Recognize the connection between work maturity skills and success in the workplace.",
            "Identify work maturity skills that are a personal strength.",
            "Identify work maturity skills that need improvement and commit to next steps to grow in those areas.",
            "Have tools to address workplace conflict. ",
        ],
        buttons: [
            {
                route: "/unit-4/lesson-1/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-4/lesson-1/work-maturity-skills-list",
                linktext: "Work Maturity Skills List",
                color: "btn-primary",
                window: false,
            },
            {
                route: "/img/unit-4/lesson-1/T-Folio_Unit-4_Lesson-1_Job-Description.pdf",
                linktext: "Job Description PDF",
                color: "btn-primary",
                window: true,
            },
            {
                route: '/img/unit-4/lesson-1/T-Folio_Unit-4_Lesson-1_Work-Maturity-Skills.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-4/lesson-1/L1_cytonn-photography-604681-unsplash.jpg",
        imgalt: "This image is a close up of two hands shaking in a business setting.",
        caption: "by Cytonn Photography on",
        photoLink: "https://unsplash.com/photos/n95VMLxqM2I",
        source: "Unsplash",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",

    }


}
