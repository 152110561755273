import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "resume-template",
    templateUrl: "./resume-template.component.html",
    styleUrls: ["./resume-template.component.css"]
})
export class UnitFourLessonTwoResumeTemplateComponent {

    title: string = "R\xE9sum\xE9 Template";
    pageTitle: string = this.title + " | Unit 4, Lesson 2 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }

    restoreBulletPoint($event) {
        if ($event.srcElement.innerHTML.indexOf("<li>") == -1)
            $event.srcElement.innerHTML = '<ul><li></li></ul>';
    }

    addPosition() {
        var elem = document.getElementById('experience');
        elem.innerHTML += 'Position, Employer, City, State, Start Year-End Year<ul><li></li></ul>';
    }
}
