﻿import { Component, Input } from "@angular/core";

@Component({
    selector: 'landing-page-banner',
    template: `
<div class="container-fluid narrow-gutter banner">
    <div class="row-fluid">
        <div class="col-md-12 narrow-gutter">
            <div class="banner-text">{{bannerText}}</div>
        </div>
    </div>
</div>
`,
    styles: [`

    .narrow-gutter > [class*='col-'] {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .banner {
        min-height: 80px;
        background-color: #00a3d8;
        padding: 0px;
        margin: 0px;
        box-shadow: 0px 0px 6px #000000b3;
        width: 100%;
        margin-top:-25px;
    }

    .banner-text {
        font-family: Rockwell;
        font-size: xx-large;
        font-weight:bold;
        color: #ffffff;
        text-shadow: 2px 2px 7px #000000;
        padding-left:10%;
        padding-top:20px;
    }

    @media only screen and (max-width: 760px), 
        (min-device-width: 768px) and (max-device-width: 1024px) {

        .banner-text {
            font-size: x-large;
        }
    }
`
    ]
})

export class LandingPageBannerComponent {
    @Input('banner-text') bannerText: string;
}