import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'unit-one-lesson-seven-guide',
    templateUrl: './_guide.component.html',
    styleUrls: ['./_guide.component.css']
})
export class UnitOneLessonSevenGuideComponent {

    pageTitle: string = "Lesson Guide | Unit 1, Lesson 7 | T-Folio";
    title: string = "Lesson Guide";
    subtitle: string = "Lesson 7: Synthesize and Reflect";

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
