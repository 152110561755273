import { Component, Input } from "@angular/core";

@Component({
    selector: 'job-shadow-workbook-cite',
    template: `
    <div class="row-fluid">
        <div class="col-md-12 citations" style="font-size:smaller;">
            This <a [routerLink]="['/unit-3/lesson-4/job-shadow-workbook']"> workbook</a> is a modified version of the "Sophomore Job Shadow Handbook" 
                provided by the 
                <a href="http://www.mdihs.net/" target="_blank">Mount Desert Island High School</a>. 
        </div>
    </div>
`,
    styles: [`
div.citations {
    padding-top:25px;
}
div.citations a[href]:after {
    content: none !important;
}
`]
})
export class UnitThreeLessonFourWorkbookCiteComponent {
}
