﻿import { Component, Input } from "@angular/core";

@Component({
    selector: 'self-determination-scale-graph-bar',
    templateUrl: './graph-bar.component.html',
    styleUrls: ['./graph-bar.component.css']
})
export class SelfDeterminationScaleGraphBarComponent {
    @Input() val: number;
    @Input() label: string;
    @Input() range: string;
}