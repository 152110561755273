import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'unit-one-lesson-seven-synthesize-reflect',
    templateUrl: './synthesize-reflect.component.html',
    styleUrls: ['./synthesize-reflect.component.css']
})
export class UnitOneLessonSevenSynthesizeReflectComponent {

    title: string = "Synthesizing Assessment Results: Bringing It All Together";
    pageTitle: string = this.title + " | Unit 1, Lesson 7 | T-Folio";

    orderedListContent = [
        { id: '01', content: ' Select the appropriate assessments and/ or tools you will be using to complete the section. Do one section at a time.' },
        { id: '02', content: ' As you review the tools results, highlight those items that represent the traits pertaining to you.' },
        { id: '03', content: ' Review the traits you have highlighted and group them into categories (e.g., interpersonal skills, interacting with others, work environment, etc.). Enter those categories in the Trait Category column. There are no wrong category labels; create ones that make sense to you!' },
        { id: '04', content: ' In the Common Traits column, list three to five common or connected traits that align to each category from your highlights.' },
        { id: '05', content: ' In the Combined Trait column, combine all the aspects into one phrase or statement.' },
        { id: '06', content: ' In the last column, explain how the combined trait reflects you and give an example of how it displays itself in your life.' },
        { id: '07', content: ' Complete a minimum of three Trait Categories for each section.' },
        { id: '08', content: ' Finally, for each section, name the assessments and/or tools used in the space provided.' },
    ]

    tipsContent = [
        { id: '01', content: ' For each section (strengths, needs, preferences, interests), use a different color highlighter with the selected assessments/tools when reviewing your results.' },
        { id: '02', content: ' If you had to describe your traits to a person who you just met, what are the top attributes you would want them to know about you?' },

    ]

    textContent = [
        {
            id: '01', name: 'Strengths', traitCategory: '(e.g., Interpersonal Skills.)',
            CommonTraitsOne: '(e.g., Work well with others.',
            CommonTraitsTwo: 'Enjoy being around others.',
            CommonTraitsThree: 'Get along with classmates outside class.',
            CommonTraitsFour: 'Respect individual differences.',
            CommonTraitsFive: 'Make new friends easily.)',
            combinedTrait: '(e.g. I have strong interpersonal skills and I value and enjoy being with other people.)',
            reflectMe: '(e.g. I am a friendly person and enjoy meeting new people. I am the team lead on the FPHS Welcoming Committee at my school.)',
            headerHeight: '215px',
        },
        {
            id: '02', name: 'Needs', traitCategory: '(e.g., Interacting with others.)',
            CommonTraitsOne: '(e.g., Difficult to ask for help.',
            CommonTraitsTwo: 'Easily embarrassed.',
            CommonTraitsThree: 'Shy around others.',
            CommonTraitsFour: 'Difficult to make new friends.)',
            CommonTraitsFive: '',
            combinedTrait: '(e.g., I have challenges with interacting with others, especially those I do not know well.)',
            reflectMe: '(e.g., I have to plan ahead and practice initiating conversations or asking for help to address my less confident, hesitant self.  I use my pre-written scripts to help me engage with others.)',
            headerHeight: '250px',
        },
        {
            id: '03', name: 'Preferences', traitCategory: '(e.g., Work Environment.)',
            CommonTraitsOne: '(e.g., Prefer being outdoors in all kinds of weather.',
            CommonTraitsTwo: 'Being in nature.',
            CommonTraitsThree: 'Learning about agriculture and forestry.',
            CommonTraitsFour: 'Physical labor.)',
            CommonTraitsFive: '',
            combinedTrait: '(e.g., I prefer being part of nature and performing physically demanding work to protect and maintain our natural environments.)',
            reflectMe: '(e.g., Being outdoors doing demanding work and giving back to the environment is what I really enjoy. I maintain my family acreage.)',
            headerHeight: '200px',  
        },

        {
            id: '04', name: 'Interests', traitCategory: '(e.g., Culinary  Creativity.)',
            CommonTraitsOne: '(e.g., Serving others.',
            CommonTraitsTwo: 'Enjoy being around others.',
            CommonTraitsThree: 'Trying new foods from different cultures.',
            CommonTraitsFour: 'Hands on activities.)',
            CommonTraitsFive: '',
            combinedTrait: '(e.g., I have a high interest in creating and learning about foods through experimenting and sharing dishes with others.)',
            reflectMe: '(e.g., I do my best when I can use my hands, tap into my creative side and serve others.  I routinely make new and innovative meals for my family.)',
            headerHeight: '215px',
        },

    ]

    constructor(private titleService: Title) {

        this.titleService.setTitle(this.pageTitle);

    }

    doPrint(): void {
        window.print();
    }
}
