﻿import { Component, Input } from "@angular/core";

@Component({
    selector: 'tfolio-directions',
    template: `
<div class="container-fluid" [ngStyle]="{'padding-bottom' : this.padBottom ? '25px' : '0px' }">
    <div class="row">
        <div class="col-md-12">
            <b [id]="identifier">{{tag}}:</b>
            <span [attr.aria-labelledby]="identifier">
                <ng-content></ng-content>
            </span>
        </div>
    </div>
</div>
`,
    styles: [``]
})
export class TFolioDirectionsComponent {
    @Input() identifier: string;
    @Input() tag: string;
    @Input() padBottom: boolean = false;
}