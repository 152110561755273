import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'postsecondary-goals-worksheet',
    templateUrl: './postsecondary-goals-worksheet.component.html',
    styleUrls: ['./postsecondary-goals-worksheet.component.css']
})
export class UnitTwoLessonTwoPostsecondaryGoalsWorksheetComponent {

    title: string = "Postsecondary Goals Worksheet";
    pageTitle: string = this.title + " | Unit 2, Lesson 2 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

}
