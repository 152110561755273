﻿import { Component } from "@angular/core";

@Component({
    selector: 'encuesta-de-interes',
    templateUrl: './encuesta-de-interes.component.html',
    styleUrls: ['./encuesta-de-interes.component.css']
})
export class UnitOneLessonThreeEncuestaDeInteresComponent {

    title: string = "Encuesta de Interes de Grupos de Carreras";

    boxTotals: Map<string, number> = new Map();

    constructor() {

        this.boxTotals.set('01', 0);
        this.boxTotals.set('02', 0);
        this.boxTotals.set('03', 0);
        this.boxTotals.set('04', 0);
        this.boxTotals.set('05', 0);
        this.boxTotals.set('06', 0);
        this.boxTotals.set('07', 0);
        this.boxTotals.set('08', 0);
        this.boxTotals.set('09', 0);
        this.boxTotals.set('10', 0);
        this.boxTotals.set('11', 0);
        this.boxTotals.set('12', 0);
        this.boxTotals.set('13', 0);
        this.boxTotals.set('14', 0);
        this.boxTotals.set('15', 0);
        this.boxTotals.set('16', 0);
    }

    totalBox(event: any) {

        var chkboxId: string = event.target.id.substring(5, 7);

        var incr = event.target.checked ? 1 : -1;
        var curBoxTotal = this.boxTotals.get(chkboxId);
        this.boxTotals.set(chkboxId, curBoxTotal + incr);
    }

    doPrint(): void {
        window.print();
    }
}