import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'facilitator-whats-important-to-me',
    templateUrl: './whats-important-to-me-facilitator.component.html',
    styleUrls: ['./whats-important-to-me-facilitator.component.css']
})
export class IntroductionWhatsImportantToMeFacilitatorComponent {

    title: string = "What's Important to Me (Facilitator)";
    pageTitle: string = this.title + " | Introduction, Lesson 1 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    doPrint(): void {
        window.print();
    }
}
