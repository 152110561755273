import { Component, ViewChild, ElementRef } from "@angular/core";


@Component({
    selector: 'tfolio-copyright',
    template: `
         <div class="row-fluid"> 
            <div class="col-md-12" style="margin-top:5px;">
                <hr style="display:block;border-color:darkgrey;height:1px;width:100%;"> 
            </div>
        </div>
        <div class="row-fluid">
            <div class="col-md-12" style="margin-top:1px;font-size:small;">
               T-Folio&nbsp;|&nbsp;<a href="http://www.cctstfolio.com" target="_blank">www.cctstfolio.com</a>&nbsp;|&nbsp;2020
            </div>  
            <div class="col-md-1" style="padding-top:15px;">
                <img src="/img/ccby.png" alt = "CC BY License" height="20" style="float:left;">
            </div>
            <div class="col-md-11" style="padding-top:5px;padding-left:20px;">
                <div style="font-size:12.3px;">                     
                     <p>
                        Except where otherwise noted, this work, created by <a href = "http://www.seattleu.edu/ccts" target = "_blank"> CCTS at Seattle University </a> 
                        with funding from <a href = "https://www.dshs.wa.gov/strategic-planning/division-vocational-rehabilitation" target = "_blank"> WA DSHS / DVR </a>, is licensed under a
                        <a href = "https://creativecommons.org/licenses/by/4.0/" target="_blank"> CC BY 4.0 license </a>. All logos and trademarks are property of their respective owners.
                     </p>
                </div>
            </div>
        </div>
`,


    styles: [`
    .centered {
        text-align:center;
    }
    .padded {
        padding-top:25px;
    }
    @media print {
         a[href]:after  {
            content: none!important;
        }
    }
`]
})

export class TFolioCopyright {
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }
}
