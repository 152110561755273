import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-one-lesson-three-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitOneLessonThreeLandingComponent {
    title = "Lesson 3: Interests";
    pageTitle = "Interests | Unit 1, Lesson 3 | T-Folio";
    bannerHeader = "Job Exploration Groundwork: Lesson 3";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 3",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-1",
                    linktext: "Unit 1"
                }
            ]
        },
        summary: "In this lesson, students will brainstorm careers and jobs they are interested in, through guided discussion and interest surveys. This lesson facilitates a deeper understanding of which jobs would be a good fit for the individual and why, based on their own interests and preferences.",
        objectives: [
            "Understand and reflect upon the importance of identifying work they enjoy.",
            "Explore career clusters results and jobs that match interests.",
            "Apply readiness methods and supports when taking interests assessment.",
            "Complete one or more interest assessments.",
        ],
        buttons: [
            {
                route: "/unit-1/lesson-3/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            },
            {
                route: "/unit-1/lesson-3/interests-survey",
                linktext: "Career Clusters Interest Survey",
                color: "btn-primary",
            },
            {
                route: "/img/unit-1/lesson-3/T-Folio_Unit-1_Lesson-3_Interests.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-1/LESSON 3_jobs_photography.jpg",
        imgalt: "A person holds a printed photograph of a lake and mountains in front of their computer screen.",
        caption: "on",
        source: "Pexels",
        photoLink: "https://www.pexels.com/photo/adult-art-artist-blur-297648/",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",

    }
}
