import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'introduction-lesson-one-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class IntroductionLessonOneLandingComponent {
    title = "Lesson 1: Spotlight on Student Warm-Up";
    pageTitle = "Spotlight on Student Warm-Up | Introduction, Lesson 1 | T-Folio";
    bannerHeader = "Student-Centered Introduction: Lesson 1";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 1",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/introduction",
                    linktext: "Introduction"
                }
            ]
        },
        summary: "This introductory lesson introduces both youth and facilitators to the student-led work of the T-Folio. Through a guided activity, peer work, and suggested discussion points, students identify what is important to them in their lives and reflect on using their own voice.",
        objectives: [
            "Self-reflect and explore what is important to them and their future.",
            "Increase ability to identify and voice future desires.",
            "Complete the What's Important to Me worksheet.",
            "Learn about the T-Folio Tracker.",
            "Develop a system for saving their documents as they use the T-Folio curriculum.",
        ],
        buttons: [
            {
                route: "/introduction/lesson-1/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            },
            {
                route: "/introduction/lesson-1/whats-important-to-me",
                linktext: "What's Important to Me",
                color: "btn-primary",
            },
            {
                route: "/introduction/lesson-1/whats-important-to-me-facilitator",
                linktext: "What's Important to Me (Facilitator)",
                color: "btn-primary",
            },
            {
                route: "/img/introduction/lesson-1/T-Folio_Introduction_Lesson-1_T-Folio-Tracker.docx",
                linktext: "T-Folio Tracker (Word document)",
                color: "btn-primary",
                window: true,
            },
            {
                route: "/img/introduction/lesson-1/T-Folio_Introduction_Lesson-1_Spotlight-on-Student.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/introduction/LESSON 1_dayne-topkin-76099.jpg",
        imgalt: "A young person pictured from the shoulders up holds a card in front of their right eye that reads “Dare to Dream.”", 
        caption: "by Dayne Topkin on",
        photoLink: "https://unsplash.com/photos/Z9OC6FeDKzg",
        source: "Unsplash",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",

    }
}
