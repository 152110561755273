import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-five-lesson-five-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFiveLessonFiveLandingComponent {

    bannerHeader = "Self-Advocacy: Lesson 5";
    title: string = "Lesson 5: Action Plan for Self-Advocacy and Independent Living";
    pageTitle: string = "Action Plan for Self-Advocacy and Independent Living | Unit 5, Lesson 5 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 5",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-5",
                    linktext: "Unit 5"
                }
            ]
        },
        summary: "In Lesson 5, students will develop an action plan to follow up with the activities conducted throughout Unit 5. This will allow them to put the reflection and goal-setting activities they have completed in Lessons 1-4 of the unit into a plan of action.",
        objectives: [
            "Review the components of an action plan.",
            "Create an action plan for one or more of the areas covered in Unit 5 (self-determination reassessment, self-advocacy, independent living goals, and student-led IEPs).",
            "Identify supports needed for each portion of the action plan.",
        ],
        buttons: [
            {
                route: "/unit-5/lesson-5/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },

            {
                route: "/unit-5/lesson-5/action-plan",
                linktext: "Action Plan",
                color: "btn-primary",
                window: false,
            },

            {
                route: '/img/unit-5/lesson-5/T-Folio_Unit-5_Lesson-5_Action-Planning-For-Self-Advocacy-And-Independent-Living.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-5/lesson-5/5_oliver-cole-232552-unsplash.jpg",
        imgalt: "A man walks in front of a colorful painted mural that says “Courage.”",
        caption: "by Oliver Cole on",
        photoLink: "https://unsplash.com/photos/0hQATQI7F7Q",
        source: "Unsplash",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",

    }


}
