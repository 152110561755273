import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-five-lesson-six-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFiveLessonSixLandingComponent {

    bannerHeader = "Self-Advocacy: Lesson 6";
    pageTitle: string = "Final Reflection | Unit 5, Lesson 6 | T-Folio";
    title: string = "Lesson 6: Final Reflection";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 6",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-5",
                    linktext: "Unit 5"
                }
            ]
        },
        summary: "In this lesson, students will reflect on what they have accomplished through their work with the T-Folio curriculum. They will use the T-Folio Tracker to assess which documents they have completed for their T-Folio and determine additional needs, if necessary.",
        objectives: [
            "Organize the completed components of their T-Folio. ",
            "Determine if additional documents need to be completed or updated.",
            "Create a plan to complete or update their T-Folio.",
            "Reflect on their T-Folio experience.",
        ],
        buttons: [
            {
                route: "/unit-5/lesson-6/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/img/unit-5/lesson-6/T-Folio_Unit-5_Lesson-6_T-Folio-Tracker.docx",
                linktext: "T-Folio Tracker (Word document)",
                color: "btn-primary",
                window: true,
            },
            {
                route: "/unit-5/lesson-6/final-reflection-worksheet",
                linktext: "Final Reflection Worksheet",
                color: "btn-primary",
                window: false,
            },

            {
                route: '/img/unit-5/lesson-6/T-Folio_Unit-5_Lesson-6_Final-Reflection.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-5/lesson-6/6_teen1.jpg",
        imgalt: "A black-and-white image of a young man smiling, wearing earbuds and a t-shirt that says “Thank you.”",
        caption: "on",
        photoLink: "https://www.pexels.com/photo/man-boy-black-and-white-person-9687/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",

    }


}
