﻿import { Component, Input } from "@angular/core";

@Component({
    selector: 'self-determination-thermometer',
    templateUrl: './thermometer.component.html',
    styleUrls: ['./thermometer.component.css']
})
export class SelfDeterminationScaleThermometerComponent {
    @Input() thermometerLevel: string = '';
   
}