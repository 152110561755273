﻿import { Component, Input } from "@angular/core";

@Component({
    selector: 'agency-connections-agency-info-table',
    templateUrl: './agency-info.component.html',
    styleUrls: ['./agency-info.component.css']
})
export class AgencyConnectionsAgencyInfoTableComponent {

    @Input('agency-type') agencyType: string;

}