import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-one-lesson-six-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitOneLessonSixLandingComponent {
    title = "Lesson 6: Work Maturity Skills";
    pageTitle = "Work Maturity Skills | Unit 1, Lesson 6 | T-Folio";
    bannerHeader = "Job Exploration Groundwork: Lesson 6";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 6",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-1",
                    linktext: "Unit 1"
                }
            ]
        },
        summary: "In Lesson 6, students will learn about 'work maturity skills' (or soft skills), reflecting on which skills are needed in the workplace. They will then complete the Skills Assessment Worksheet, in which they will identify their own existing skills and the skills they would like to develop.",
        objectives: [
            "Identify the skills  that they have already acquired and feel competent doing.",
            "Identify skills they enjoy, even if not proficient at them.",
            "Identify which skills they would like to learn, acquire, or develop further.",
        ],
        buttons: [
            {
                route: "/unit-1/lesson-6/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            },
            {
                route: "/unit-1/lesson-6/work-maturity-skills",
                linktext: "Skills Assessment",
                color: "btn-primary",
            },
            {
                route: "/img/unit-1/lesson-6/T-Folio_Unit-1_Lesson-6_Work-Maturity-Skills.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-1/LESSON 6_climate-kic-350836.jpg",
        imgalt: "Five college-aged students are gathered around a small, wooden table working on a design project with Legos, play dough, and other creative materials, as well as three computer laptops.",
        caption: "by Climate KIC on",
        photoLink: "",
        source: "Unsplash",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",
    }
}
