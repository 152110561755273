import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "thank-you-note-template",
    templateUrl: "./thank-you-note-template.component.html",
    styleUrls: ["./thank-you-note-template.component.css"]
})
export class UnitFourLessonSixThankYouNoteTemplateComponent {

    title: string = "Thank You Note Template";
    pageTitle: string = this.title + " | Unit 4, Lesson 6 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    note_date: string;
    recipient: string;
    position: string;
    skills_interests: string;
    enthusiasm: string;
    neglected: string;
    username: string;

    copyToClipboard(event: any) {

        var copyText = document.getElementById("note").innerText;

        var textArea = document.createElement("textarea");

        textArea.style.position = 'fixed';
        textArea.style.top = '0px';
        textArea.style.left = '0px';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0px';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = copyText;
        document.body.appendChild(textArea);

        textArea.select();
        document.execCommand('copy');

        document.body.removeChild(textArea);

    }

    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }
}
