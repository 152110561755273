import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'needs-assessment',
    templateUrl: './needs-assessment.component.html',
    styleUrls: ['./needs-assessment.component.css']
})
export class UnitOneLessonFourNeedsAssessmentComponent {

    title: string = "Needs Assessment";
    pageTitle: string = this.title + " | Unit 1, Lesson 4 | T-Folio";

    constructor(private titleService: Title) {

        this.titleService.setTitle(this.pageTitle);

    }

}

