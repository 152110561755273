import { NgModule } from "@angular/core";

import { BrowserModule, Title } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import "rxjs/Rx";
import { AppRouting } from "./app.routing";

import { AppComponent } from "./app.component";

import { HomeComponent } from "./home.component";
import { PageNotFoundComponent } from "./page-not-found.component";

import { TFolioHeadingComponent } from "./_shared/tfolio-heading.component";
import { TFolioPrintButtonComponent } from "./_shared/tfolio-print-button.component";
import { TFolioCopyright } from "./_shared/tfolio-copyright.component";
import { TFolioPDFComponent } from "./_shared/tfolio-pdf-button.component";
import { TFolioWhatsImportantToMePDFComponent } from "./_shared/tfolio-whats-important-to-me-pdf-button";
import { TFolioScroll } from "./_shared/tfolio-scroll.component";
import { TFolioPageTitleComponent } from "./_shared/tfolio-page-title.component";
import { TFolioDirectionsComponent } from "./_shared/tfolio-directions.component";
import { TFolioCiteComponent } from "./_shared/tfolio-cite.component";
import { TFolioTextareaComponent } from "./_shared/tfolio-textarea";
import { TFolioNavButtons } from "./_shared/tfolio-nav-butt.component";

import { LandingPageBannerComponent } from "./_shared/landing-pages/landing-page-banner.component";
import { LandingPageFooterComponent } from "./_shared/landing-pages/landing-page-footer.component";

import { LessonGuideFooterComponent } from "./_shared/lesson-guides/lesson-guide-footer.component";

import { StudentCenteredIntroductionlandingpage } from "./introduction/_landing.component";
import { IntroductionLessonOneLandingComponent } from "./introduction/lesson-1/_landing.component";
import { IntroductionLessonOneGuideComponent } from "./introduction/lesson-1/_guide.component";
import { IntroductionWhatsImportantToMeComponent } from "./introduction/lesson-1/whats-important-to-me.component";
import { IntroductionWhatsImportantToMeFacilitatorComponent } from "./introduction/lesson-1/whats-important-to-me-facilitator.component";
import { IntroductionLessonTwoLandingComponent } from "./introduction/lesson-2/_landing.component";
import { IntroductionLessonTwoGuideComponent } from "./introduction/lesson-2/_guide.component";
import { IntroductionLessonTwoSelfIntroductionComponent } from "./introduction/lesson-2/_self-reflection.component";

import { UnitOneLandingpage } from "./unit-1/_landing.component";
import { UnitOneLessonOneLandingComponent } from "./unit-1/lesson-1/_landing.component";
import { UnitOneLessonOneGuideComponent } from "./unit-1/lesson-1/_guide.component";
import { UnitOneLessonOneStrengthsAssessmentComponent } from "./unit-1/lesson-1/strengths-assessment.component";
import { UnitOneLessonTwoLandingComponent } from "./unit-1/lesson-2/_landing.component";
import { UnitOneLessonTwoGuideComponent } from "./unit-1/lesson-2/_guide.component";
import { UnitOneLessonTwoPreferencesAssessmentComponent } from "./unit-1/lesson-2/preferences-assessment.component";
import { UnitOneLessonThreeLandingComponent } from "./unit-1/lesson-3/_landing.component";
import { UnitOneLessonThreeGuideComponent } from "./unit-1/lesson-3/_guide.component";
import { UnitOneLessonThreeInterestsSurveyComponent } from "./unit-1/lesson-3/interests-survey.component";
import { UnitOneLessonThreeEncuestaDeInteresComponent } from "./unit-1/lesson-3/encuesta-de-interes.component";
import { UnitOneLessonThreeCareerClustersComponent } from "./unit-1/lesson-3/child-components/career-clusters.component"
import { UnitOneLessonFourLandingComponent } from "./unit-1/lesson-4/_landing.component";
import { UnitOneLessonFourGuideComponent } from "./unit-1/lesson-4/_guide.component";
import { UnitOneLessonFourNeedsAssessmentComponent } from "./unit-1/lesson-4/needs-assessment.component";
import { UnitOneLessonFourChallengesInTheClassroomComponent } from "./unit-1/lesson-4/classroom-challenges.component";
import { UnitOneLessonFiveLandingComponent } from "./unit-1/lesson-5/_landing.component";
import { UnitOneLessonFiveGuideComponent } from "./unit-1/lesson-5/_guide.component";
import { UnitOneLessonFiveSelfDeterminationScaleComponent } from "./unit-1/lesson-5/self-determination-scale.component";
import { SelfDeterminationScaleRadioButtonGroupComponent } from "./unit-1/lesson-5/child-components/radio-button-group.component";
import { SelfDeterminationScaleRadioButtonSubtotalComponent } from "./unit-1/lesson-5/child-components/radio-button-subtotal.component";
import { SelfDeterminationScaleGraphBarComponent } from "./unit-1/lesson-5/child-components/graph-bar.component";
import { SelfDeterminationScaleThermometerComponent } from "./unit-1/lesson-5/child-components/thermometer.component";
import { SelfDeterminationScaleGraphLegendComponent } from "./unit-1/lesson-5/child-components/graph-legend.component";
import { UnitOneLessonSixLandingComponent } from "./unit-1/lesson-6/_landing.component";
import { UnitOneLessonSixGuideComponent } from "./unit-1/lesson-6/_guide.component";
import { UnitOneLessonSixWorkMaturitySkillsComponent } from "./unit-1/lesson-6/work-maturity-skills.component"
import { UnitOneLessonSevenLandingComponent } from "./unit-1/lesson-7/_landing.component";
import { UnitOneLessonSevenGuideComponent } from "./unit-1/lesson-7/_guide.component";
import { UnitOneLessonSevenSynthesizeReflectComponent } from "./unit-1/lesson-7/synthesize-reflect.component";
import { UnitOneLessonSevenTextareaBlockComponent } from "./unit-1/lesson-7/child-components/_textarea-block.component";
import { UnitOneLessonSevenListNamesComponent } from "./unit-1/lesson-7/child-components/_list-names.component";
import { UnitOneLessonEightLandingComponent } from "./unit-1/lesson-8/_landing.component";
import { UnitOneLessonEightGuideComponent } from "./unit-1/lesson-8/_guide.component";
import { UnitOneLessonEightResearchingOccupationComponent } from "./unit-1/lesson-8/researching-occupations.component"; 
import { UnitOneLessonEightQuestionTextareaComponent } from "./unit-1/lesson-8/child-components/question-textarea.component"; 

import { UnitTwoLandingComponent } from "./unit-2/_landing.component";
import { UnitTwoLessonOneLandingComponent } from "./unit-2/lesson-1/_landing.component";
import { UnitTwoLessonOneGuideComponent } from "./unit-2/lesson-1/_guide.component";
import { UnitTwoLessonOneSmartGoalsWorksheetComponent } from "./unit-2/lesson-1/smart-goals-worksheet.component";
import { UnitTwoLessonTwoLandingComponent } from "./unit-2/lesson-2/_landing.component";
import { UnitTwoLessonTwoGuideComponent } from "./unit-2/lesson-2/_guide.component";
import { UnitTwoLessonTwoPostsecondaryGoalsWorksheetComponent } from "./unit-2/lesson-2/postsecondary-goals-worksheet.component";
import { UnitTwoLessonThreeLandingComponent } from "./unit-2/lesson-3/_landing.component";
import { UnitTwoLessonThreeGuideComponent } from "./unit-2/lesson-3/_guide.component";
import { UnitTwoLessonThreeCareerExplorationWorksheetComponent } from "./unit-2/lesson-3/career-exploration-worksheet.component";
import { UnitTwoLessonFourLandingComponent } from "./unit-2/lesson-4/_landing.component";
import { UnitTwoLessonFourGuideComponent } from "./unit-2/lesson-4/_guide.component";
import { UnitTwoLessonFiveLandingComponent } from "./unit-2/lesson-5/_landing.component";
import { UnitTwoLessonFiveGuideComponent } from "./unit-2/lesson-5/_guide.component";
import { UnitTwoLessonFiveCourseOfStudyWorksheetComponent } from "./unit-2/lesson-5/course-of-study-worksheet.component";
import { UnitTwoLessonSixLandingComponent } from "./unit-2/lesson-6/_landing.component";
import { UnitTwoLessonSixGuideComponent } from "./unit-2/lesson-6/_guide.component";
import { UnitTwoLessonSixAgencyConnectionsWorksheetComponent } from "./unit-2/lesson-6/agency-connections-worksheet.component";
import { AgencyConnectionsAgencyInfoTableComponent } from "./unit-2/lesson-6/child-components/agency-info.component";
import { UnitTwoLessonSevenLandingComponent } from "./unit-2/lesson-7/_landing.component";
import { UnitTwoLessonSevenGuideComponent } from "./unit-2/lesson-7/_guide.component";
import { UnitTwoLessonSevenActionPlanComponent } from "./unit-2/lesson-7/action-plan.component";

import { UnitThreeLandingComponent } from "./unit-3/_landing.component";
import { UnitThreeLessonOneLandingComponent } from "./unit-3/lesson-1/_landing.component";
import { UnitThreeLessonOneGuideComponent } from "./unit-3/lesson-1/_guide.component";
import { UnitThreeLessonTwoLandingComponent } from "./unit-3/lesson-2/_landing.component";
import { UnitThreeLessonTwoGuideComponent } from "./unit-3/lesson-2/_guide.component";
import { UnitThreeLessonTwoScansSkillsAssessmentComponent } from "./unit-3/lesson-2/scans-skills-assessment.component";
import { UnitThreeLessonThreeLandingComponent } from "./unit-3/lesson-3/_landing.component";
import { UnitThreeLessonThreeGuideComponent } from "./unit-3/lesson-3/_guide.component";
import { UnitThreeLessonThreeInformationalInterviewWorksheetComponent } from "./unit-3/lesson-3/informational-interview-worksheet.component";
import { UnitThreeLessonFourWorkbookLandingComponent } from "./unit-3/lesson-4/workbook-landing.component";
import { UnitThreeLessonFourLandingComponent } from "./unit-3/lesson-4/_landing.component";
import { UnitThreeLessonFourGuideComponent } from "./unit-3/lesson-4/_guide.component";
import { UnitThreeLessonFourWorkbookCiteComponent } from "./unit-3/lesson-4/workbook/_shared/workbook-cite.component";
import { UnitThreeLessonFourWorkbookFooterComponent } from "./unit-3/lesson-4/workbook/_shared/workbook-footer.component";
import { UnitThreeLessonFourWorkbookCoverLetterComponent } from "./unit-3/lesson-4/workbook/cover-letter.component";
import { UnitThreeLessonFourWorkbookChecklistComponent } from "./unit-3/lesson-4/workbook/job-shadow-checklist.component";
import { UnitThreeLessonFourWorkbookPhoneScriptComponent } from "./unit-3/lesson-4/workbook/phone-script.component";
import { UnitThreeLessonFourWorkbookEmailScriptComponent } from "./unit-3/lesson-4/workbook/email-script.component";
import { UnitThreeLessonFourWorkbookStudentQuestionsComponent } from "./unit-3/lesson-4/workbook/student-questions.component";
import { UnitThreeLessonFourWorkbookDayOfComponent } from "./unit-3/lesson-4/workbook/day-of.component";
import { UnitThreeLessonFourWorkbookHostFeedbackComponent } from "./unit-3/lesson-4/workbook/host-feedback.component";
import { UnitThreeLessonFourWorkbookObservationComponent } from "./unit-3/lesson-4/workbook/observation.component";
import { UnitThreeLessonFourWorkbookReflectionComponent } from "./unit-3/lesson-4/workbook/reflection.component";
import { UnitThreeLessonFourWorkbookEvaluationComponent } from "./unit-3/lesson-4/workbook/evaluation.component";
import { UnitThreeLessonFourWorkbookThankYouComponent } from "./unit-3/lesson-4/workbook/thank-you.component";
import { UnitThreeLessonFiveLandingComponent } from "./unit-3/lesson-5/_landing.component";
import { UnitThreeLessonFiveGuideComponent } from "./unit-3/lesson-5/_guide.component";
import { UnitThreeLessonFivePerformanceReviewComponent } from "./unit-3/lesson-5/performance-review.component";
import { UnitThreeLessonSixLandingComponent } from "./unit-3/lesson-6/_landing.component";
import { UnitThreeLessonSixGuideComponent } from "./unit-3/lesson-6/_guide.component";

import { UnitFourLandingComponent } from "./unit-4/_landing.component";
import { UnitFourLessonOneLandingComponent } from "./unit-4/lesson-1/_landing.component";
import { UnitFourLessonOneGuideComponent } from "./unit-4/lesson-1/_guide.component";
import { WorkMaturitySkillsNumberOnlyInputComponent } from "./unit-4/lesson-1/child-components/tfolio-number-only-input.component";
import { UnitFourLessonOneWorkMaturitySkillsListComponent } from "./unit-4/lesson-1/work-maturity-skills-list.component";
import { UnitFourLessonTwoLandingComponent } from "./unit-4/lesson-2/_landing.component";
import { UnitFourLessonTwoGuideComponent } from "./unit-4/lesson-2/_guide.component";
import { UnitFourLessonTwoResumeKeywordsWorksheetComponent } from "./unit-4/lesson-2/resume-keywords-worksheet.component";
import { UnitFourLessonTwoResumeTemplateComponent } from "./unit-4/lesson-2/resume-template.component";
import { UnitFourLessonThreeLandingComponent } from "./unit-4/lesson-3/_landing.component";
import { UnitFourLessonThreeGuideComponent } from "./unit-4/lesson-3/_guide.component";
import { UnitFourLessonThreeElevatorSpeechWorksheetComponent } from "./unit-4/lesson-3/elevator-speech-worksheet.component";
import { UnitFourLessonThreeElevatorSpeechFeedbackComponent } from "./unit-4/lesson-3/elevator-speech-feedback.component";
import { UnitFourLessonFourLandingComponent } from "./unit-4/lesson-4/_landing.component";
import { UnitFourLessonFourGuideComponent } from "./unit-4/lesson-4/_guide.component";
import { UnitFourLessonFourPracticeJobApplicationComponent } from "./unit-4/lesson-4/practice-job-application.component";
import { UnitFourLessonFourJobApplicationsTrackerComponent } from "./unit-4/lesson-4/job-applications-tracker.component";
import { UnitFourLessonFiveLandingComponent } from "./unit-4/lesson-5/_landing.component";
import { UnitFourLessonFiveGuideComponent } from "./unit-4/lesson-5/_guide.component";
import { UnitFourLessonFiveJobInterviewQuestionsComponent } from "./unit-4/lesson-5/job-interview-questions.component";
import { UnitFourLessonFiveInterviewEvaluationComponent } from "./unit-4/lesson-5/job-interview-evaluation.component";

import { UnitFourLessonFiveJobInterviewFeedbackComponent } from "./unit-4/lesson-5/job-interview-feedback.component";
import { UnitFourLessonSixLandingComponent } from "./unit-4/lesson-6/_landing.component";
import { UnitFourLessonSixGuideComponent } from "./unit-4/lesson-6/_guide.component";
import { UnitFourLessonSixThankYouNoteTemplateComponent } from "./unit-4/lesson-6/thank-you-note-template.component"
import { UnitFourLessonSevenLandingComponent } from "./unit-4/lesson-7/_landing.component";
import { UnitFourLessonSevenGuideComponent } from "./unit-4/lesson-7/_guide.component";
import { UnitFourLessonSevenActivityActionPlanTemplateComponent } from "./unit-4/lesson-7/activity-action-plan-template.component";

import { UnitFiveLandingComponent } from "./unit-5/_landing.component";
import { UnitFiveLessonOneLandingComponent } from "./unit-5/lesson-1/_landing.component";
import { UnitFiveLessonOneGuideComponent } from "./unit-5/lesson-1/_guide.component";
import { UnitFiveLessonTwoLandingComponent } from "./unit-5/lesson-2/_landing.component";
import { UnitFiveLessonTwoGuideComponent } from "./unit-5/lesson-2/_guide.component";
import { UnitFiveLessonTwoSelfAdvocacyGoalsWorksheetComponent } from "./unit-5/lesson-2/self-advocacy-goals-worksheet.component";
import { UnitFiveLessonThreeLandingComponent } from "./unit-5/lesson-3/_landing.component";
import { UnitFiveLessonThreeGuideComponent } from "./unit-5/lesson-3/_guide.component";
import { UnitFiveLessonThreeAToZComponent } from "./unit-5/lesson-3/a-to-z.component";
import { UnitFiveLessonThreeIndependentLivingGoalsWorksheetComponent } from "./unit-5/lesson-3/independent-living-goals-worksheet.component";
import { UnitFiveLessonThreeIndependentLivingAssessmentComponent } from "./unit-5/lesson-3/independent-living-assessment.component";
import { IndependentLivingAssessmentThreeRadioButtonsComponent } from "./unit-5/lesson-3/child-components/three-radio-buttons.component";
import { UnitFiveLessonFourLandingComponent } from "./unit-5/lesson-4/_landing.component";
import { UnitFiveLessonFourGuideComponent } from "./unit-5/lesson-4/_guide.component";
import { UnitFiveLessonFiveLandingComponent } from "./unit-5/lesson-5/_landing.component";
import { UnitFiveLessonFiveGuideComponent } from "./unit-5/lesson-5/_guide.component";
import { UnitFiveLessonFiveActionPlanComponent } from "./unit-5/lesson-5/action-plan.component";
import { UnitFiveLessonSixLandingComponent } from "./unit-5/lesson-6/_landing.component";
import { UnitFiveLessonSixGuideComponent } from "./unit-5/lesson-6/_guide.component";
import { UnitFiveLessonSixFinalReflectionWorksheetComponent } from "./unit-5/lesson-6/final-reflection-worksheet.component";

import { CreateTicketComponent } from "./create-ticket.component";
import { AboutComponent } from "./about.component";
import { ConfirmRouteGuard } from './_guards/confirm-route-guard.component';
import { HelpComponent } from './help.component';
import { FeedbackComponent } from './feedback.component';

@NgModule({
    declarations: [
        AppComponent,

        HomeComponent,
        PageNotFoundComponent,
        TFolioHeadingComponent,
        TFolioCopyright,
        TFolioPDFComponent,
        TFolioWhatsImportantToMePDFComponent,
        TFolioScroll,
        TFolioPageTitleComponent,
        TFolioDirectionsComponent,
        TFolioCiteComponent,
        TFolioPrintButtonComponent,
        TFolioTextareaComponent,
        TFolioNavButtons,
        LandingPageBannerComponent,
        LandingPageFooterComponent,
        LessonGuideFooterComponent,

        StudentCenteredIntroductionlandingpage,
        IntroductionLessonOneLandingComponent,
        IntroductionLessonOneGuideComponent,
        IntroductionWhatsImportantToMeComponent,
        IntroductionWhatsImportantToMeFacilitatorComponent,
        IntroductionLessonTwoLandingComponent,
        IntroductionLessonTwoGuideComponent,
        IntroductionLessonTwoSelfIntroductionComponent,

        UnitOneLandingpage,
        UnitOneLessonOneLandingComponent,
        UnitOneLessonOneGuideComponent,
        UnitOneLessonOneStrengthsAssessmentComponent,
        UnitOneLessonTwoLandingComponent,
        UnitOneLessonTwoGuideComponent,
        UnitOneLessonTwoPreferencesAssessmentComponent,
        UnitOneLessonThreeLandingComponent,
        UnitOneLessonThreeGuideComponent,
        UnitOneLessonThreeInterestsSurveyComponent,
        UnitOneLessonThreeEncuestaDeInteresComponent,
        UnitOneLessonThreeCareerClustersComponent,
        UnitOneLessonFourLandingComponent,
        UnitOneLessonFourGuideComponent,
        UnitOneLessonFourNeedsAssessmentComponent,
        UnitOneLessonFourChallengesInTheClassroomComponent,
        UnitOneLessonFiveLandingComponent,
        UnitOneLessonFiveGuideComponent,
        UnitOneLessonFiveSelfDeterminationScaleComponent,
        SelfDeterminationScaleRadioButtonGroupComponent,
        SelfDeterminationScaleRadioButtonSubtotalComponent,
        SelfDeterminationScaleGraphBarComponent,
        SelfDeterminationScaleThermometerComponent,
        SelfDeterminationScaleGraphLegendComponent,
        UnitOneLessonSixLandingComponent,
        UnitOneLessonSixGuideComponent,
        UnitOneLessonSixWorkMaturitySkillsComponent,
        UnitOneLessonSevenLandingComponent,
        UnitOneLessonSevenGuideComponent,
        UnitOneLessonSevenSynthesizeReflectComponent,
        UnitOneLessonSevenTextareaBlockComponent,
        UnitOneLessonSevenListNamesComponent,
        UnitOneLessonEightLandingComponent,
        UnitOneLessonEightGuideComponent,
        UnitOneLessonEightResearchingOccupationComponent,
        UnitOneLessonEightQuestionTextareaComponent,

        UnitTwoLandingComponent,
        UnitTwoLessonOneLandingComponent,
        UnitTwoLessonOneGuideComponent,
        UnitTwoLessonOneSmartGoalsWorksheetComponent,
        UnitTwoLessonTwoLandingComponent,
        UnitTwoLessonTwoGuideComponent,
        UnitTwoLessonTwoPostsecondaryGoalsWorksheetComponent,
        UnitTwoLessonThreeLandingComponent,
        UnitTwoLessonThreeGuideComponent,
        UnitTwoLessonThreeCareerExplorationWorksheetComponent,
        UnitTwoLessonFourLandingComponent,
        UnitTwoLessonFourGuideComponent,
        UnitTwoLessonFiveLandingComponent,
        UnitTwoLessonFiveGuideComponent,
        UnitTwoLessonFiveCourseOfStudyWorksheetComponent,
        UnitTwoLessonSixLandingComponent,
        UnitTwoLessonSixGuideComponent,
        UnitTwoLessonSixAgencyConnectionsWorksheetComponent,
        AgencyConnectionsAgencyInfoTableComponent,
        UnitTwoLessonSevenLandingComponent,
        UnitTwoLessonSevenGuideComponent,
        UnitTwoLessonSevenActionPlanComponent,

        UnitThreeLandingComponent,
        UnitThreeLessonOneLandingComponent,
        UnitThreeLessonOneGuideComponent,
        UnitThreeLessonTwoLandingComponent,
        UnitThreeLessonTwoGuideComponent,
        UnitThreeLessonTwoScansSkillsAssessmentComponent,
        UnitThreeLessonThreeLandingComponent,
        UnitThreeLessonThreeGuideComponent,
        UnitThreeLessonThreeInformationalInterviewWorksheetComponent,
        UnitThreeLessonFourLandingComponent,
        UnitThreeLessonFourGuideComponent,
        UnitThreeLessonFourWorkbookCiteComponent,
        UnitThreeLessonFourWorkbookFooterComponent,
        UnitThreeLessonFourWorkbookLandingComponent,
        UnitThreeLessonFourWorkbookCoverLetterComponent,
        UnitThreeLessonFourWorkbookChecklistComponent,
        UnitThreeLessonFourWorkbookPhoneScriptComponent,
        UnitThreeLessonFourWorkbookEmailScriptComponent,
        UnitThreeLessonFourWorkbookStudentQuestionsComponent,
        UnitThreeLessonFourWorkbookDayOfComponent,
        UnitThreeLessonFourWorkbookHostFeedbackComponent,
        UnitThreeLessonFourWorkbookObservationComponent,
        UnitThreeLessonFourWorkbookReflectionComponent,
        UnitThreeLessonFourWorkbookEvaluationComponent,
        UnitThreeLessonFourWorkbookThankYouComponent,
        UnitThreeLessonFiveLandingComponent,
        UnitThreeLessonFiveGuideComponent,
        UnitThreeLessonFivePerformanceReviewComponent,
        UnitThreeLessonSixLandingComponent,
        UnitThreeLessonSixGuideComponent,

        UnitFourLandingComponent,
        UnitFourLessonOneLandingComponent,
        UnitFourLessonOneGuideComponent,
        WorkMaturitySkillsNumberOnlyInputComponent,
        UnitFourLessonOneWorkMaturitySkillsListComponent,
        UnitFourLessonTwoLandingComponent,
        UnitFourLessonTwoGuideComponent,
        UnitFourLessonTwoResumeKeywordsWorksheetComponent,
        UnitFourLessonTwoResumeTemplateComponent,
        UnitFourLessonThreeLandingComponent,
        UnitFourLessonThreeGuideComponent,
        UnitFourLessonThreeElevatorSpeechWorksheetComponent,
        UnitFourLessonThreeElevatorSpeechFeedbackComponent,
        UnitFourLessonFourLandingComponent,
        UnitFourLessonFourGuideComponent,
        UnitFourLessonFourPracticeJobApplicationComponent,
        UnitFourLessonFourJobApplicationsTrackerComponent,
        UnitFourLessonFiveLandingComponent,
        UnitFourLessonFiveGuideComponent,
        UnitFourLessonFiveJobInterviewQuestionsComponent,
        UnitFourLessonFiveJobInterviewFeedbackComponent,
        UnitFourLessonSixLandingComponent,
        UnitFourLessonSixGuideComponent,
        UnitFourLessonSixThankYouNoteTemplateComponent,
        UnitFourLessonSevenLandingComponent,
        UnitFourLessonSevenGuideComponent,
        UnitFourLessonSevenActivityActionPlanTemplateComponent,
        UnitFourLessonFiveInterviewEvaluationComponent,

        UnitFiveLessonTwoSelfAdvocacyGoalsWorksheetComponent,
        UnitFiveLessonThreeAToZComponent,
        UnitFiveLessonThreeIndependentLivingGoalsWorksheetComponent,
        UnitFiveLessonThreeIndependentLivingAssessmentComponent,
        IndependentLivingAssessmentThreeRadioButtonsComponent,
        UnitFiveLessonFiveActionPlanComponent,
        UnitFiveLessonSixFinalReflectionWorksheetComponent,
        UnitFiveLandingComponent,
        UnitFiveLessonOneLandingComponent,
        UnitFiveLessonOneGuideComponent,
        UnitFiveLessonTwoLandingComponent,
        UnitFiveLessonTwoGuideComponent,
        UnitFiveLessonThreeLandingComponent,
        UnitFiveLessonThreeGuideComponent,
        UnitFiveLessonFourLandingComponent,
        UnitFiveLessonFourGuideComponent,
        UnitFiveLessonFiveLandingComponent,
        UnitFiveLessonFiveGuideComponent,
        UnitFiveLessonSixLandingComponent,
        UnitFiveLessonSixGuideComponent,


        CreateTicketComponent,
        AboutComponent,
        HelpComponent,
        FeedbackComponent    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        RouterModule,
        AppRouting
    ],
    providers: [
        Title,
        ConfirmRouteGuard
    ],
    bootstrap: [
        AppComponent
    ]
})

export class AppModule { }
