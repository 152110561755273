import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-four-landing-page',
    templateUrl: '../_shared/landing-pages/unit-landing-page.component.html',
    styleUrls: ['../_shared/landing-pages/unit-landing-page.component.css']
})
export class UnitFourLandingComponent {

    title: string = "Unit 4: Workplace Readiness Training";
    pageTitle: string = "Workplace Readiness Training | Unit 4 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    UnitLandingPageData = {
        breadcrumbs: {
            label: "Unit 4",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                }
            ]
        },
        portfolioitems: [
            {
                text: "Work Maturity Skills List",
            },
            {
                text: "Résumé",
            },
            {
                text: "Résumé Keyword Worksheet",
            },
            {
                text: "Elevator Speech Worksheet",
            },
            {
                text: "Practice Job Application",
            },
            {
                text: "Job Applications Tracker",
            },
            {
                text: "Job Interview Questions and Answers",
            },
            {
                text: "Job interview: Peer Reflection Feedback",
            },
            {
                text: "Job Interview: Self-Evaluation",
            },
            {
                text: "Thank You Note",
            },
            {
                text: "Action Plan for Employment",
            }
        ],
        summary: "Unit 4 assists youth in building more specific skills and products, including a r\xE9sum\xE9 and elevator speech, that will help them in applying and interviewing for jobs.",
        buttons: [
            {
                route: "/unit-4/lesson-1",
                linktext: "Lesson 1: Work Maturity Skills",
                color: "btn-success",
                image: "/img/unit-4/lesson-1/L1_cytonn-photography-604681-unsplash.jpg",
                alttext: "This image is a close up of two hands shaking in a business setting.",
            },
            {
                route: "/unit-4/lesson-2",
                linktext: "Lesson 2: Writing a Résumé",
                color: "btn-success",
                image: "/img/unit-4/lesson-2/L2_resume.jpg",
                alttext: "A r\xE9sum\xE9 and a data report rest on a wooden desk next to a laptop.",
            },
            {
                route: "/unit-4/lesson-3",
                linktext: "Lesson 3: Writing and Presenting an Elevator Speech",
                color: "btn-success",
                image: "/img/unit-4/lesson-3/L3_k-120-name-1117-id-62272-jpeg.jpg",
                alttext: "A person wearing glasses and a blazer is speaking to a coworker.",
            },
            {
                route: "/unit-4/lesson-4",
                linktext: "Lesson 4: The Job Application Process",
                color: "btn-success",
                image: "/img/unit-4/lesson-4/L4_233-pom-4028-id-260562-jpeg.jpg",
                alttext: "A student fills out a form at a desk.",
            },
            {
                route: "/unit-4/lesson-5",
                linktext: "Lesson 5: Job Interview Preparation",
                color: "btn-success",
                image: "/img/unit-4/lesson-5/L5_dream-job-2904780_1920.jpg",
                alttext: "A wooden sign with the text “Dream Job” points towards the right of the image with a cloudy blue-sky backdrop.",
            },
            {
                route: "/unit-4/lesson-6",
                linktext: "Lesson 6: Writing a Thank You Note",
                color: "btn-success",
                image: "/img/unit-4/lesson-6/L6_aaron-burden-211846-unsplash.jpg",
                alttext: "This image is a close up of a red thank you card and a calligraphy pen.",
            },
            {
                route: "/unit-4/lesson-7",
                linktext: "Lesson 7: Action Plan for Employment",
                color: "btn-success",
                image: "/img/unit-4/lesson-7/L7_K-63C-Ae-0005424901-BW-1-id-1211-jpeg.jpg",
                alttext: "This black and white photo shows a team of eight young people putting their hands together over a table with work supplies.",
            },

        ],
        image: "/img/unit-4/_landing/UNIT 4_229-num-002-id-259951-jpeg.jpg",
        alttext: "A young person smiles at the camera beside two coworkers who are discussing work materials.",
        caption: "on",
        photoText: "Photo",
        photoLink: "https://www.rawpixel.com/image/259951/diverse-business-people-woman-man-work-workplace-indoor-office-computers-documents-paper-colleagues",
        source: "Rawpixel",
        sourceLink: "https://www.rawpixel.com/",
        licence: "/ Free license",
        licenceLink: "https://www.rawpixel.com/services/image-licenses/free-license",
    }
}
