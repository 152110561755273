import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-four-lesson-six-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFourLessonSixLandingComponent {

    bannerHeader = "Workplace Readiness Training: Lesson 6";
    title: string = "Lesson 6: Writing a Thank You Note";
    pageTitle: string = "Writing a Thank You Note | Unit 4, Lesson 6 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 6",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-4",
                    linktext: "Unit 4"
                }
            ]
        },
        summary: "In this lesson, students will gain an understanding of why and how to write a thank you note to a potential employer after an interview.  ",
        objectives: [
            "Understand the value of sending a thank you note after an interview.",
            "Demonstrate knowledge of the various components of a thank you note.",
            "Complete a sample thank you note for an interviewer. ",
        ],
        buttons: [
            {
                route: "/unit-4/lesson-6/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-4/lesson-6/thank-you-note-template",
                linktext: "Thank You Note Template",
                color: "btn-primary",
                window: false,
            },
            {
                route: '/img/unit-4/lesson-6/T-Folio_Unit-4_Lesson-6_Thank-You-Note.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-4/lesson-6/L6_aaron-burden-211846-unsplash.jpg",
        imgalt: "This image is a close up of a red thank you note and a calligraphy pen.",
        caption: "by Aaron Burden on",
        photoLink: "https://unsplash.com/photos/zunGugEsJCE",
        source: "Unsplash",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",
    }


}
