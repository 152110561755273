import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-five-landing-page',
    templateUrl: '../_shared/landing-pages/unit-landing-page.component.html',
    styleUrls: ['../_shared/landing-pages/unit-landing-page.component.css']
})
export class UnitFiveLandingComponent {

    title: string = "Unit 5: Self-Advocacy";
    pageTitle: string = "Self-Advocacy | Unit 5 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    UnitLandingPageData = {
        breadcrumbs: {
            label: "Unit 5",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                }
            ]
        },
        portfolioitems: [
            {
                text: "AIR Self-Determination Scale"
            },
            {
                text: "Self-Advocacy Goals Worksheet"
            },
            {
                text: "Independent Living Assessment"
            },
            {
                text: "Independent Living Goals Worksheet"
            },
            {
                text: "Action Plan for Self-Advocacy and Independent Living"
            },
            {
                text: "T-Folio Tracker (updated)"
            },
            {
                text: "Final Reflections Worksheet"
            },
        ],
        summary: "In Unit 5, youth will reassess their level of self-determination and will make goals in the areas of self-advocacy and independent living.",
        buttons: [
            {
                route: "/unit-5/lesson-1",
                linktext: "Lesson 1: Self-Determination Re-Assessment",
                color: "btn-success",
                image: "/img/unit-5/lesson-1/1_achievement-adult-agreement-1153213.jpg",
                alttext: "A group of young people at a meeting table join hands in a high five.",
            },
            {
                route: "/unit-5/lesson-2",
                linktext: "Lesson 2: Self-Advocacy Goals",
                color: "btn-success",
                image: "/img/unit-5/lesson-2/2_jonathan-simcoe-88013.jpg",
                alttext: "A green sign hanging on a wall between two clocks reads “Ask more questions.”",
            },
            {
                route: "/unit-5/lesson-3",
                linktext: "Lesson 3: Independent Living Goals",
                color: "btn-success",
                image: "/img/unit-5/lesson-3/3_apples-business-buy-95425.jpg",
                alttext: "The image shows two people’s arms and hands as one buys an apple from the other at a fruit stand.",
            },
            {
                route: "/unit-5/lesson-4",
                linktext: "Lesson 4: Student-Led IEPs",
                color: "btn-success",
                image: "/img/unit-5/lesson-4/4_nathan-anderson-157614.jpg",
                alttext: "A young person hugs their mom with their dad looking on and all three smiling.",
            },
            {
                route: "/unit-5/lesson-5",
                linktext: "Lesson 5: Action Plan for Self-Advocacy and Independent Living",
                color: "btn-success",
                image: "/img/unit-5/lesson-5/5_oliver-cole-232552-unsplash.jpg",
                alttext: "A man walks in front of a colorful painted mural that says “Courage.”",
            },
            {
                route: "/unit-5/lesson-6",
                linktext: "Lesson 6: Final Reflection",
                color: "btn-success",
                image: "/img/unit-5/lesson-6/6_teen1.jpg",
                alttext: 'A black-and-white image of a young man smiling, wearing earbuds and a t-shirt that says "Thank you."',
            }

        ],
        image: "/img/unit-5/_landing/UNIT_AMERICANED_CAPCITY_032-2.jpg",
        alttext: "A young person listens intently to her classmate while writing with a pencil.",
        caption: "Courtesy of Allison Shelley/The Verbatim Agency for American Education: Images of Teachers and Students in Action",
        photoText: null,
        photoLink: "",
        source: "",
        sourceLink: "",
        licence: "",
        licenceLink: "", 
    }
}
