import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'career-exploration-worksheet',
    templateUrl: './career-exploration-worksheet.component.html',
    styleUrls: ['./career-exploration-worksheet.component.css']
})
export class UnitTwoLessonThreeCareerExplorationWorksheetComponent {

    title: string = "Career Exploration Worksheet";
    pageTitle: string = this.title + " | Unit 2, Lesson 3 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

}
