import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-one-lesson-two-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitOneLessonTwoLandingComponent {
    title = "Lesson 2: Preferences";
    pageTitle: string = "Preferences | Unit 1, Lesson 2 | T-Folio";
    link = "http://www.careerbridge.wa.gov/Home_ExploreCareers.aspx";
    route = "['/unit-1/lesson-2/preferences-assessment']";
    bannerHeader = "Job Exploration Groundwork: Lesson 2";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 2",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-1",
                    linktext: "Unit 1"
                }
            ]
        },
        summary: "This lesson helps students to identify their own preferences in a work setting and encourages voicing one's preferences in all areas of life. After completing a Preferences Assessment, students will begin to connect their preferences to different employment conditions and opportunities.",
        objectives: [
            "Explore and identify personal preferences as it relates to work environments.",
            "Identify possible jobs that match stated preferences.",
            "Analyze and compare common preference features of identified jobs.",
            "Apply readiness methods and supports when taking preferences assessment.",
            "Complete a preferences assessment.",
        ],
        buttons: [
            {
                route: "/unit-1/lesson-2/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            },
            {
                route: "/unit-1/lesson-2/preferences-assessment",
                linktext: "Preferences Assessment",
                color: "btn-primary",
            },
            {
                route: "/img/unit-1/lesson-2/T-Folio_Unit-1_Lesson-2_Preferences.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-1/LESSON 2_Checklist_yes_no.jpg",
        imgalt: "A hand holding a sharpie pen writes a checkmark on a small notebook page with the word “Checklist” at the top and the words “yes” and “no” underneath. The yes has a check to the left of it and the no has an x to the left of it.",
        caption: "by MorganK on",
        photoLink: "https://pixabay.com/en/checklist-check-yes-or-no-decision-2313804/",
        source: "Pixabay",
        sourceLink: "https://pixabay.com/en/",
        licence: "CC0 license",
        licenceLink: "https://creativecommons.org/publicdomain/zero/1.0/",
    }
}
