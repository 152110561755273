import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-two-lesson-seven-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitTwoLessonSevenLandingComponent {
    title = "Lesson 7: Action Plan for Employment and Education";
    pageTitle: string = "Action Plan for Employment and Education | Unit 2, Lesson 7 | T-Folio";
    bannerHeader = "Postsecondary Employment and Education Options: Lesson 7";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 7",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-2",
                    linktext: "Unit 2"
                }
            ]
        },
        summary: "In Lesson 7, youth will develop an action plan to follow up with the activities conducted throughout Unit 2. This will allow them to put the research and exploration activities they have completed in Lessons 1-6 of the unit into a plan of action.",
        objectives: [
            "Learn the components of an action plan.",
            "Create an action plan for one or more of the areas covered in this unit.",
            "Identify supports needed for each portion of the action plan.",
        ],
        buttons: [
            {
                route: "/unit-2/lesson-7/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false
            },
            {
                route: "/unit-2/lesson-7/action-plan",
                linktext: "Action Plan",
                color: "btn-primary",
                window: false
            },
            {
                route: '/img/unit-2/lesson-7/T-Folio_Unit-2_Lesson-7_Action-Plan-Sample.pdf',
                linktext: "Action Plan - Sample (PDF)",
                color: "btn-primary",
                window: true
            },
            {
                route: '/img/unit-2/lesson-7/T-Folio_Unit-2_Lesson-7_Action-Plan-For-Employment-And-Education.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true
            }

        ],
        image: "/img/unit-2/lesson-7/LESSON 7_Action-Plan_tamarcus-brown-131428.jpg",
        imgalt: "Two hands work in a spiral sketchbook with the left one positioning a ruler and the right one holding a pen.",
        caption: " on ",
        source: "Pixabay",
        photoLink: "https://pixabay.com/en/student-school-classroom-desk-2607160/",
        sourceLink: "https://pixabay.com/en/",
        licence: "CC0 license",
        licenceLink: "https://creativecommons.org/publicdomain/zero/1.0/",

    }
}
