import { Component } from "@angular/core";
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'smart-goals-worksheet',
    templateUrl: './smart-goals-worksheet.component.html',
    styleUrls: ['./smart-goals-worksheet.component.css']
})
export class UnitTwoLessonOneSmartGoalsWorksheetComponent {

    title: string = "SMART Goals Worksheet";
    pageTitle: string = this.title + " | Unit 2, Lesson 1 | T-Folio";

    public constructor(private titleService: Title, private router: Router) {

        this.titleService.setTitle(this.pageTitle);


    }

}
