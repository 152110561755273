import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'unit-three-lesson-five-guide',
    templateUrl: './_guide.component.html',
    styleUrls: ['../../_shared/lesson-guides/lesson-guide.component.css']
})
export class UnitThreeLessonFiveGuideComponent {

    pageTitle: string = "Lesson Guide | Unit 3, Lesson 5 | T-Folio";
    title: string = "Lesson Guide";
    subtitle: string = "Lesson 5: Evaluations and Letters of Recommendation";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
