import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent {

    title: string = "Feedback";
    pageTitle: string = this.title + " | T-Folio";

    constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
