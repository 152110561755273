import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "job-shadow-workbook-performance-review",
    templateUrl: "./performance-review.component.html",
    styles: [`
    h1 {
        text-align:right;
    }

    .active {
    color: #2C3E50;
    }

    .editor {
        min-height:75px;
        width:100%;
        margin-top:5px;
        border: 1px solid gray;
        text-align:left;
        font-weight:bold;
    }   

    @media print {
        .editor { 
            min-height:75px;
            width:100%;
            margin-top:5px;
            border:none; 
            text-align:left;
        }
    }

    `]
})
export class UnitThreeLessonFivePerformanceReviewComponent {

    title: string = "Employee Performance Review";
    pageTitle: string = this.title + " | Unit 3, Lesson 5 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }

}
