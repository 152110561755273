import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'job-applications-tracker',
    templateUrl: './job-applications-tracker.component.html',
    styleUrls: ['./job-applications-tracker.component.css']
})
export class UnitFourLessonFourJobApplicationsTrackerComponent {

    title: string = "Job Applications Tracker";
    pageTitle: string = this.title + " | Unit 4, Lesson 4 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

}
