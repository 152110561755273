import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-two-lesson-six-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitTwoLessonSixLandingComponent {

    title = "Lesson 6: Exploring Agency Options";
    pageTitle = "Exploring Agency Options | Unit 2, Lesson 6 | T-Folio";
    bannerHeader = "Postsecondary Employment and Education Options: Lesson 6";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 6",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-2",
                    linktext: "Unit 2"
                }
            ]
        },
        summary: "In this lesson, youth will identify and explore the available agencies for support in their county and local community. Youth will create a resource reference sheet for their use as they transition into the adult world.",
        objectives: [
            "Identify areas in which they feel they may need support in the transition to the adult world.",
            "Identify support agencies in their county.",
            "Create a personalized resource list of support agencies they may access.",
        ],
        buttons: [
            {
                route: "/unit-2/lesson-6/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-2/lesson-6/agency-connections-worksheet",
                linktext: "Agency Connections Worksheet",
                color: "btn-primary",
                window: false,
            },
            {
                route: '/img/unit-2/lesson-6/T-Folio_Unit-2_Lesson-6_Agency-Connections-Worksheet-Sample.pdf',
                linktext: "Agency Connections Worksheet - Sample (PDF)",
                color: "btn-primary",
                window: true,
            },
            {
                route: '/img/unit-2/lesson-6/T-Folio_Unit-2_Lesson-6_Exploring-Agency-Options.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }

        ],
        image: "/img/unit-2/lesson-6/LESSON 6_Exploring-Agency-Connections_william-bout-264826.jpg",
        imgalt: "The image shows a network of cables that connect to form geometrical patterns.",
        caption: "by William Bout on ",
        source: "Unsplash",
        photoLink: "",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",
    }
}
