import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'job-interview-evaluation',
    templateUrl: './job-interview-evaluation.component.html',
    styleUrls: ['./job-interview-evaluation.component.css']
})

export class UnitFourLessonFiveInterviewEvaluationComponent {

    title: string = "Job Interview: Self-Evaluation";
    pageTitle: string = this.title + " | Unit 4, Lesson 5 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }
}
