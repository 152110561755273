import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

declare var jquery: any;
declare var $: any;

@Component({
    selector: "job-shadow-workbook-thank-you",
    templateUrl: "./thank-you.component.html",
    styleUrls: ['./job-shadow-workbook.component.css']
})
export class UnitThreeLessonFourWorkbookThankYouComponent {

    title: string = "Thank You Note Template";
    pageTitle: string = this.title + " | Job Shadow Workbook | Unit 3, Lesson 4 | T-Folio";

    shadowDate: string;
    hostName: string;
    learned: string;
    liked: string;
    userName: string;

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    copyToClipboard(event: any) {

        var copyText = document.getElementById("note").innerText;

        var textArea = document.createElement("textarea");

        textArea.style.position = 'fixed';
        textArea.style.top = '0px';
        textArea.style.left = '0px';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0px';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = copyText;
        document.body.appendChild(textArea);

        textArea.select();
        document.execCommand('copy');

        document.body.removeChild(textArea);

    }

    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }

}
