﻿import { Component } from "@angular/core";

@Component({
    selector: 'self-determination-scale-graph-legend',
    template: `
<table cellpadding="0" cellspacing="0">
    <thead>
        <tr>
            <th height="75" style="min-height:75px;"></th>
        </tr>
    </thead>
    <tr>
        <td width="25" height="25">10</td>
    </tr>
    <tr>
        <td width="25" height="25">9</td>
    </tr>
    <tr>
        <td width="25" height="25">8</td>
    </tr>
    <tr>
        <td width="25" height="25">7</td>
    </tr>
    <tr>
        <td width="25" height="25">6</td>
    </tr>
    <tr>
        <td width="25" height="25">5</td>
    </tr>
    <tr>
        <td width="25" height="25">4</td>
    </tr>
    <tr>
        <td width="25" height="25">3</td>
    </tr>
    <tr>
        <td width="25" height="25">2</td>
    </tr>
    <tr>
        <td width="25" height="25">1</td>
    </tr>
</table>
`,
    styles: [`
td {
    text-align:center;
}
`]
})
export class SelfDeterminationScaleGraphLegendComponent {
}