import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-one-lesson-four-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitOneLessonFourLandingComponent {
    title = "Lesson 4: Needs";
    pageTitle = "Needs | Unit 1, Lesson 4 | T-Folio";
    bannerHeader = "Job Exploration Groundwork: Lesson 4";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 4",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-1",
                    linktext: "Unit 1"
                }
            ]
        },
        summary: "Lesson 4 asks students to explore and identify their own challenges, through a variety of activities that promote self-reflection and self-determination. The lesson focuses on growth, improvement, and success and helps students understand when and how to ask for support or accommodations.",
        objectives: [
            "Gain an increased awareness of the normalcy of challenges and their ability to overcome them.",
            "Identify their own challenges as well as strategies or supports to address or accept them.",
            "Independently apply readiness methods and supports when taking the challenges and needs assessments.",
            "Complete challenges and needs assessments.",
        ],
        buttons: [
            {
                route: "/unit-1/lesson-4/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            },
            {
                route: "/unit-1/lesson-4/classroom-challenges",
                linktext: "Challenges in the Classroom and Beyond",
                color: "btn-primary",
            },
            {
                route: "/unit-1/lesson-4/needs-assessment",
                linktext: "Needs Assessment",
                color: "btn-primary",
            },
            {
                route: "/img/unit-1/lesson-4/T-Folio_Unit-1_Lesson-4_Needs.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-1/LESSON 4_lightbulb_thoughtbubbles.jpg",
        imgalt: "A chalkboard drawing of a light bulb in the center and six circles drawn out from the lightbulb, like thought bubbles.",
        caption: " on",
        source: "Pexels",
        photoLink: "https://www.pexels.com/photo/abstract-blackboard-bulb-chalk-355948/",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",

    }
}
