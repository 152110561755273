import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'unit-one-lesson-eight-guide',
    templateUrl: './_guide.component.html',
    styleUrls: ['./_guide.component.css']
})
export class UnitOneLessonEightGuideComponent {

    pageTitle: string = "Lesson Guide | Unit 1, Lesson 8 | T-Folio";
    title: string = "Lesson Guide";
    subtitle: string = "Lesson 8: Researching Occupations";

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
