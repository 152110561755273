import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-one-lesson-eight-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitOneLessonEightLandingComponent {
    title = "Lesson 8: Researching Occupations";
    pageTitle: string = "Researching Occupations | Unit 1, Lesson 8 | T-Folio";
    bannerHeader = "Job Exploration Groundwork: Lesson 8";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 8",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-1",
                    linktext: "Unit 1"
                }
            ]
        },
        summary: "Students will use the information gathered in Lesson 7 in order to begin researching the occupations that they feel are the best match for them. They will then reflect on the results of their research in order to evaluate what they have learned in relation to what they know about themselves.",
        objectives: [
            "Identify potential careers of interest.",
            "Identify critical information for researching careers.",
            "Research careers using critical information areas.",
            "Self-evaluate researched careers and their fit for personal interests, skills, and abilities.",
        ],
        buttons: [
            {
                route: "/unit-1/lesson-8/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            },
            {
                route: "/unit-1/lesson-8/researching-occupation",
                linktext: "Career Research Worksheet",
                color: "btn-primary",
            },
            {
                route: "/img/unit-1/lesson-8/T-Folio_Unit-1_Lesson-8_Researching-Occupations.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-1/LESSON 8_jobs_happy.jpg",
        imgalt: "A person sitting in a computer lab stretches their arms upwards while smiling at their computer screen.",
        caption: "by Rawpixel on",
        photoLink: "https://www.pexels.com/photo/adult-black-and-white-break-chairs-440581/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",


    }
}
