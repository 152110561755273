import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'agency-connections-worksheet',
    templateUrl: './agency-connections-worksheet.component.html',
    styleUrls: ['./agency-connections-worksheet.component.css']
})
export class UnitTwoLessonSixAgencyConnectionsWorksheetComponent {

    title: string = "Agency Connections Worksheet";
    pageTitle: string = this.title + " | Unit 2, Lesson 6 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

}
