import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-four-lesson-five-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFourLessonFiveLandingComponent {

    bannerHeader = "Workplace Readiness Training: Lesson 5";
    title: string = "Lesson 5: Job Interview Preparation";
    pageTitle: string = "Job Interview Preparation | Unit 4, Lesson 5 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 5",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-4",
                    linktext: "Unit 4"
                }
            ]
        },
        summary: "In this lesson, students will identify the work maturity skills needed for a successful interview. They will practice responding to sample interview questions as a whole group, then will have time to participate in mock interviews with self- and peer-feedback opportunities.",
        objectives: [
            "Identify and demonstrate work maturity skills needed for a job interview. ",
            "Identify and evaluate appropriate questions and responses for a job interview.",
            "Demonstrate appropriate performance in a mock job interview.",
            "Self and peer evaluate mock job interviews.",
        ],
        buttons: [
            {
                route: "/unit-4/lesson-5/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-4/lesson-5/job-interview-questions",
                linktext: "Job Interview Questions and Answers",
                color: "btn-primary",
                window: false,
            },
            {
                route: "/unit-4/lesson-5/job-interview-feedback",
                linktext: "Job Interview: Peer Reflection Feedback",
                color: "btn-primary",
                window: false,
            },
            {
                route: "/unit-4/lesson-5/job-interview-evaluation",
                linktext: "Job Interview: Self-Evaluation",
                color: "btn-primary",
                window: false,
            },
            {
                route: '/img/unit-4/lesson-5/T-Folio_Unit-4_Lesson-5_Job-Interview-Preparation.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            },
        ],
        image: "/img/unit-4/lesson-5/L5_dream-job-2904780_1920.jpg",
        imgalt: "A wooden sign with the text “Dream Job” points towards the right of the image with a cloudy blue-sky backdrop.",
        caption: "on",
        photoLink: "https://pixabay.com/en/dream-job-application-location-job-2904780/",
        source: "Pixabay",
        sourceLink: "https://pixabay.com/en/",
        licence: "CC0 license",
        licenceLink: "https://creativecommons.org/publicdomain/zero/1.0/",
    }


}
