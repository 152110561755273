﻿import { Component } from "@angular/core";
import { Router, NavigationEnd, UrlTree } from "@angular/router";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "app-root",
    templateUrl: './app.component.html'
})
export class AppComponent {

    title = "T-Folio";
   
    constructor(public router: Router, private titleService: Title) {
        this.router.events.subscribe((evt) => {

            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            window.scrollTo(0, 0);

            (<any>window).ga('set', 'page', evt.url);
            (<any>window).ga('send', 'pageview');

        });
    }

    public setTitle() {
         var newTitle = this.title;
        this.titleService.setTitle(newTitle);
    }

    isActive(data: any[]): boolean {
        return this.router.isActive(
            this.router.createUrlTree(data),
            true);
    }
}