import { Component } from "@angular/core";

@Component({
    selector: 'lesson-guide-footer',
    template: `
    <div class="row-fluid">
        <div class="col-md-2" style="padding-top:25px;">
            <a (click)="doScroll();" class="hidden-print" style="cursor:pointer">Back to Top</a>
        </div>
        <div class="col-md-9" style="padding-top:25px;">
        </div>
        <div class="col-md-1" style="padding-top:25px;">
            <button (click)="doPrint();" class="hidden-print">Print</button><tfolio-print-button></tfolio-print-button>
        </div>
    </div>
    <div class="row-fluid">
        <div class="col-md-12" style="padding-top:50px;">
            <img src="/img/ccby.png" alt="CC BY License" height="33" style="float:left;margin-top:2px;margin-right:5px;">
            <div style="font-size:small;">
                Except where otherwise noted, content on this site, created by <a href="http://www.seattleu.edu/ccts" target="_blank">CCTS at Seattle University</a>
                with funding from <a href="https://www.dshs.wa.gov/strategic-planning/division-vocational-rehabilitation" target="_blank">WA DSHS/DVR</a>,
                is licensed under a <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0 license</a>.
                All logos and trademarks are property of their respective owners.
            </div>
        </div>
    </div>
`,
    styles: [`
@media print {
    a[href]:after {
        content: none!important;
    }
}
`]
})
export class LessonGuideFooterComponent {
    doPrint(): void {
        window.print();
    }
    doScroll() {
        window.scrollTo(0, 0);
    }
}
