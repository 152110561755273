import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'introduction-lesson-zero-guide',
    templateUrl: './_guide.component.html',
    styleUrls: ['./_guide.component.css']
})
export class IntroductionLessonOneGuideComponent {

    pageTitle: string = "Lesson Guide | Introduction, Lesson 1 | T-Folio";
    title: string = "Lesson Guide";
    subtitle: string = "Lesson 1: Spotlight on Student Warm-up";
    
    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
