﻿import { Component, Input } from "@angular/core";

@Component({
    selector: 'career-clusters',
    templateUrl: './career-clusters.component.html',
    styleUrls: ['./career-clusters.component.css']
})
export class UnitOneLessonThreeCareerClustersComponent {

    @Input() isTop: boolean; 
    @Input() num: string;
    @Input() identifier: string; 
    @Input() pageBreakBefore: boolean;

    jobTitles: Map<string, string> = new Map(); 
    altDisplay: Map<string, string> = new Map(); 


    constructor() {

        this.jobTitles.set('01', '1. Agriculture, Food, and Natural Resources Career Cluster');
        this.jobTitles.set('02', '2. Architecture and Construction Career Cluster');
        this.jobTitles.set('03', '3. Arts, Audio/Visual Technology and Communications Career Cluster');
        this.jobTitles.set('04', '4. Business, Management and Administration Career Cluster');
        this.jobTitles.set('05', '5. Education and Training Career Cluster');
        this.jobTitles.set('06', '6. Finance Career Cluster');
        this.jobTitles.set('07', '7. Government and Public Administration Career Cluster');
        this.jobTitles.set('08', '8. Health Science Career Cluster');
        this.jobTitles.set('09', '9. Hospitality and Tourism Career Cluster');
        this.jobTitles.set('10', '10. Human Services Career Cluster');
        this.jobTitles.set('11', '11. Information Technology (IT) Career Cluster');
        this.jobTitles.set('12', '12. Law, Public Safety, Corrections and Security Career Cluster');
        this.jobTitles.set('13', '13. Manufacturing Career Cluster');
        this.jobTitles.set('14', '14. Marketing Career Cluster');
        this.jobTitles.set('15', '15. Science, Technology, Engineering and Mathematics (STEM) Career Cluster');
        this.jobTitles.set('16', '16. Transportation, Distribution and Logistics Career Cluster');

        this.altDisplay.set('01', 'Agriculture, Food, and Natural Resources');
        this.altDisplay.set('02', 'Architecture and Construction');
        this.altDisplay.set('03', 'Arts, Audio/Visual Technology and Communications');
        this.altDisplay.set('04', 'Business, Management and Administration');
        this.altDisplay.set('05', 'Education and Training');
        this.altDisplay.set('06', 'Finance');
        this.altDisplay.set('07', 'Government and Public Administration');
        this.altDisplay.set('08', 'Health Science');
        this.altDisplay.set('09', 'Hospitality and Tourism');
        this.altDisplay.set('10', 'Human Services');
        this.altDisplay.set('11', 'Information Technology');
        this.altDisplay.set('12', 'Law, Public Safety, Corrections and Security');
        this.altDisplay.set('13', 'Manufacturing');
        this.altDisplay.set('14', 'Marketing');
        this.altDisplay.set('15', 'Science, Technology, Engineering and Mathematics');
        this.altDisplay.set('16', 'Transportation, Distribution and Logistics');

    }

}