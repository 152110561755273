import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "informational-interview-worksheet",
    templateUrl: "./informational-interview-worksheet.component.html",
    styleUrls: ["./informational-interview-worksheet.component.css"]
})
export class UnitThreeLessonThreeInformationalInterviewWorksheetComponent {

    title: string = "Informational Interview Worksheet";
    pageTitle: string = this.title + " | Unit 3, Lesson 2 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }

}
