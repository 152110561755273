import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'whats-important-to-me',
    templateUrl: './whats-important-to-me.component.html',
    styleUrls: ['./whats-important-to-me.component.css']
})
export class IntroductionWhatsImportantToMeComponent {

    title: string = "What's Important to Me";    
    pageTitle: string = this.title + " | Introduction, Lesson 1 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    doPrint(): void {
        window.print();
    }
    doScroll() {
        window.scrollTo(0, 0);
    }

    onEnter(event) {
        if (event.keyCode == 13) {
            event.preventDefault();
            return false;
        }
        
    }
}
