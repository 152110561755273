import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";



@Component({
    selector: "work-maturity-skills-list",
    templateUrl: "./work-maturity-skills-list.component.html",
    styleUrls: ["./work-maturity-skills-list.component.css"]
})


export class UnitFourLessonOneWorkMaturitySkillsListComponent {

    title: string = "Work Maturity Skills List";
    pageTitle: string = this.title + " | Unit 4, Lesson 1 | T-Folio";

    public constructor(private titleService: Title)
    {
        this.titleService.setTitle(this.pageTitle);
    }
 }
