import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'course-of-study-plan',
    templateUrl: './course-of-study-worksheet.component.html',
    styleUrls: ['./course-of-study-worksheet.component.css']
})
export class UnitTwoLessonFiveCourseOfStudyWorksheetComponent {

    title: string = "Course of Study Worksheet";
    pageTitle: string = this.title + " | Unit 2, Lesson 5 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    restoreBulletPoint($event) {
        if ($event.srcElement.innerHTML.indexOf("<li>") == -1)
            $event.srcElement.innerHTML = '<ul><li></li></ul>';
    }

}
