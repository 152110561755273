import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'interests-survey',
    templateUrl: './interests-survey.component.html',
    styleUrls: ['./interests-survey.component.css']
})
export class UnitOneLessonThreeInterestsSurveyComponent {

    title: string = "Career Clusters Interest Survey";
    pageTitle: string = this.title + " | Unit 1, Lesson 3 | T-Folio";

    boxTotals: Map<string, number> = new Map();
    isTopCluster: Map<string, boolean> = new Map();
    clusterLabels: Map<string, string> = new Map();

    topClustersRevealed: boolean = false;
    topThreeClusters: string = '';

    clusters = [
        { id: '01', identifier: 'Agriculture', pageBreak: false, message: 'Focuses on the production, processing, marketing, distribution, financing, and development of agricultural commodities and resources including food, fiber, wood products, natural resources, horticulture, and other plant and animal products/resources.' },
        { id: '02', identifier: 'Architecture', pageBreak: false, message: 'Careers in designing, planning, managing, building, and maintaining the built environment.' },
        { id: '03', identifier: 'Arts', pageBreak: false, message: 'Designing, producing, exhibiting, performing, writing, and publishing multimedia content including visual and performing arts and design, journalism, and entertainment services.' },
        { id: '04', identifier: 'Business', pageBreak: false, message: 'Careers in planning, organizing, directing, and evaluating business functions essential to efficient and productive business operations.' },
        { id: '05', identifier: 'Education', pageBreak: false, message: 'Planning, managing, and providing education and training services, and related learning support activities.' },
        { id: '06', identifier: 'Finance', pageBreak: false, message: 'Planning, services for financial and investment planning, banking, insurance, and business financial management.' },
        { id: '07', identifier: 'Government', pageBreak: true, message: 'Planning and performing government functions as the local, state, and federal levels, including governance, national security, foreign service, planning, revenue and taxation, and regulations.' },
        { id: '08', identifier: 'Health', pageBreak: false, message: 'Planning, managing, and providing therapeutic services, diagnostic services, health informatics, support services, and biotechnology research and development.' },
        { id: '09', identifier: 'Hospitality', pageBreak: false, message: 'The management, marketing, and operations of restaurants and other food services, lodging, attractions, recreation events, and travel related services.' },
        { id: '10', identifier: 'Human', pageBreak: false, message: 'Preparing individuals for employment in career pathways that relate to families and human needs such as counseling and mental health services, family and community services, personal care, and consumer services.' },
        { id: '11', identifier: 'Information', pageBreak: false, message: 'Building linkages in IT occupations for entry level, technical, and professional careers related to the design, development, support and management of hardware, software, multimedia, and systems integration services.' },
        { id: '12', identifier: 'Law', pageBreak: false, message: 'Planning, managing, and providing legal, public safety, protective services, and homeland security, including professional and technical support services.' },
        { id: '13', identifier: 'Manufacturing', pageBreak: false, message: 'Planning, managing, and performing the processing of materials into intermediate or final products and related professional and technical support activities such as production planning and control, maintenance and manufacturing/process engineering.' },
        { id: '14', identifier: 'Marketing', pageBreak: true, message: 'Planning, managing, and performing marketing activities to reach organizational objectives.' },
        { id: '15', identifier: 'Science', pageBreak: false, message: 'Planning, managing, and providing scientific research and professional and technical services (such as physical science, social science, engineering) including laboratory and testing services, and research and developmental services.' },
        { id: '16', identifier: 'Transportation', pageBreak: false, message: 'Planning, management, and movement of people, materials, and goods by road, pipeline, air, rail, and water and related professional support services such as transportation infrastructure planning and management, logistics services, mobile equipment, and facility maintenance.' },
    ];
    
    constructor(private titleService: Title) {

        this.titleService.setTitle(this.pageTitle);

        this.boxTotals.set('01', 0);
        this.boxTotals.set('02', 0);
        this.boxTotals.set('03', 0);
        this.boxTotals.set('04', 0);
        this.boxTotals.set('05', 0);
        this.boxTotals.set('06', 0);
        this.boxTotals.set('07', 0);
        this.boxTotals.set('08', 0);
        this.boxTotals.set('09', 0);
        this.boxTotals.set('10', 0);
        this.boxTotals.set('11', 0);
        this.boxTotals.set('12', 0);
        this.boxTotals.set('13', 0);
        this.boxTotals.set('14', 0);
        this.boxTotals.set('15', 0);
        this.boxTotals.set('16', 0);

        this.isTopCluster.set('01', false);
        this.isTopCluster.set('02', false);
        this.isTopCluster.set('03', false);
        this.isTopCluster.set('04', false);
        this.isTopCluster.set('05', false);
        this.isTopCluster.set('06', false);
        this.isTopCluster.set('07', false);
        this.isTopCluster.set('08', false);
        this.isTopCluster.set('09', false);
        this.isTopCluster.set('10', false);
        this.isTopCluster.set('11', false);
        this.isTopCluster.set('12', false);
        this.isTopCluster.set('13', false);
        this.isTopCluster.set('14', false);
        this.isTopCluster.set('15', false);
        this.isTopCluster.set('16', false);

        this.clusterLabels.set('01', '&nbsp;&nbsp;1. Agriculture, Food, and Natural Resources');
        this.clusterLabels.set('02', '&nbsp;&nbsp;2. Architecture and Construction');
        this.clusterLabels.set('03', '&nbsp;&nbsp;3. Arts, Audio/Visual Technology and Communications');
        this.clusterLabels.set('04', '&nbsp;&nbsp;4. Business, Management and Administration');
        this.clusterLabels.set('05', '&nbsp;&nbsp;5. Education and Training');
        this.clusterLabels.set('06', '&nbsp;&nbsp;6. Finance');
        this.clusterLabels.set('07', '&nbsp;&nbsp;7. Government and Public Administration');
        this.clusterLabels.set('08', '&nbsp;&nbsp;8. Health Science');
        this.clusterLabels.set('09', '&nbsp;&nbsp;9. Hospitality and Tourism');
        this.clusterLabels.set('10', '10. Human Services');
        this.clusterLabels.set('11', '11. Information Technology');
        this.clusterLabels.set('12', '12. Law, Public Safety, Corrections and Security');
        this.clusterLabels.set('13', '13. Manufacturing');
        this.clusterLabels.set('14', '14. Marketing');
        this.clusterLabels.set('15', '15. Science, Technology, Engineering and Mathematics');
        this.clusterLabels.set('16', '16. Transportation, Distribution and Logistics');

    }

    revealTopClusters() {
        this.topClustersRevealed = true;
        this.setTopClusters();
    }

    totalBox(event: any) {

        var chkboxId: string = event.target.id.substring(5, 7);

        var incr = event.target.checked ? 1 : -1;
        var curBoxTotal = this.boxTotals.get(chkboxId);
        this.boxTotals.set(chkboxId, curBoxTotal + incr);

        if (this.topClustersRevealed) {
            this.setTopClusters();
        }
    }

    setTopClusters() {

        this.topThreeClusters = '';
        var htmlID = "career-cluster-";
        for (let key of this.isTopCluster.keys()) {
            this.isTopCluster.set(key, false);
        }

        var iNumTopClusters = 3;

        var boxTotalsSorted: Map<string, number> = new Map([...this.boxTotals.entries()].sort(
            (a, b) => {

                var n = b[1] - a[1];
                if (n != 0) {
                    return n;
                }

                if (b[0] > a[0]) {
                    return -1;
                } else if (b[0] < a[0]) {
                    return 1;
                }
                else {
                    return 0;
                }                
            })
        );
        var boxTotalsSortedArray: Array<[string, number]> = [...boxTotalsSorted.entries()];

        for (var i: number = 0; i < iNumTopClusters; i++) {

            var boxIndex = boxTotalsSortedArray[i][0];
            var temp = htmlID + boxIndex;
            document.getElementById(temp).style.cssText += 'background-color:springgreen !important';
            this.isTopCluster.set(boxIndex, true);
            this.topThreeClusters += '<p>' + this.clusterLabels.get(boxIndex) + '</p>';
        }
    }
}
