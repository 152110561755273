import { Component, Input } from "@angular/core";

@Component({
    selector: 'job-shadow-workbook-footer',
    template: `
    <div class="row-fluid" style="margin-top:25px;">
        <div class="col-md-2">
            <a (click)="doScroll();" class="hidden-print" style="cursor:pointer">Back to Top</a>
        </div>
        <div class="col-md-offset-11">
            <tfolio-print-button></tfolio-print-button>
        </div>
    </div>
    <div class="row-fluid">
        <div class="col-md-12" style="font-size:12px;margin-top:30px;">
            <div class="border"style="text-align:left;">T-Folio | <a href="cctstfolio.com">www.cctstfolio.com</a> | 2018 </div>
            <div style="float:left;">
                This <a [routerLink]="['/unit-3/lesson-4/job-shadow-workbook']"> workbook</a> is a modified version of the 
               "Sophomore Job Shadow Handbook" 
                provided by the 
                <a href="http://www.mdihs.net/" target="_blank">Mount Desert Island High School</a>. 
            </div>
        </div>
    </div>
`,
    styles: [`
    .border {
        border-top: solid black 1px;
        padding-bottom: 10px;
        padding-top: 5px;
    }
    
    @media print {
      a[href]:after {
        content: none !important;
      }
    }
`]
})
export class UnitThreeLessonFourWorkbookFooterComponent {
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }
}
