import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-five-lesson-two-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFiveLessonTwoLandingComponent {

    bannerHeader = "Self-Advocacy: Lesson 2";
    title: string = "Lesson 2: Self-Advocacy Goals";
    pageTitle: string = "Self-Advocacy Goals | Unit 5, Lesson 2 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 2",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-5",
                    linktext: "Unit 5"
                }
            ]
        },
        summary: "In this lesson, students will review their AIR Self-Determination Scale scores in order to reflect on where they want to be in the future and will identify steps to take in order to meet their self-advocacy goals.",
        objectives: [
            "Analyze past data around self-determination. ",
            "Set goals for self-advocacy.",
            "Determine appropriate next steps to reach their goals. ",
        ],
        buttons: [
            {
                route: "/unit-5/lesson-2/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },

            {
                route: "/unit-5/lesson-2/self-advocacy-goals-worksheet",
                linktext: "Self-Advocacy Goals Worksheet",
                color: "btn-primary",
                window: false,
            },

            {
                route: '/img/unit-5/lesson-2/T-Folio_Unit-5_Lesson-2_Self-Advocacy-Goals.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-5/lesson-2/2_jonathan-simcoe-88013.jpg",
        imgalt: "A green sign hanging on a wall between two clocks reads “Ask more questions.”",
        caption: "by Jonathan Simcoe on ",
        photoLink: "https://static.pexels.com/photos/92028/pexels-photo-92028-medium.jpeg",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",

    }
}
