import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-two-lesson-five-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitTwoLessonFiveLandingComponent {
    title = "Lesson 5: Course of Study";
    pageTitle: string = "Course of Study | Unit 2, Lesson 5 | T-Folio";
    bannerHeader = "Postsecondary Employment and Education Options: Lesson 5";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 5",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-2",
                    linktext: "Unit 2"
                }
            ]
        },
        summary: "In this lesson, youth will outline their course of study as it relates to their postsecondary outcomes. A worksheet is provided for the youth to record their course of study.",
        objectives: [
            "Review their postsecondary goals (from Unit 2: Lesson 2).",
            "Map their courses as they relate to their postsecondary goals for the remainder of their high school career.",
        ],
        buttons: [
            {
                route: "/unit-2/lesson-5/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-2/lesson-5/course-of-study-worksheet",
                linktext: "Course of Study Worksheet",
                color: "btn-primary",
                window: false,
            },
            {
                route: '/img/unit-2/lesson-5/T-Folio_Unit-2_Lesson-5_Course-Of-Study-Worksheet-Sample.pdf',
                linktext: "Course of Study Worksheet - Sample (PDF)",
                color: "btn-primary",
                window: true,
            },
            {
                route: '/img/unit-2/lesson-5/T-Folio_Unit-2_Lesson-5_Writing-Your-Course-Of-Study.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }

        ],
        image: "/img/unit-2/lesson-5/LESSON 5_Course-of-Study_kimberly-farmer-287677.jpg",
        imgalt: "The edges of a colorful stack of hardback textbooks appear on the left side of the image with the background blurred.",
        caption: "by Kimberly Farmer on",
        photoLink:"https://unsplash.com/photos/lUaaKCUANVI",
        source: "Unsplash",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",

    }
}
