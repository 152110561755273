import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "resume-keywords-worksheet",
    templateUrl: "./resume-keywords-worksheet.component.html",
    styleUrls: ["./resume-keywords-worksheet.component.css"]
})
export class UnitFourLessonTwoResumeKeywordsWorksheetComponent {

    title: string = "R\xE9sum\xE9 Keywords Worksheet";
    pageTitle: string = this.title + " | Unit 4, Lesson 2 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
