import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-three-lesson-six-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitThreeLessonSixLandingComponent {

    bannerHeader = "Work-Based Learning Experiences: Lesson 6";
    title: string = "Lesson 6: High School and Beyond Plan";
    pageTitle: string = "High School and Beyond Plan | Unit 3, Lesson 6 | T-Folio";
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 6",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-3",
                    linktext: "Unit 3"
                }
            ]
        },
        summary: "In this lesson, the youth will align the information and activities they have completed throughout the T-Folio with their High School and Beyond Plan (HSBP). Educators and agency personnel will guide youth in determining if they have gathered the necessary information to complete the HSBP.",
        objectives: [
            "Review their High School and Beyond Plan.",
            "Review the information they have gathered so far using the T-Folio.",
            "Update the High School and Beyond Plan.",
        ],
        buttons: [
            {
                route: "/unit-3/lesson-6/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            }
        ],
        image: "/img/unit-3/lesson-6/LESSON 6_carl-heyerdahl-181868.jpg",
        imgalt: 'This image shows an organized desk space with a large computer monitor that says "DO MORE." in white letters on a black screen.',
        caption: "by Carl Heyerdahl on",
        photoLink: "https://unsplash.com/photos/KE0nC8-58MQ",
        source: "Unsplash",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",

    }

    doPrint(): void {
        window.print();
    }
}
