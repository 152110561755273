﻿import { Component, Input } from "@angular/core";

@Component({
    selector: 'unit-one-lesson-eight-question-textarea',
    templateUrl: './question-textarea.component.html',
    styleUrls: ['./question-textarea.component.css']
})
export class UnitOneLessonEightQuestionTextareaComponent {

    @Input() question: string;
    @Input() identifier: string;

    constructor() {
    }

    doPrint(): void {
        window.print();
    }
}