import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "job-shadow-workbook-phone-script",
    templateUrl: "./phone-script.component.html",
    styleUrls: ['./job-shadow-workbook.component.css']
})
export class UnitThreeLessonFourWorkbookPhoneScriptComponent {

    title: string = "Phone Script";
    pageTitle: string = this.title + " | Job Shadow Workbook | Unit 3, Lesson 4 | T-Folio";

    studentName: string;
    hostName: string;
    schoolName: string;
    dateOfJobShadow: string;
    career: string;

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }

}
