import { Component } from "@angular/core";


@Component({
    selector: 'tfolio-nav-buttons',
    template: `

       <div class="row-fluid">
            <div class="col-md-10" style="padding-top:40px;">
                <a (click)="doScroll();" class="hidden-print" style="cursor:pointer">Back to Top</a>
            </div>
            <div class="col-md-2" style="padding-top:40px;">
                <tfolio-print-button></tfolio-print-button>
            </div>
        </div>
 `
    ,

    styles: [`  
        .centered {
            text-align: center;
        }

        .padded {
        padding-top: 25px;
        }

        @media print {
    a[href]: after  {
        content: none!important;
    }
}
               
`]
})


export class TFolioNavButtons {
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }
}
