import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-five-lesson-three-landing-page',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})
export class UnitFiveLessonThreeLandingComponent {

    bannerHeader = "Self-Advocacy: Lesson 3";
    title: string = "Lesson 3: Independent Living Goals";
    pageTitle: string = "Independent Living Goals | Unit 5, Lesson 3 | T-Folio";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 3",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-5",
                    linktext: "Unit 5"
                }
            ]
        },
        summary: "In Lesson 3, students will review elements of independent living, such as daily living, money management, relationships and communication, etc. Students will self-assess whether they feel they possess identified skills around independent living. Students will then reflect and set 2-3 SMART goals to improve their independent living skills.",
        objectives: [
            "Review a variety of independent living skills. ",
            "Identify their level of independence in terms of possessing identified skills.",
            "Set SMART goals to improve areas of need.",
        ],
        buttons: [
            {
                route: "/unit-5/lesson-3/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
                window: false,
            },
            {
                route: "/unit-5/lesson-3/a-to-z",
                linktext: "A to Z for Independent Living",
                color: "btn-primary",
                window: false,
            },
            {
                route: "/unit-5/lesson-3/independent-living-assessment",
                linktext: "Independent Living Assessment",
                color: "btn-primary",
                window: false,
            },
            {
                route: "/unit-5/lesson-3/independent-living-goals-worksheet",
                linktext: "Independent Living Goals Worksheet",
                color: "btn-primary",
                window: false,
            },

            {
                route: '/img/unit-5/lesson-3/T-Folio_Unit-5_Lesson-3_Independent-Living-Goals.pptx',
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-5/lesson-3/3_apples-business-buy-95425.jpg",
        imgalt: "The image shows two people’s arms and hands as one buys an apple from the other at a fruit stand.",
        caption: "by Erik Scheel on",
        photoLink: "https://www.pexels.com/photo/apple-business-fruit-local-95425/",
        source: "Pexels",
        sourceLink: "https://www.pexels.com/",
        licence: "CC0 license",
        licenceLink: "https://www.pexels.com/photo-license/",

    }


}
