﻿import { Component, Input, Output, ElementRef} from "@angular/core";

@Component({
    selector: 'tfolio-number-only-input',
    templateUrl: './tfolio-number-only-input.component.html',
    styleUrls: ['./tfolio-number-only-input.component.css']
})
export class WorkMaturitySkillsNumberOnlyInputComponent {

    @Input() identifier: string;
    @Input() labelText: string;
    @Input() OnlyNumber: boolean;

    ensureNumber($event) {
        let e = <KeyboardEvent>event;
        if (this.OnlyNumber) {
            if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
                //Allow: Ctrl+A
                (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
                //Allow: Ctrl+C
                (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
                //Allow: Ctrl+V
                (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
                //Allow: Ctrl+X
                (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
                //Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)) {
                    //Don't do anything
                    return;
            }
            //Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 49 || e.keyCode > 51)) && (e.keyCode < 97 || e.keyCode > 99)) {
                    e.preventDefault();
            }
        }
    }
}