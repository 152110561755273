import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "elevator-speech-feedback",
    templateUrl: "./elevator-speech-feedback.component.html",
    styleUrls: ["./elevator-speech-feedback.component.css"]
})
export class UnitFourLessonThreeElevatorSpeechFeedbackComponent {

    title: string = "Elevator Speech: Peer Reflection Feedback";
    pageTitle: string = this.title + " | Unit 4, Lesson 3 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }
}
