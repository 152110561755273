import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "job-interview-questions",
    templateUrl: "./job-interview-questions.component.html",
    styleUrls: ['./job-interview-questions.component.css']
})
export class UnitFourLessonFiveJobInterviewQuestionsComponent {

    title: string = "Job Interview Questions and Answers";
    pageTitle: string = this.title + " | Unit 4, Lesson 5 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
    doScroll() {
        window.scrollTo(0, 0);
    }
    doPrint() {
        window.print();
    }

}
