import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'unit-one-lesson-five-landing',
    templateUrl: '../../_shared/landing-pages/landing-page.component.html',
    styleUrls: ['../../_shared/landing-pages/landing-page.component.css']
})

export class UnitOneLessonFiveLandingComponent {
    title = "Lesson 5: Self-Determination";
    pageTitle = "Self-Determination | Unit 1, Lesson 5 | T-Folio";
    bannerHeader = "Job Exploration Groundwork: Lesson 5";

    openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }

    LandingPageData = {
        breadcrumbs: {
            label: "Lesson 5",
            crumbs: [
                {
                    route: "",
                    linktext: "T-Folio"
                },
                {
                    route: "/unit-1",
                    linktext: "Unit 1"
                }
            ]
        },
        summary: "In this lesson, students will complete the AIR Self-Determination Scale assessment in order to gain better insight into their own level of self-determination both at school and at home.",
        objectives: [
            "Gain an increased awareness of the capacities and  opportunities that are available to them.",
            "Demonstrate and/or practice those skills, by setting and achieving goals both at school and at home.",
            "Make a plan for exploring and expanding opportunities.",
            "Begin the goal-setting process based on assessment information.",
        ],
        buttons: [
            {
                route: "/unit-1/lesson-5/guide",
                linktext: "Lesson Guide",
                color: "btn-success",
            },
            {
                route: "/unit-1/lesson-5/self-determination-scale",
                linktext: "AIR Self-Determination Scale",
                color: "btn-primary",
            },
            {
                route: "/img/unit-1/lesson-5/T-Folio_Unit-1_Lesson-5_Self-Determination.pptx",
                linktext: "PowerPoint Presentation",
                color: "powerpoint-button",
                window: true,
            }
        ],
        image: "/img/unit-1/LESSON 5_zachary-nelson-192289.jpg",
        imgalt: "Three young people are jumping in the air with big smiles on their faces. They are on a sidewalk that has mounds of snow on either side, next to a three way intersection.",
        caption: "by Zachary Nelson on",
        photoLink: "https://unsplash.com/photos/98Elr-LIvD8",
        source: "Unsplash",
        sourceLink: "https://unsplash.com/",
        licence: "Open license",
        licenceLink: "https://unsplash.com/license",
    }
}
