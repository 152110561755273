import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'unit-one-lesson-three-guide',
    templateUrl: './_guide.component.html',
    styleUrls: ['./_guide.component.css']
})
export class UnitOneLessonThreeGuideComponent {

    pageTitle: string = "Lesson Guide | Unit 1, Lesson 3 | T-Folio";
    title: string = "Lesson Guide";
    subtitle: string = "Lesson 3: Interests";

    doPrint(): void {
        window.print();
    }
    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
