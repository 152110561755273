import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'unit-four-lesson-two-guide',
    templateUrl: './_guide.component.html',
    styleUrls: ['../../_shared/lesson-guides/lesson-guide.component.css']
})
export class UnitFourLessonThreeGuideComponent {

    pageTitle: string = "Lesson Guide | Unit 4, Lesson 3 | T-Folio";
    title: string = "Lesson Guide";
    subtitle: string = "Lesson 3: Writing and Presenting an Elevator Speech";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
}
