import { Component} from "@angular/core";
import { Title } from '@angular/platform-browser';

declare let $;

@Component({
    selector: 'strengths-assessment',
    templateUrl: './strengths-assessment.component.html',
    styleUrls: ['./strengths-assessment.component.css']
})

export class UnitOneLessonOneStrengthsAssessmentComponent {

    title: string = "Strengths Assessment";
    pageTitle: string = this.title + " | Unit 1, Lesson 1 | T-Folio";

    public constructor(private titleService: Title) {
        this.titleService.setTitle(this.pageTitle);
    }
 
    clearAllPersonalStrengthBoxes(event: any) {
        $(".personal-strengths-assessment-checkbox").prop("checked", false);
    }

    clearAllWorkStrengthBoxes(event: any) {
        $(".work-strengths-assessment-checkbox").prop("checked", false);
    }

    clearAllSpecialityStrengthBoxes(event: any) {
        $(".speciality-strengths-assessment-checkbox").prop("checked", false);
    }
}
